import { useCallback, useEffect, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import moment from 'moment';
import {
  validateFormCampaign,
  validateSchemeCampaign,
} from 'domains/campaign/model/shared/validateForm';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import { createConfiValues } from 'utils/createConfigValues';
import { useSavedField } from 'domains/campaign/model/hooks/useSavedField';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { getOffersList } from 'domains/offers/model/actions';
import { useOffersList } from 'domains/offers/model/selectors';
import { useContractsList } from 'domains/contracts/model/selectors';
import { getContractsList } from 'domains/contracts/model/actions';

import { someGroups } from 'utils/statics/everyGroups';
import {
  useCampaignAdvancedOptimizationInfo,
  useSelectorCampaignCompanyTagsInfo,
} from '../../../../model/selectors';
import {
  fetchAdvancedOptimizationLists,
  fetchOptimizationList,
  fetchTagsGetCompany,
  revertCampaignTags,
  setEditPersonalCampaign,
  unlinkCampaignToOffer,
  updateTagCampaign,
} from '../../../../model/actions';
import { resetCompanyTags } from '../../../../reduser';
import { useDispatchApp } from '../../../../../../redux/rootSelectors';
import last from '../../../../../../utils/last';
import {
  PSetEditPersonalCampaign,
  RGetPersonal,
  TCampaignForm,
  TExperimentalInfoField,
} from '../../../../types';
import { objectOptimizationWithFormik } from '../../../../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { useCurrency, useProjectInfo } from '../../../../../project/hooks';
import { useClientsInfo } from '../../../../../clients/model/selectors';
import { SelectField } from '../../../../../../types/general';
import { fetchClients } from '../../../../../clients/reducer';
import { RAdvancedOptimizationLists } from '../../../../model/types';
import { useIsGroup } from '../../../../../user/hooks';
import { transformDateAdvancedOptimization } from '../../../../model/utils/utilsForm';
import { contractsOptionsFunc, offersOptionsFunc } from '../../utils';

type TFUseEditCampaignFormProps = {
  campaign: RGetPersonal;
  pastCampaign: RGetPersonal[];
  experimentalInfo?: TExperimentalInfoField[] | null;
};

type TFUseEditCampaignForm = (param: TFUseEditCampaignFormProps) => {
  formik: FormikProps<TCampaignForm>;
  handlerUpdateTags: (v: string[]) => void;
  companyTags: ReturnType<typeof useSelectorCampaignCompanyTagsInfo>['data'];
  errorTags: ReturnType<typeof useSelectorCampaignCompanyTagsInfo>['error'];
  LTUPastTags: number;
  LTU: number;
  handlerRevertTags: () => void;
  isSavedTags: string[];
  handlerInput: () => void;
  savedField: Record<string, unknown>;
  currency: ReturnType<typeof useCurrency>['currency'];
  clients: SelectField[];
  advancedList: ReturnType<typeof useCampaignAdvancedOptimizationInfo>['data'];
  isRawState: ReturnType<typeof useIsGroup>['isRawState'];
  setField: (
    name: string,
    value: string | number | undefined | boolean,
  ) => void;
  locked: boolean;
  resetForm: () => void;
  offersOptions?: SelectField[];
  contractsOptions?: SelectField[];
};

export const useEditCampaignForm: TFUseEditCampaignForm = ({
  campaign,
  pastCampaign,
  experimentalInfo,
}) => {
  const {
    xxhash,
    advertiser_name,
    title,
    status,
    date_start,
    date_end,
    config_values,
    pay_price,
    pay_type,
    advanced_optimizations,
    pay_optimization_field_id,
    cpa_optimizations,
    container,
    limits,
    offer_id,
    contract_uuid,
    ord_name
  } = campaign;
  const dispatch = useDispatchApp();

  const { data: user } = useUserInfo();

  const { isRawState, isLocked } = useIsGroup();
  const { data: bread_crumbs } = useSearchBreadcrumbsInfo();
  const { data: projectData } = useProjectInfo();

  const { currency } = useCurrency();
  const { getValueFromSettingForKey } = useUserTheme();
  const { data: clientsTemp, LTU: clientsLTU } = useClientsInfo();
  const { data: contractsList } = useContractsList();
  const { data: offersList } = useOffersList();

  const { data: advancedList, LTU: LTUAdvanced } =
    useCampaignAdvancedOptimizationInfo();

  const offersOptions = offersOptionsFunc(offersList);
  const contractsOptions = contractsOptionsFunc(contractsList);

  const clients = useMemo<SelectField[]>(() => {
    if (!clientsTemp) {
      return [];
    }
    return clientsTemp
      .filter((client) => client.status !== 'DELETED')
      .map(({ title: label, id }) => ({ label, value: id }));
  }, [clientsLTU]);

  const {
    data: companyTags,
    past: { LTU: LTUPastTags, data: pastCampaignTags },
    LTU,
    error: errorTags,
  } = useSelectorCampaignCompanyTagsInfo();

  const configValues = useMemo(
    () =>
      createConfiValues(experimentalInfo, config_values || {}, [
        'categories_campaign',
        'coef_name',
      ]),
    [experimentalInfo, config_values],
  );

  const formik = useFormik<TCampaignForm>({
    enableReinitialize: true,
    initialValues: {
      offer_id,
      ord_name,
      partner_xxhash: bread_crumbs?.Client?.xxhash || '',
      advertiser_name,
      title,
      contract_uuid: contract_uuid || null,
      status,
      date_start: moment(date_start?.date).toDate(),
      date_end: moment(date_end?.date).toDate(),
      pay_price,
      pay_optimization_field_id,
      config_values: configValues,
      pay_type,
      cpa_optimizations,
      advanced_optimizations: transformDateAdvancedOptimization(
        advanced_optimizations,
        advancedList as RAdvancedOptimizationLists,
      ),
      container,
      limits,
    },
    onSubmit: (values) => {
      const param = objectOptimizationWithFormik<Omit<TCampaignForm, 'contracts'>>(
        {
          offer_id,
          ord_name: null,
          partner_xxhash: bread_crumbs?.Client?.xxhash || '',
          advertiser_name,
          title,
          contract_uuid,
          status,
          date_start: moment(date_start.date).format('YYYY-MM-DD'),
          date_end: moment(date_end.date).format('YYYY-MM-DD'),
          pay_price,
          pay_type,
          pay_optimization_field_id,
          config_values: configValues,
          cpa_optimizations,
          advanced_optimizations: transformDateAdvancedOptimization(
            advanced_optimizations,
            advancedList as RAdvancedOptimizationLists,
          ),
          container,
          limits,
        },
        {
          ...values,
          date_start: moment(values.date_start).format('YYYY-MM-DD'),
          date_end: moment(values.date_end).format('YYYY-MM-DD'),
        },
      );
      let params: PSetEditPersonalCampaign = {
        xxhash: campaign.xxhash,
        translation:
          getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
          !getValueFromSettingForKey('ShowBroadcastStatus'),
      };
      if (param) {
        if ('advanced_optimizations' in param) {
          params = {
            ...params,
            advanced_optimizations: Object.values(
              values.advanced_optimizations,
            ).map((v) => +v),
            cpa_optimizations: !!Object.keys(values.advanced_optimizations)
              .length,
          };
          delete param.advanced_optimizations;
        }
        if ('offer_id' in param && param.offer_id === 'null') {
          dispatch(
            unlinkCampaignToOffer({ campaigns_xxhash: [campaign.xxhash] }),
          );
          delete param.offer_id;
        }
        if (Object.keys(param).length) {
          params = {
            ...params,
            ...param,
          };
        }
        if (
          Object.keys(params).length &&
          !(
            Object.keys(params).length === 2 &&
            params.xxhash &&
            params.translation !== undefined
          )
        ) {
          dispatch(setEditPersonalCampaign({ ...params }));
        }
      }
    },
    validationSchema: validateSchemeCampaign,
    validate: validateFormCampaign,
  });

  const isSavedTags = useMemo<string[]>(() => {
    if (LTUPastTags && pastCampaignTags.length && companyTags) {
      const lastTags = last(pastCampaignTags);
      if (lastTags) {
        return companyTags.filter(
          (value) => lastTags.findIndex((v) => value === v) === -1,
        );
      }
    }
    return [];
  }, [companyTags, pastCampaignTags, LTUPastTags]);

  const handlerUpdateTags: ReturnType<TFUseEditCampaignForm>['handlerUpdateTags'] =
    (v) => {
      dispatch(updateTagCampaign({ xxhash, tags: v }));
    };

  const handlerRevertTags = () => {
    dispatch(revertCampaignTags({ xxhash }));
  };

  const handlerInput: ReturnType<TFUseEditCampaignForm>['handlerInput'] =
    useCallback(() => {
      if (formik.isValid) {
        formik.handleSubmit();
      }
    }, [formik, campaign]);

  const setField: ReturnType<TFUseEditCampaignForm>['setField'] = useCallback(
    (name, value) => {
      formik.setFieldValue(name, value);
      handlerInput();
    },
    [formik],
  );

  const resetForm = useCallback(() => {
    formik.setValues({
      ...formik.values,
      date_start: moment(date_start.date).toDate(),
      date_end: moment(date_end.date).toDate(),
      limits,
    });
  }, [formik, campaign]);

  useEffect(() => {
    if (!bread_crumbs?.Client?.xxhash) return;
    dispatch(
      getOffersList({ partner_xxhash_list: [bread_crumbs.Client?.xxhash] }),
    );
  }, [bread_crumbs?.Client?.xxhash]);

  useEffect(() => {
    dispatch(fetchAdvancedOptimizationLists());
    dispatch(fetchClients({}));
    if (someGroups(user, 'owner', 'self_user') && projectData?.ord_registration) {
      dispatch(getContractsList({}));
    }
    if (campaign) {
      dispatch(fetchTagsGetCompany(xxhash));
    }
    return () => {
      dispatch(resetCompanyTags());
    };
  }, []);

  useEffect(() => {
    if (formik.values.pay_type !== 'cpa') return;
    dispatch(fetchOptimizationList());
  }, [formik.values.pay_type]);

  const { savedField } = useSavedField({
    data: campaign,
    pastData: pastCampaign,
  });

  useEffect(() => {
    if (advancedList && campaign.cpa_optimizations) {
      formik.setFieldValue(
        'advanced_optimizations',
        transformDateAdvancedOptimization(advanced_optimizations, advancedList),
      );
    }
  }, [LTUAdvanced, campaign.cpa_optimizations]);

  return {
    LTU,
    errorTags,
    isSavedTags,
    LTUPastTags,
    handlerRevertTags,
    handlerUpdateTags,
    companyTags,
    handlerInput,
    formik,
    savedField,
    currency,
    clients,
    advancedList,
    isRawState,
    setField,
    locked: isLocked,
    resetForm,
    offersOptions,
    contractsOptions,
  };
};
