/* eslint-disable no-plusplus */
import React, { FC, useRef, useState } from 'react';
import cn from 'classnames';
import Tooltip from 'components/UI/Tooltip';
import { FiMoreHorizontal, FiTrash2 } from 'react-icons/fi';
import { useFixedHeightItem } from 'utils/useFixedHeightItem';
import { format } from 'date-fns';
import { ContractItem } from 'domains/contract/model/types';
import { useHistory } from 'react-router-dom';
import { statusDict } from 'domains/contracts/model/consts';
import { useTranslation } from 'react-i18next';
import { useOrdList } from 'domains/counteragents/model/selectors';
import css from '../styles.module.scss';

interface IItemProps {
  contractData: ContractItem;
}

const Item: FC<IItemProps> = ({ contractData }) => {
  const [toggle, setToggle] = useState(false);
  const itemRef = useRef<HTMLDivElement>(null);
  const heightItem = useFixedHeightItem(itemRef);
  const history = useHistory();
  const { t } = useTranslation();
  const { data: ordList } = useOrdList();

  const {
    uuid,
    number,
    ord,
    customer,
    executor,
    date,
    erirStatus,
    erirExportedOn,
  } = contractData;

  return (
    <div
      className={cn(css.item_wrapper)}
      onClick={() => history.push(`/main/contract/${uuid}/edit`)}
    >
      <div className={css.indicator} />
      <div
        className={cn(css.item, {
          _isOpen: toggle,
        })}
        ref={itemRef}
        style={{
          height: toggle ? `${heightItem}px` : `auto`,
        }}
      >
        <Tooltip
          hintWidth={194}
          title={
            <div
              className={cn(css.status, {
                _isActive: contractData.status === 'ACTIVE',
                _isDeleted: contractData.status === 'DELETED',
                _isModeration: contractData.status === 'ON_APPROVAL',
              })}
            />
          }
        >
          {contractData.status === 'ACTIVE' && `Активный`}
          {contractData.status === 'DELETED' && `Удален`}
          {contractData.status === 'ON_APPROVAL' && `На модерации`}
        </Tooltip>

        <div className={css.value}>
          <div className={cn(css.value_top, css.value_fat)}>{number}</div>
          <div className={css.value_bottom}>{uuid}</div>
        </div>
        <div>{format(new Date(+date * 1000), 'dd.MM.yyyy')}</div>
        <div className={css.value}>
          <div className={css.value_top}>{customer.name}</div>
          <div className={css.value_bottom}>{customer.inn}</div>
        </div>
        <div className={css.value}>
          <div className={css.value_top}>{executor.name}</div>
          <div className={css.value_bottom}>{executor.inn}</div>
        </div>
        <div>
          {ordList?.find(({ id }) => id === ord)?.title || t(`ord.unknow_ord`)}
        </div>
        <Tooltip
          hintWidth={194}
          title={
            <div
              className={cn(css.status_erid, {
                _isNotSend: erirStatus === 'NONE',
                _isWaiting: erirStatus === 'PENDING',
                _isWaitUnload: false,
                _isSuccess: erirStatus === 'SUCCESS',
                _isError: erirStatus === 'ERROR',
              })}
            />
          }
        >
          {t(statusDict[erirStatus])}
          {erirStatus === 'SUCCESS' && erirExportedOn
            ? ` • ${format(
                new Date(+erirExportedOn * 1000),
                'dd.MM.yyyy, H:mm:ss',
              )}`
            : ''}
        </Tooltip>
        <div
          className={cn('toggle-dropdown', css.dropdown, { _isOpen: toggle })}
          onMouseEnter={() => {
            setToggle(true);
          }}
          onMouseLeave={() => {
            setToggle(false);
          }}
        >
          <button type="button" className={css.dropdown__show}>
            <FiMoreHorizontal size={24} />
          </button>

          <div
            className={css.dropdown__menu}
            onClick={(e) => e.stopPropagation()}
          >
            <Tooltip title={<FiTrash2 size={24} />} isDisabled>
              <span>{t(`delete_btn`)}</span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
