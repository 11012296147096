import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import CreativesNew from 'domains/creative/creatives';
import CreativeEditNew from 'domains/creative/creatives/CreativeEdit';
import CreativeAddNew from 'domains/creative/creatives/creativeAdd';
import { useDataCampaignMain } from './hooks/useDataCampaignMain';
import css from './styles.module.scss';
import NavigationHeader from '../../components/NavigationHeader';
import Main from './Main';
import Reports from './reports';
import Targets from '../target';
import Report from './reports/reportStatististic';
import Additional from './Additional';
import Settings from './Settings';
import Experimental from './Experimental';
import { useSearchBreadcrumbsInfo } from '../search/model/selectors';

const Detail: FC = () => {
  const { t } = useTranslation();
  const {
    user,
    goBack,
    hasNavigation,
    location,
    id,
    isOwner,
    isEperimentalOwner,
    isSelfUser,
    isWithTargets,
    isWithCreatives,
    path,
    isCampaignError,
    campaign,
  } = useDataCampaignMain();

  useEffect(() => {
    document.scrollingElement?.scrollTo({ top: 0 });
  }, []);

  // начало костыля
  const { data } = useSearchBreadcrumbsInfo();
  const kostilClientsArr = ['6566B07B891EE6A0', '4FE611F2EC314DD2'];
  const isKostil =
    data && 'Client' in data && kostilClientsArr.includes(data.Client.xxhash);
  // конец костыля

  return (
    <div className={css.detail}>
      {hasNavigation() && !isCampaignError && campaign && (
        <NavigationHeader
          disabled={!!campaign && !!data?.Client?.xxhash}
          back={{
            title: t('campaigns_page.campaign_settings.black_menu.Back'),
            to: goBack(),
          }}
        >
          <NavLink
            to={{
              ...location,
              pathname: `/main/campaign/${id}/main`,
            }}
            activeClassName="isActive"
          >
            {t('campaigns_page.campaign_settings.black_menu.Main_settings')}
          </NavLink>
          {(isWithTargets || isOwner || isSelfUser) && (
            <NavLink
              to={`/main/campaign/${id}/target`}
              activeClassName="isActive"
            >
              {t('campaigns_page.campaign_settings.black_menu.Targeting')}
            </NavLink>
          )}
          {(isWithCreatives || isWithTargets || isOwner || isSelfUser) && (
            <NavLink
              to={{
                ...location,
                pathname: `/main/campaign/${id}/creatives`,
              }}
              activeClassName="isActive"
            >
              {t('campaigns_page.campaign_settings.black_menu.Creatives')}
            </NavLink>
          )}
          {!isKostil && (
            <NavLink
              to={{
                ...location,
                pathname: `/main/campaign/${id}/reports`,
              }}
              activeClassName="isActive"
            >
              {t('campaigns_page.campaign_settings.black_menu.Reports')}
            </NavLink>
          )}
          {user && (isWithCreatives || isWithTargets || isOwner || isSelfUser) && (
            <NavLink
              to={{
                ...location,
                pathname: `/main/campaign/${id}/additional`,
              }}
              activeClassName="isActive"
            >
              {t(
                'campaigns_page.campaign_settings.black_menu.Settings_aditional',
              )}
            </NavLink>
          )}

          {isOwner && user && (
            <NavLink
              to={{
                ...location,
                pathname: `/main/campaign/${id}/settings`,
              }}
              activeClassName="isActive"
            >
              {t('campaigns_page.campaign_settings.black_menu.Settings')}
            </NavLink>
          )}

          {isOwner && user && isEperimentalOwner && (
            <NavLink
              to={{
                ...location,
                pathname: `/main/campaign/${id}/experimental`,
              }}
              activeClassName="isActive"
            >
              Experimental
            </NavLink>
          )}
        </NavigationHeader>
      )}

      {user && (
        <Switch>
          <Route path={`${path}/main`} component={Main} />

          <Route path={`${path}/reports`} component={Reports} />

          <Route path={`${path}/general-reports/:type`} component={Report} />

          <Route exact path={`${path}/creatives`} component={CreativesNew} />

          <Route path={`${path}/creatives/:id`} component={CreativeEditNew} />

          {(isWithTargets || isOwner || isSelfUser) && (
            <Route exact path={`${path}/target`} component={Targets} />
          )}

          {(isOwner || isSelfUser) && (
            <Route path={`${path}/creative-add`} component={CreativeAddNew} />
          )}

          {(isWithCreatives || isWithTargets || isOwner || isSelfUser) && (
            <Route path={`${path}/additional`} component={Additional} />
          )}

          {isOwner && <Route path={`${path}/settings`} component={Settings} />}

          {isOwner && isEperimentalOwner && (
            <Route path={`${path}/experimental`} component={Experimental} />
          )}

          <Redirect
            from={path}
            to={{
              ...location,
              pathname: `${path}/main`,
            }}
          />
        </Switch>
      )}
    </div>
  );
};
export default Detail;
