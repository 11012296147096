import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

interface ISelectWrapperProps {
  className?: string;
  heightCustom?: number;
  isError?: boolean;
  isDisabled?: boolean;
  isVerticalStyle?: boolean;
  isOnlyView?: boolean;
  children: ReactNode;
  isSave?: boolean;
}

const SelectWrapper: FC<ISelectWrapperProps> = ({
  className,
  heightCustom = 0,
  isError,
  isDisabled,
  isVerticalStyle,
  isOnlyView,
  children,
  isSave,
}) => (
  <div
    className={cn('new_selectWrapper', className, `height-${heightCustom}`, {
      _isError: isError,
      _isDisabled: isDisabled,
      _isVerticalStyle: isVerticalStyle,
      _isOnlyView: isOnlyView,
      _isSave: isSave,
    })}
  >
    {children}
  </div>
);

export default SelectWrapper;
