export default {
  translations: {
    error_page: {
      err404: {
        title: 'Ошибка 404. Страница не найдена',
        text: 'Попробуйте начать с главной.',
        button: 'ВЕРНУТЬСЯ',
      },
      err500: {
        title: 'Ошибка 500',
        text: 'Извините, произошла ошибка',
        button: 'Вернуться на главную',
      },
      errNC: {
        title: 'Нет соединения с интернетом',
        text: 'Кажется, у Вас проблемы с подключением к сети.',
      },
      errUC: {
        title: 'Ведутся технические работы.',
        text: 'Совсем скоро все заработает. Приносим извинения за причиненные неудобства.',
      },
      errAB: {
        title: 'Мы заметили, что вы используете блокировщик рекламы.',
        text: 'Он частично блокирует функционал и препятствует полной загрузке сайта. Для получения доступа к площадке TD отключите все расширения, блокирующие рекламу для данного домена.',
      },
    },
    errors: {
      upload: {
        e1: 'Недопустимый формат файла. Требуется zip',
        e2: 'Недопустимый формат файла. Требуется png, jpeg, jpg, gif, svg',
        e3: 'Размер файла превышает 150 кб',
        e4: 'Размер файла превышает',
        e5: 'Недопустимый формат файла. Требуется ',
      },
      unknown_error: 'Неизвестная ошибка в методе',
      formdom: 'Неверный формат домена или URL',
      additional_cat_err:
        'Эта категория может существенно ограничивать доступный инвентарь',
      err_http_lorem:
        'Неверный формат ссылки. Используйте http:// или https:// или www. или {LOREM}',
      err_http_lorem2:
        'Неверный формат ссылки. Используйте http:// или https://',
      limitsym: 'Превышен лимит символов',
      percent_limit: 'Возможны значения не более 100',
      parameter_d_n_match:
        'Значение параметра передачи идентификатора не совпадает с эталонным',
      f1_d_n_cont_id:
        'В ссылке 1F отсутствует параметр передачи идентификатора показа',
      limit_symbol: {
        start: 'Превышено числовое значение. Максимум ',
        pixel: ' пикселей',
      },

      err_arh: 'Недопустимый формат файла. Требуется ZIP архив',
      err_value: 'Недопустимое значение',
      err1: 'Системная ошибка',
      err_file: 'Ошибка загрузки файла. \n',
      err40: 'Название файла не является разрешенным.',
      err40_link: 'Ошибка при загрузке. Неправильная ссылка',
      err41: 'Слишком большое число',
      err42: 'Слишком маленькое число',
      err43: 'Превышен лимит символов',
      err44:
        'Минимальное количество символов должно быть больше одного символа',
      err45: 'Поле обязательно для заполнения',
      err46: 'Неверный формат значения',
      err47: 'Неверный формат строки',
      err47_container: 'Ошибка, номер контейнера введен неверно.',
      err48: 'Данные не сохранены, ошибка валидации.',
      err49: 'Неизвестный вариант перечисления',
      err40_1: 'Ошибка валидации: Неверный формат значения.',
      err50:
        'Ошибка валидации данных. Файл не удовлетворяет требованиям загрузки',
      err90: 'Ошибка: Не найден элемент',
      err91: 'Ошибка: Нет доступа',
      err92: 'Ошибка: Нет данных для изменения',
      err93: 'Ошибка: Превышен лимит ответа',
      err94:
        'Ошибка данных, сервис временно недоступен. Повторите запрос позднее',
      err100: 'Неверный токен',
      err101: 'Ошибка проверки подписи',
      err102: 'Время жизни токена истекло',
      err103: 'Неверные данные токена (отсутствие параметров)',
      err104: 'Неверный тип токена',
      err105: 'Токен был изменен и уже недействителен',
      err109: 'Неверный тип токена',
      err121: 'Ошибка создания токена',
      err154_title: 'Что-то пошло не так',
      err154_desc: 'Слишком большой период. Запросите менее 30 дней',
      err201:
        'Пользователь с таким email заблокирован. Обратитесь к администратору',
      err202:
        'Пользователь с таким email не активирован. Обратитесь к администратору',
      err203: 'Регистрация не доступна',
      err218: 'Пароли не совпадают',
      err219: 'Пользователь с такими email уже существует',
      err220: 'Неверный формат email',
      err221: 'Неверный email или пароль',
      err222: 'Ошибка создания креатива',
      err223: 'Ошибка валидации: Неверный формат домена или URL',
      err223_https:
        'Ошибка валидации: Неверный формат ссылки. Используйте https://.',
      err224: 'Ошибка валидации: Неверный формат ID аудитории',
      err225: 'Ошибка валидации: Неверный формат значения таргетинга.',
      err227: 'Неверный старый пароль',
      err400: 'Ошибка базы данных',
      err900: 'Статистика временно недоступна',
      err2010: 'Ошибка при извлечении файла из архива',
      err2011: 'Ошибка копирования файла',
      err2020: 'Невозможно получить данные из файла',
      err2021: 'Ошибка. Файл Html не найден',
      err2030: 'Недопустимое содержимое',
      err2031: 'Недопустимое разрешение файла',
      err2032: 'Размер файла превышает',
      err2032_200: 'Размер файла превышает 200 Мб.',
      err2033: 'Файл не найден',
      err2034: 'Неверный формат.',
      err2035: 'Недопустимая ширина или высота изображения.',
      err2026: 'Произошла ошибка во время загрузки файла',
      err2040: 'Файл не обнаружен. Указан неверный URL',
      err2041: 'Ошибка во время загрузки файла по ссылке',
      err2050: 'Код должен содержать макрос {LINK_UNESC}',
      err2051: 'Любая ссылка должна содержать макрос {LINK_UNESC}.',
      err2052: 'Ошибка загрузки метаданных. Неверный формат размера мета тега',
      err2053: 'Ошибка загрузки метаданных. Не найден размер мета тега',
      err2054: 'Ошибка загрузки метаданных. Неправильный размер файла.',
      err2060: 'Недопустимая продолжительность видео',
      err2061: 'Недопустимое разрешение видео',
      err2062: 'Недопустимый тип видеокодека',
      err2063: 'Недопустимый тип аудиокодека',
      errAll: 'Упс, что-то пошло не так',
      err_none: 'Попробуйте загрузить другой файл.',
      err_valid: 'Ошибка валидации:',
      err_access: {
        owner: 'У Вас нет доступа. Обратитесь к администратору',
        organization: 'Нет доступа. Организация в архиве',
        agency: 'Нет доступа. Агентство в архиве',
        client: 'Нет доступа. Клиент в архиве',
        campaign: 'Нет доступа. Кампания в архиве',
      },
    },
    errors_desc: {
      hours_of_day: {
        t1: 'Таргет "По времени дня · UTC +3" уже добавлен',
        t2: 'Таргет "По времени дня · UTC +3" уже добавлен в текущей кампании.',
        t3: 'Отключите таргет По времени дня · UTC +3"  в креативах:',
      },
      hours_of_day_user: {
        t1: 'Таргет "По времени дня · UTC пользователя" уже добавлен',
        t2: 'Таргет "По времени дня · UTC пользователя" уже добавлен в текущей кампании.',
        t3: 'Отключите таргет "По времени дня · UTC пользователя" в креативах:',
      },
      days_of_week: {
        t1: 'Таргет "По дням недели · UTC +3" уже добавлен.',
        t2: 'Таргет "По дням недели · UTC +3" уже добавлен в текущей кампании.',
        t3: 'Отключите таргет "По дням недели · UTC +3" в креативах:',
      },
      days_of_week_user: {
        t1: 'Таргет "По дням недели · UTC пользователя" уже добавлен.',
        t2: 'Таргет "По дням недели · UTC пользователя" уже добавлен в текущей кампании.',
        t3: 'Отключите таргет "По дням недели · UTC пользователя" в креативах: ',
      },
    },
    upload: {
      error: 'Не все файлы загружены',
      success: 'Все файлы загружены',
    },
    just_words: {
      w1: 'Кампания',
      w2: 'Креатив · ',
      w3: 'Клиент',
      w4: 'Ошибка',
      w5: 'Агентство',
      w6: 'Кампании',
      w7: 'Пользователь',
      Width: 'Ширина',
      Height: 'Высота',
      confirm_action: 'Подтвердите действие',
      exit_page: 'Подтвердите переход',
      exit_page_currect: 'Вы действительно хотите покинуть страницу?',
      exit_page_yes: 'Покинуть эту страницу',
      exit_page_no: 'Остаться на этой странице',
      one_duplicate: 'Дубликат',
      several_duplicates: 'Дубликаты',
      one_is_successful_create: 'успешно создан',
      several_successful_create: 'успешно созданы',
    },
    offers_page: {
      offer: 'Оффер',
      offers: 'Офферы',
      create_offer: 'Создать оффер',
      change_offer: 'Изменение оффера',
      add_in_archive:
        'Вы действительно хотите добавить в архив оффер <bold>{{offer}}</bold>?',
      from_in_archive:
        'Вы действительно хотите извлечь архива оффер <bold>{{offer}}</bold>?',
      empty_offers: 'У вас нет офферов',
      links_will_chaned:
        'Ссылки будут изменены в <bold>{{creoCount}}</bold> креативах, где используется оффер <bold>{{offer}}</bold>',
      links_chaned_1: 'Ссылки изменены в',
      links_chaned_2: 'креативах',
      some_time: 'Это может занять немного времени ...',
      save_chanes:
        'Были внесены изменения в оффер, сохранить изменения? Это может занять немного времени.',
      back_to_list: 'Вернуться к списку офферов',
      use_offer:
        'В кампании используется оффер <bold>{{currentOfferTitle}}</bold> и редактирование ссылок из оффера невозможно',
      enter_name: 'Введите название',
      archive: 'Архив',
      chanes_saved: 'Изменения успешно сохранены',
      saving_chanes: 'Сохранение изменений',
      select_cliient: 'Выберите клиента',
      creating_offer: 'Создание оффера',
      confirm: 'Подтвердить',
      somthing_wrong: 'Упс ... что-то пошло не так',
      search_with_name: 'Поиск по названию',
      active_offer_none: 'Нет активных офферов',
      arhiv_offer_none: 'В архиве нет офферов',
      no_availibale_offers: 'Нет доступных офферов',
      offer_archived: 'Оффер <bold>{{title}}</bold> добавлен в архив',
      offer_launched: 'Оффер <bold>{{title}}</bold> извлечен из архива',
      offer_has_created: 'Оффер <bold>{{title}}</bold> создан',
    },
    common: {
      savedBlock_part1: 'Блок ',
      savedBlock_part2: ' успешно сохранен',
      targeting_key: {
        hour: 'Акт.Ч',
      },
      target_items: {
        ru: 'Россия',
        ua: 'Украина',
        rs: 'Сербия',
        lv: 'Латвия',
        kz: 'Казахстан',
        by: 'Белоруссия',
        kg: 'Киргизия',
        uz: 'Узбекистан',
        de: 'Германия',
        gb: 'Великобритания (GB)',
        uk: 'Англия (UK)',
        pl: 'Польша',
        'n/a': 'OTHER',
        us: 'США',
        6252001: 'США (6252001)',
        ng: 'Нигерия',
        my: 'Малайзия',
        ge: 'Грузия',
        ae: 'ОАЭ',
        tr: 'Турция',
        ar: 'Аргентина',
        vn: 'Вьетнам',
        in: 'Индия',
        ph: 'Филиппины',
        tj: 'Таджикистан',
        br: 'Бразилия',
        th: 'Тайланд',
        1: 'Понедельник',
        2: 'Вторник',
        3: 'Среда',
        4: 'Четверг',
        5: 'Пятница',
        6: 'Суббота',
        0: 'Воскресенье',
      },
      targetings: {
        source: 'Источники',
        network: 'Рекламная сеть SSP',
        geo_id: 'Регионы показа',
        geo_raw: 'ГЕО +',
        city: 'Гео · регионы и города',
        os: 'Операционные системы',
        browser: 'Браузеры',
        browser_lang: 'Язык интерфейса браузера',
        traffic_type: 'Тип трафика',
        au: 'Аудитории',
        au_bin_8: 'bin_au: Aidata (8)',
        au_bin_15: 'bin_au: UID 2.0 (15)',
        au_bin_20: 'bin_au: Аудитории пользователей клиента (20)',
        isp: 'Интернет—провайдеры',
        app: 'Приложения',
        private_deals: 'Прямые сделки',
        viewability: 'Viewability',
        uniq_show_creative: 'По номеру показа креатива пользователю',
        uniq_show_creative_hour: 'По номеру показа креатива пользователю в час',
        uniq_show_creative_day: 'По номеру показа креатива пользователю в день',
        days_of_week: 'По дням недели · UTC +3',
        days_of_week_user: 'По дням недели · UTC пользователя',
        hours_of_day: 'По времени дня · UTC +3',
        hours_of_day_user: 'По времени дня · UTC пользователя',
        site_domain: 'Домены',
        site_uri: 'URL',
        adgroup: 'AdGroup · Пресеты Google',
        page_context: 'Контекст (сайты)',
        socdem: 'Соц.дем',
        au_bin_info: 'Аудитории',
        au_bin_lada: 'Аудитории',
        uroven_dohoda: 'Уровень дохода',
        actions: 'Статистика по событиям в видеорекламе',
        actions_domain: 'Действия по доменам',
        au_bin_21: 'Аудитория площадок по ССП uid (21)',
        video_type: 'По формату видео-рекламы',
        semeinoe_polozhenie: 'Семейное положение',
        interesy: 'Интересы',
        clean_all_targeting_creative: 'Все таргеты креатива',
        clean_all_targeting_campaign: 'Все таргеты кампании',
        clean_all_targeting_success: 'успешно очищены',
        clean_target_success: 'успешно очищен',
        uniq_show_camp: 'По номеру показа кампании пользователю',
        uniq_show_camp_hour: 'По номеру показа кампании пользователю в час',
        uniq_show_camp_day: 'По номеру показа кампании пользователю в день',
        keywords: 'По ключевым словам и фразам',
        video_size: 'Размеры видео',
        sex: 'Пол',
        age: 'Возраст',
        sex_rtb: 'Пол RTB',
        categories_rtb: 'Интересы RTB',
        age_rtb: 'Возраст RTB',
        adn: 'Рекламные сети',
        publisher: 'По паблишерам',
        os_versions: 'По версии ОС',
        spotid: 'ID блока',
        rewarded: 'Реклама за вознаграждение',
        video_size_site_domain: 'Размер видео в разбивке по доменам',
        fullscreen: 'Полноэкранная реклама',
        exact_url: 'Таргетинг по полному совпадению URL',
      },
      pretargeting: {
        comp_retarget_enable_now:
          'Используются настройки претаргетинга кампании',
        comp_retarget_enable: 'Использовать настройки претаргетинга кампании',
      },
      targetings_error: {
        site_domain: {
          countModal:
            'Количество доменов превышает 1000, используйте WhiteList в претаргетах',
          count:
            'Количество доменов превышает 1000, используйте WhiteList в претаргетах',
          help: 'Вы можете добавить не более 1000 доменов. Для настройки таргета с большим количеством URL используйте WhiteList в претаргетах',
        },
        site_uri: {
          countModal:
            'Количество URL - адресов превышает 100, используйте WhiteList в претаргетах',
          count:
            'Количество URL - адресов превышает 100, используйте WhiteList в претаргетах',
          help: 'Вы можете добавить не более 100 URL - адресов. Для настройки таргета с большим количеством URL используйте WhiteList в претаргетах',
        },
        app: {
          resource_not_found: 'Ресурс с запрошенным идентификатором не найден',
        },
      },
      tags: {
        revert_change: 'Отменить изменение',
        second: 'с',
      },
      reports: {
        day: 'Общий отчёт',
        day_techfee: 'Общий отчёт. Разбивка по tech fee',
        yandex: 'Яндекс.Метрика. Cводный отчёт',
        'nmi-integration-src': 'NMI отчёт',
        integration: 'Интегрированный отчёт',
        socdem: 'Социально-демографический отчёт',
        geo_city: 'Отчёт по гео',
        shownum: 'Отчёт по частоте показов',
        extended: 'Расширенный отчёт',
        none: 'У Вас нет ни одного отчета',
        no_data: 'Нет данных по отчету',
        no_reports: 'Нет доступных отчетов',
        general: {
          t1: 'Показы',
          t2: 'Дата',
          t3: 'Клики',
          t4: 'Расход',
          t5: 'Расход: ',
          t6: 'Город',
          t7: 'Частота показа',
          t8: 'Другое',
          t9: '',
          t10: '',
          t11: '',
          t12: '',
          t13: '',
          t14: '',
          t15: '',
          actions: 'Действия',
        },
      },
      modal: {
        copy_list: 'Копировать список',
      },
      empty_data: 'Нет данных',
    },
    charts: {
      impression: 'Визиты',
      click: 'Клики',
      view: 'Показы',
      spent: 'Потрачено',
      total: 'Итого',
      utm_tags: 'Utm_Метки',
    },
    statuses: {
      launched: 'Активна',
      stopped: 'Остановлена',
    },
    form: {
      common: {
        authorization: 'Авторизация',
        login: 'Логин',
        register: 'Зарегистрироваться',
        signin: 'Войти',
        signup: 'Регистрация',
        forgot: 'Забыли пароль?',
        email: 'E-mail',
        password: 'Пароль',
        have_acc: 'Уже есть аккаунт?',
        new_user: 'Новый пользователь?',
        confirm: 'Подтвердите Пароль',
        login_lab: 'Логин',
        mob_phone: 'Номер телефона',
        skype: 'Скайп',
        telegram: 'Телеграм',
        wrongPassword: 'Неверный пароль',
        passesMiss: 'Пароли не совпадают',
        passMiss: 'Пароль не совпадает',
        new_password: 'Новый пароль',
        saveChanges: 'Сохранить изменения',
        enter_email: 'Введите адрес электронной почты',
        wrong_format: 'Неверный формат email',
        phone: 'Телефон',
        login_rules: 'Логин должен быть от 3 до 100 символов',
        phone_rules: 'Телефон должен быть от 3 до 100 символов',
        required: 'Обязательное поле',
        password_rules: 'Пароль должен быть больше 6 символов',
        password_long: 'Пароль слишком длинный',
        password_short: 'Пароль слишком короткий',
        email_exist: 'Такой email уже существует',
        login_long: 'Логин слишком длинный',
        login_short: 'Логин слишком короткий',
        field_long: 'Поле слишком длинное',
        field_short: 'Поле слишком короткое',
        offer: 'Регистрируясь, Вы соглашаетесь с ',
        offer__link: 'Условиями оферты',
        offer__link__auth: 'Пользовательское соглашение',
        not_valid_link: 'Cсылка недействительна',
        fill_the_field: 'Заполните поле',
      },
    },
    top: {
      partner_managed: 'управляется агентством',
      partner_organization: 'управляется организацией',
      exit: 'Выход',
      menu: {
        clients: 'Клиенты',
        campaigns: 'Кампании',
        containers: 'Контейнеры',
        load_data: 'Загрузка данных',
        account: 'Аккаунт',
        bwlists: 'Черные и белые списки',
        agency: 'Агентства',
        partners: 'Партнеры',
        users: 'Пользователи',
        uploadbwl: 'Загрузка BWLists',
        history_list: 'История изменений',
        control_panel: 'Панель управления',
        project: 'Проект',
      },
    },
    balance_modal: {
      from: 'Откуда',
      to: 'Куда',
      enter_am: 'Введите сумму',
      transfer: 'Перевести',
      not_enough: 'Недостаточно средств для перевода',
      success_1: 'В {{label}} было успешно переведено {{amount}} {{currency}}',
      select_client: 'Выберите счет списания',
      select_count: 'Выберите счет пополнения',
    },
    bwlist_modal: {
      title: 'Загрузка BWLists',
      dropzone_text: `Выберите или перетащите файлы для блэк листов с разрешенными названиями. Если файл с таким именем уже существует - он перезапишется. 
        Размер всех файлов в сумме не должен превышать 300 Мб, размер одного файла - не более 200 Мб.`,
      button_ok: 'OK',
    },
    blackLists: {
      title: 'DMP Black Lists',
      file_type: 'тип файла',
      global: 'Глобальный',
      clients: 'Клиентский',
      exported: 'Выгружено',
      publisher: 'Паблишер',
      who_deleted: 'кто удалил',
      selected_string: 'Выбрано строк <bold>{{value}}</bold>',
      del_from_bl: 'Удалить из Black list',
      add_from_bl: 'Добавить в Black list',
      del_from_bl_real:
        'Вы действительно хотите удалить выбранные строки из файла BL?',
      add_from_bl_real:
        'Вы действительно хотите добавить выбранные строки в файл BL?',
      source: 'Источник',
      domain: 'Домен',
      adding_to_bl: 'Добавление в Black list',
      deleting_from_bl: 'Удаление из Black list',
      date_del: 'Дата удаления записи',
      date_add: 'Дата добавления записи',
      on_in_bl: 'Включен в BL',
      off_from_bl: 'Исключен из BL',
      something_wrong: 'Что-то идет не так, файл перестал обновляться',
    },
    multiple_upload: {
      status: {
        all: 'Все файлы загружены',
        not_all: 'Не все файлы загружены',
      },
    },
    login_page: {
      error_email: 'Неверный формат email',
      error_password: 'Неверный email или пароль',
      language_tit: 'Язык',
      user_blocked:
        'Пользователь с таким email заблокирован. Обратитесь к администратору',
      user_noact:
        'Пользователь с таким email не активирован. Обратитесь к администратору',
      registration_unavailable: 'Регистрация не доступна',
      mail_already_registered: 'Пользователь с такими email уже существует',
      confirm_pass: 'Пароли не совпадают',
      unknown_error: 'Неизвестная ошибка',
      user_is_not_active:
        'Пользователь с таким email не активирован. Обратитесь к администратору',
      hasEmail: 'Пользователь с такими email уже существует',
      weSendInstructions: 'Мы отправили Вам письмо с инструкцией',
      successfullyVerified: 'E-mail адрес был успешно подтвержден',
      passChange: 'Пароль был успешно изменен',
      forgotPass: 'Забыли пароль?',
      forgotYourPass: 'Забыли пароль?',
      resetPass: 'Сбросить пароль',
      changeYourPass: 'Изменить пароль',
    },
    clients_page: {
      clients_tit: 'Клиенты',
      add_client: 'Добавить клиента',
      client_name: 'Название',
      clients_search: 'Поиск в клиентах',
      clients_all: 'Все клиенты',
      clients_stopped: 'Остановленные',
      clients_deleted: 'Удаленные',
      active_clients: 'Активные клиенты',
      archive_clients: 'В архиве',
      edit_icon: 'Редактировать',
      add_arc_icon: 'Добавить в архив',
      rem_arc_icon: 'Извлечь из архива',
      report_client_name: 'Название клиента',
      report_client_id: 'id клиента',
      client_report: 'Отчет клиента',
      client_archive_from_text:
        'Вы действительно хотите извлечь из архива клиента ',
      client_archive_to_text:
        'Вы действительно хотите добавить в архив клиента ',
      client_archive_from_alert: ' извлечен из Архива',
      client_archive_to_alert: ' добавлен в Архив',
      show_columns: 'Показывать в списке кампаний столбцы статистики',
      nav_menu: {
        basic_settings: 'Основные настройки',
        reports: 'Отчеты',
      },
      client_create: {
        hint1: 'Обязательно для заполнения',
        hint2: 'Ошибка при загрузке. Размер файла превышает 1 Mb',
        hint3: 'Ошибка при загрузке. Размер изображения превышает 2000x2000 px',
        hint4: 'Ошибка при загрузке. Неверный формат',
        hint5: 'Ошибка при загрузке. Неправильная ссылка',
        hint6: 'Клиент был успешно ',
        hint7: 'сохранен',
        hint8: 'добавлен',
        hint9: 'Ошибка при загрузке.',
        hint10: 'Размер файла превышает 1 Mb',
        hint11: 'Файл не найден',
        hint12: 'Недоступный тип файла',
        hint13: 'Произошла ошибка. Невозможно сохранить изменения',
        client_create_tit: 'Добавление клиента',
        client_load_img: 'Загрузить изображение',
        client_img_link: 'Ссылка на изображение',
        client_img_formats: 'Допустимые форматы: png, jpeg, jpg, svg.',
        client_img_size: 'Максимальный размер — 1 Mb.',
        client_img_maxsize: 'Максимальное разрешение — 2000×2000 пикселей.',
        client_name: 'Название *',
        client_site: 'Сайт',
        client_reports: 'Отчеты',
        client_use_systemset: 'Использовать настройки системы',
        client_report_list:
          'Список доступных отчетов можно изменить после добавления клиента',
        client_agency: 'Агентство',
        client_default_title: 'Новый клиент',
      },
      client_edit: {
        client_edit_tit: 'Редактирование клиента',
        client_edit_delete_avatar: 'Удалить',
        client_edit_reports: 'Отчеты',
        client_edit_use_settings: 'Использовать настройки системы',
        day: 'Общий отчёт',
        tech_fee: 'Разбивка по tech fee',
        metrika: 'Яндекс.Метрика.',
        consolidated_report: 'Cводный отчёт',
        nmi_report: 'NMI отчёт',
        integr_report: 'Интегрированный отчёт',
        soc_dem_report: 'Социально-демографический отчёт',
        geo_report: 'Отчёт по гео',
        frequency_shows: 'Отчёт по частоте показов',
        extended_report: 'Расширенный отчёт',
        included: 'Включено',
        turned_off: 'Выключено',
        available: 'Доступно',
        moderation_creatives: 'Модерация креативов',
        update_hand: 'Одобрять креативы в ручном режиме',
        update_auto: 'Автоматически одобрять креативы',
        categories_creatives: 'Категории креативов',
        switch_points:
          'Включенные пункты будут отображаться в креативах во вкладке категории',
        animation: 'Анимация',
        jest: 'Тип жести',
        erotic: 'Тип эротики',
        link_to_image: 'Ссылка на изображение',
        link_to_image_link: 'Ссылка',
        link_to_image_add: 'Добавить',
        link_to_image_cancel: 'Отменить',
      },
      clients_none: {
        empty1: 'У вас нет активных клиентов',
        empty2: 'У вас нет остановленных клиентов',
        empty3: 'У вас нет клиентов в архиве',
        empty4: 'У вас нет удаленных клиентов',
        empty5: 'У вас еще нет клиентов.',
        empty6: 'Нажмите, чтобы создать нового.',
      },
      limit_groups: {
        title: 'Группы лимитов',
        title2: 'Группа лимитов',
        group: 'Группа',
        created: 'успешно создана',
        changed: 'успешно изменена',
        add_new: 'Добавить новую группу',
        no_settings: 'Для клиента не настроены группы лимитов',
        clear_group: 'Очистить группу',
        search_camp: 'Поиск кампании',
        show_groups: 'Показывать группы (лимиты)',
        show_groups_hints:
          'Групповые настройки лимитов в кампаниях и креативах',
        cancel_del: 'Отменить удаление',
      },
    },
    campaigns_page: {
      camp_hint: {
        hint1: 'Данные не сохранены, ошибка валидации',
        hint2: '',
        hint3: '',
        hint4: '',
        hint5: '',
      },
      coef_name: 'Название алгоритма',
      choose_action: 'Выберите действие из поля',
      icons: 'Иконки',
      popular: 'Популярные',
      campaigns: 'Кампании',
      camp_search: 'Поиск в кампаниях',
      show_full_name: 'Полное название РК',
      show_short_name: 'Сокращенное название РК',
      create_btn: 'Создать кампанию',
      campaign_additional: {
        special: {
          title: 'Специальные настройки',
          lifetime_label: 'Время жизни клика',
          lifetime_help:
            'Время жизни клика задается в днях, максимально 60 дней',
        },
      },
      experimental: {
        by_default: 'Установить значение по умолчанию',
      },
      campaign_category: {
        Video: 'Видео',
        MS_performance_audience: 'Перфоманс аудитория МS',
        MS_performance_context_audience: 'Перфоманс контекстная аудитория MS',
        Performance_audience_pbd: 'Перфоманс аудитория ПБД',
        Performance_audience_outside_DMP: 'Перфоманс аудитория сторонних DMP',
        Reach_WL: 'Охватный white list',
        Reach_Premium: 'Охватный premium',
        Reach_Middle: 'Охватный middle',
        Reach_Budget: 'Охватный budget',
        inn_app: 'In-App',
      },
      campaigns_black_header_main: {
        act: 'Активные',
        pause: 'Остановленные',
        del: 'Удаленные',
        all: 'Все кампании',
        arch: 'Архивные',
      },
      campaigns_comments: {
        title: 'Комментарии',
        add_comment_btn: 'Добавить комментарий',
        add_comment_tit: 'Добавление комментария',
        del_comment: 'Удаление комментария',
        edit_comment: 'Изменение комментарий',
        comment_deleted: 'Комментарий удален',
        confirm_del: 'Вы действительно хотите удалить комментарий',
        show_comments_part: 'Показать все комментарии',
        hide_comments: 'Скрыть комментарии',
        roll_up: 'Свернуть',
        expand: 'Развернуть',
        saved: 'Сохранено',
        save_error: 'Ошибка сохранения. Повторите через минуту.',
        no_search: 'Ничего не найдено',
        search_placeholder: 'Введите комментарий или email',
        symbols: 'символов',
        empty: 'Нет комментариев',
      },
      campaigns_header_main: {
        total: 'Общая статистика',
        name: 'Название',
        show: 'Показы',
        click: 'Клики',
        spent: 'Расход',
        budget: 'Бюджет',
        status: 'Состояние',
        cpc: 'CPC',
        cpm: 'CPM',
        ctr: 'CTR',
      },
      campaigns_status: {
        act: 'Активна',
        pause: 'Остановлена',
        del: 'Удалена',
      },
      campaigns_copy_modal: {
        title: 'Копирование кампании',
        de_start_predix_one: '',
        de_start_predix_many: '',
        mass_copy_success_one: 'успешно скопирована',
        mass_copy_success_many: 'успешно скопированы',
        mass_copy_in_one_client: 'в клиента',
        mass_copy_in_many_clients: 'в клиентов',
        unknow_error: 'Неизвестная ошибка копирования',
        error_copy: 'Ошибка копирования',
        limit_copy: 'Копирование ограничено из-за конфликта в таргетингах.',
        select_comp:
          'Выберите клиентов, в которые необходимо скопировать кампании',
      },
      campaign_stat_icon: 'Статистика',
      campaign_del_icon: 'Удалить',
      campaign_stat_modal: {
        tit: 'Статистика',
        shows: 'Показы',
        clicks: 'Клики',
        spent: 'Расход',
        budget: 'Бюджет',
      },
      chart: {
        show: 'Показать график',
        hide: 'Скрыть график',
        no_stats: 'Cтатистика отсутствует',
      },
      empty: {
        act: 'У вас нет активных кампаний',
        no1: 'У вас еще нет кампаний.',
        no2: 'Нажмите, чтобы создать новую.',
        no3: 'У вас нет остановленных кампаний',
        no4: 'У вас нет удаленных кампаний',
        no5: 'У вас нет кампаний в архиве',
      },
      create_campaign: {
        camp_create_hint: 'Кампания успешно создана',
        black_header: {
          back: 'Назад',
          set: 'Настройки',
          targ: 'Таргетинг',
        },
        newcamp_tit: 'Новая кампания',
        req_fill: 'Поле обязательно для заполнения',
        tit: 'Создание кампании',
        client: 'Клиент',
        adv_name: 'Рекламодатель',
        name: 'Название *',
        categories_campaign: 'Категории кампании',
        status: 'Статус',
        period: 'Период',
        data_start: 'Начало',
        date_stop: 'Конец',
        budget_tit: 'Бюджет',
        budget_text:
          'При израсходовании средств кампания будет остановлена. Бюджет можно увеличить в любое время',
        budget: 'Бюджет',
        price: 'Стоимость',
        enter: 'Введите…',
        without_limit: 'Без лимита',
        limits: 'Лимиты',
        clicks: 'Клики',
        shows: 'Показы',
        shows_one: 'Показы на одного пользователя',
        limits_budget: 'Бюджет',
        hour: 'Час',
        day: 'День',
        hour_rub: 'Час',
        day_rub: 'День',
        all: 'Всего',
        create_btn: 'Создать',
        cancel_btn: 'Отмена',
        create_text:
          'Создавайте новые рекламные кампании при помощи нашей площадки Trading Desk. Для всех кампаний доступен широкий набор действий. Вы всегда сможете следить за их состоянием, а также регулярно просматривать статистику и подробные отчеты.',
        create_text2:
          'Если у вас есть вопросы или нужна дополнительная информация, ознакомьтесь с TD ',
        create_text_link: ' документацией',
        ord_name: 'Название в орд',
      },
      pretarg_text: 'Для настройки таргетов необходимо создать эту кампанию',
      targeting_word: 'Таргетинг',
      campaign_settings: {
        date_hint: {
          h1: 'Дата завершения кампании должна быть больше даты старта',
          h2: 'Формат даты должен быть DD/MM/YYYY',
          h3: '',
        },
        campaign_prompt: {
          main: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D0%B0%D1%8F-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D1%8F',
          },
          period: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4',
          },
          moreOptimisation: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D1%80%D0%B0%D1%81%D1%88%D0%B8%D1%80%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F-%D0%BE%D0%BF%D1%82%D0%B8%D0%BC%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F',
          },
          limits: {
            link: 'https://terratraf.readme.io/docs/campaigns-limits',
          },
          container: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%B9%D0%BD%D0%B5%D1%80',
          },
          optimization: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BE%D0%BF%D1%82%D0%B8%D0%BC%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F',
          },
        },
        hint_for_card: {
          optim_part1: 'Чтобы начать ротацию, ',
          optim_part2: 'заполните раздел "Оптимизация".',
          limit_part1: 'Установите лимиты ',
          limit_part2: 'для ограничения бюджета и других параметров.',
        },
        camp_name_fied: {
          name1: '"Рекламная кампания"',
          name2: '"Период"',
          name3: '"Оптимизация"',
          name4: '"Лимиты"',
        },
        on_edit: {
          hint1: 'Блок ',
          hint2: ' успешно сохранен',
          hint3: 'Блок успешно сохранен',
        },
        enter_data_tip: 'Введите данные',
        click_hint: {
          hint1: '[ДД.ММ.ГГГГ]',
          hint2: 'пробел',
          hint3: '[значение]',
          hint4: '[коэффициент]',
          hint5: 'Например: 18.12.2019 300 1.4',
          hint6: 'Например: 18.12.2019 1.4',
        },
        creative_hint: 'Вы можете настроить таргетинг на ',
        creative_hint2: 'кампанию ',
        creative_hint3: 'или на каждый креатив отдельно',
        nostat: 'Статистика недоступна',
        black_menu: {
          Main: 'Основные',
          Back: 'Назад',
          Main_settings: 'Основные настройки',
          Targeting: 'Таргетинг',
          Creatives: 'Креативы',
          Reports: 'Отчеты',
          Settings: 'Настройки',
          Settings_aditional: 'Дополнительные настройки',
        },
        ListSRMethods: {
          enter_data: 'Введите данные',
          label: 'Метод пересчета статистики',
          CAMPAIGN_TYPE: 'по типу кампании',
          COEFFICIENTS: 'по коэффициентам',
          DSP_CALCULATION: 'по клиентскому расходу из DSP',
          TD_CALCULATION: 'по клиентскому расходу из TD',
          RAW_STAT: 'по RAW STAT',
          MAX_FIX_CPC_MARGINALITY: 'максимальная цена клика',
          SELF_SERVICE_MARKUP: 'наценка Self-Service',
          commission_agency: {
            label: 'Комиссия агентства, %',
            hint: 'Для изменения CPM в DSP применяется двойная наценка. Скрытая комиссия агентства и наша комиссия за self-service',
          },
          commission_self_service: {
            label: 'Комиссия Self-Service, %',
            hint: 'Для изменения CPM в DSP применяется двойная наценка. Скрытая комиссия агентства и наша комиссия за self-service',
          },
          max_cpc: {
            label: 'МАКСИМАЛЬНЫЙ CPC, ₽',
            hint: 'Максимальная цена за клик для метода MAX_FIX_CPC_MARGINALITY',
          },
          marginality: {
            label: 'Маржинальность, %',
            hint: 'Маржинальность для метода пересчета по CPC MAX_FIX_CPC_MARGINALITY. Задается в процентах',
          },
          hidden_coef: {
            label: 'Скрытая комиссия, %',
          },
        },
        Client: 'Клиент',
        Advertiser_name: 'Рекламодатель',
        Campaign_name: 'Рекламная кампания',
        tags_label: 'Теги',
        tags_placeholder: 'Введите данные',
        Running: 'Активна',
        Paused: 'Остановлена',
        Edit: 'Редактировать',
        Period: 'Период',
        Start_date: 'Начало',
        End_date: 'Конец',
        Optimization: 'Оптимизация',
        moreOptimisation: {
          title: 'Расширенная оптимизация',
          algoritm: 'Алгоритм оптимизации',
        },
        Container: 'Контейнер',
        container_block: {
          cid: 'Идентификатор контейнера',
          save: 'Сохранять идентификатор клика (strk) в cookie при редиректе',
          additionally: 'Дополнительно',
        },
        Budget: 'Бюджет',
        Budget_RAW: 'Бюджет RAW',
        Please_note:
          'При израсходовании средств кампания будет остановлена. Бюджет можно увеличить в любое время',
        cost: 'Стоимость',
        Please_enter: 'Введите…',
        Limit_display_Adriver: 'Ограничить частоту показа Adriver',
        Unlimited: 'Без лимита',
        Limits: 'Лимиты',
        Clicks: 'Клики',
        Impressions: 'Показы (всего)',
        per_user: 'Показы (пользователь)',
        Hour: 'Час',
        Day: 'День',
        Total: 'Всего',
        Total_raw: 'Бюджет',
        additional: {
          set_tit: 'Настройки',
          coefficient: 'Коэффициенты',
          method: 'Метод расчета агентского расхода',
          range: 'КОЭФФИЦИЕНТ РАСЧЕТА ДИАПАЗОНА',
          info_1:
            'Коэффициенты расчета диапазона применяются для кампаний, которые используют метод расчета статистики "По типу кампании"',
          info_2:
            'Чтобы в кампаниях автоматически применился метод расчета статистики "По типу кампаний" в админке TD, необходимо включить <bold> lable - {{method}}</bold> в агентстве.',
          price: 'Цена',
          type: 'Тип',
          clicks: 'Клики',
          from: 'От',
          to: 'До',
          maxToBeMax:
            'Максимальное число диапазона должно быть больше минимального',
          hide_real_statistics: {
            title: 'Скрыть реальную статистику',
            hintStrong: 'Скрыта статистика кампании!',
            hintSpan: 'Если нужно вывести статистику, то снимите галку.',
          },
          aggregation: 'Агрегация кампаний',
          ddmm: 'Выберите...',
          eg: 'Например:',
          ext_sour: 'Привязка внешних источников',
          ids: 'Идентификаторы',
          id_from_ss: 'Введите id из СС',
          dashboard_ids: 'Dashboard',
          reports: 'Отчеты',
          use_client: 'Использовать настройки клиента',
          enabled: 'Включено',
          extend_rep: 'Расширенный отчет',
          data: 'Данные',
          enter_date: 'Введите данные',
          data_based: 'Данные строятся на основании базового отчета',
          use_special:
            'Используйте специальный символ “ | “ для разделителя столбцов',
          help_text:
            'Для пользователей с правами администратора доступен раздел с широким спектром настроек, позволяющий задавать коэффициенты цены по дням (для типа цены CPA), привязывать внешние источники, регулировать список доступных отчетов для кампании и многое другое.',
          pre_targ_tit: 'Претаргетинг',
          pre_targ_main: 'Основные',
          pre_targ_search: 'Поиск',
          pre_targ_brand_on: 'Включен',
          pre_targ_brand_off: 'Выключен',
          pre_targ_brand_rot: 'Ротация',
          pre_targ_brand_rotall: 'Ротируем на все сайты',
          pre_targ_brand_rotrus:
            'Ротируем на сайты, где встретился русский текст',
          pre_targ_brand_rotcat: 'Исключить из ротации категории',
          pre_targ_categories: {
            drugs_n_diseases: 'Наркотики и заболевания',
            indecency: 'Непристойность',
            media_test: 'Фарм препараты',
            obscenity_br: 'Русский мат (корни)',
            obscenity_r: 'Русский мат (расширенный)',
            slang: 'Сленг',
            swearing_en: 'Английский мат',
            swearing_ru: 'Русский мат (наиболее употребляемый)',
            tragedy: 'Трагедии',
            ujc: 'Пользовательский контент',
          },
          statistics: {
            title: 'Статистика',
            text: 'Показывать в кампании столбцы статистики',
            select_all: 'Выбрать все',
            selected: 'Выбрано',
            of: 'из',
            save_empty_message:
              'Вы не выбрали ни одного параметра. После сохранения отобразятся стандартные настройки.',
            exit_message: 'Вы действительно хотите покинуть страницу?',
            show_columns: 'Показывать в списке креативов столбцы статистики',
          },
          links: {
            copy: 'ID дизапрувов успешно скопированы в буфер обмена',
            title: 'Ссылки и аппрувы по креативам',
            tooltip: 'Копировать ID дизапрувов',
            tabs: {
              links: 'Ссылки',
              google: 'Google',
              yandex: 'Yandex',
            },
            table: {
              link: 'Ссылка для перехода',
              pixel: 'Пиксель аудита',
              pixel_additional: 'Дополнительный пиксель аудита',
              status: 'Статус',
            },
          },
        },
        reports: {
          reports_tit: 'Отчеты',
          back: 'Назад',
          donwload: 'Скачать',
          see: 'Просмотр',
          nodata: 'Нет данных за указанный период',
          gen_report: 'Общий отчет',
          targ_report: 'Отчёт по таргетингу',
          shows_report: 'Отчёт по частоте показов',
          geo_report: 'Отчёт по ГЕО',
          socdem_report: 'Социальная демография',
          integr_report: 'Интегрированный отчёт',
          yandex_report: 'Яндекс.Метрика: сводный отчёт',
          fee_report: 'Общий отчёт. Разбивка по tech fee',
          hide_fromchart: 'Скрыть из графика',
          show_fromchart: 'Отобразить на графике',
          stat_tit: 'Статистика',
          sort_high: 'Сортировать по возрастанию',
          sort_low: 'Сортировать по убыванию',
          sort_cancel: 'Отменить сортировку',
        },
        push_notifications: {
          title: 'PUSH-уведомления',
          hint: 'Получайте PUSH-уведомления об изменениях в кампании',
          hint2: 'Получайте PUSH-уведомления об изменениях в креативе',
          browser_push_error_1:
            'У Вас заблокирован сайт для получения уведомлений.',
          browser_push_error_2:
            'Разрешите уведомления в настройках Вашего браузера.',
        },
      },
      stat_chart: {
        tit: 'Статистика',
        shows: 'Показы',
        clicks: 'Клики',
      },
      camp_del_tit: 'Удаление кампании',
      camp_is_deleted: 'удалена',
      camp_del_quest: 'Вы действительно хотите удалить',
      camp_archive_from_text:
        'Вы действительно хотите извлечь из архива кампанию ',
      camp_archive_to_text:
        'Вы действительно хотите добавить в архив кампанию ',
      camp_archive_from_alert: ' извлечена из Архива',
      camp_archive_to_alert: ' добавлена в Архив',
      copy_campaign: {
        t1: 'Настройки таргетов кампании ',
        t1_2: 'Настройки таргетов и претаргетов кампании ',
        t2: ' cкопированы в кампании',
        t3: 'Выберите кампании, в которые необходимо скопировать настройки таргетов',
      },
      mass_copy: 'Копировать в другого клиента',
      mass_actions_success: 'Кампании успешно',
      mass_actions_success_eng: '',
      bulk_action_alert: 'Вы действительно хотите',
      client_company_alert: 'кампании клиента',
      client_company_alert_for_del: 'кампании клиента',
      client_company_alert_for_double:
        'Вы действительно хотите дублировать кампании клиента',
      client_company_expect_to: 'извлечь из архива кампании клиента',
    },
    users_activity: {
      users: 'Пользователи',
      client: 'Клиент',
      organisation: 'Организация',
      agency: 'Агентство',
    },
    users_api: {
      create_title: 'Создание пользователя API',
      info_1:
        'Создание технических пользователей позволяет дать доступ к API2 с  определенным набором методов.Используется для операций с TD из внешних систем.',
      select_partners: 'Выберите партнеров, к которым будет доступ',
      set_groups: 'Задайте группы пользователю. Достаточно включить default',
      use_tegs:
        'Теги используйте, если вам нужно получить статистику по определенным кампаниям. Обратите внимание, теги также должны быть добавлены в настройках кампании с идентичным названием.',
      methods_list:
        'Список метод позволяет контролировать доступ к информации.',
      see_methods_info: 'См. описание методов',
      methods: 'Методы',
      token: 'Токен',
      passwordHelp: 'Пароль должен быть больше трех символов',
    },
    users_page: {
      page_title: 'Пользователи',
      search_placeholder: 'Поиск по логину',
      create_btn: 'Создать пользователя',
      users_api: 'Пользователи API',
      update_token: 'Обновить токен',
      token_has_update: 'Токен успешно обновлен',
      token: 'Tокен',
      userDel: 'Пользователь',
      userHBD: 'удален',
      navigation: {
        allUsers: 'Все пользователи',
        online: 'Онлайн',
        statistics: 'Статистика',
      },
      table: {
        name: 'Название',
        type: 'Тип',
        login: 'Логин',
        groups: 'Группы',
        status: 'Статус',
        delete_user: 'Удалить',
        edit_user: 'Редактировать',
        active_time: 'Время активности',
        count_pages: 'Страницы',
        count_get: 'Просмотры',
        count_edit: 'Действия',
        hinits: {
          active_time:
            'Время активности пользователя переведено в часы.\n Пользователь становится неактивным после 5 минут бездействия',
          count_pages: 'Количество всех загруженных страниц.',
          count_get:
            'Количество запросов на просмотры настроек кампаний, креативов и статистики по таргетам.',
          count_edit:
            'Количество запросов на добавление и изменение общих настроек кампаний и креативов.\n А также дополнительные настройки: теги, комментарии, таргетинг.',
        },
      },
      statuses: {
        active: 'Активен',
        blocked: 'Заблокирован',
      },
      delete_modal: {
        title: 'Удаление пользователя',
        text: 'Вы уверены, что хотите удалить пользователя',
      },
      settings_modal: {
        create_title: 'Создать пользователя',
        edit_title: 'Редактировать пользователя',
        complete_registration: 'Завершить регистрацию',
        registration_done: 'Регистрация завершена',
        email: 'Адрес email',
        roles_title: 'Роли',
        parthner_title: 'Партнер',
        select_roles: 'Выбрать роли',
        select_parthner: 'Выбрать партнера',
      },
    },
    partners_page: {
      page_title: 'Партнеры',
      search_placeholder: 'Поиск id | xxhash | Название',
      table: {
        name: 'Название',
        type: 'Тип',
        login: 'Логин',
        balance: 'Баланс',
        delete: 'Удалить',
        edit: 'Редактировать',
        enable_registration: 'Регистрация разрешена. Запретить?',
        deny_registration: 'Регистрация запрещена. Разрешить?',
        change_registration: 'Регистрация изменена',
      },
      statuses: {
        active: 'Активен',
        blocked: 'Заблокирован',
      },
      delete_modal: {
        title: 'Удаление',
        text: 'Вы уверены, что хотите удалить ',
      },
    },
    agencies_page: {
      agency_title: 'Агентства',
      agency_new: 'Новое агентство',
      create_btn: 'Создать агентство',
      search_placeholder_agency: 'Поиск в агентствах',
      agencies_active: 'Активные',
      agencies_all: 'Все агентства',
      agencies_report: 'Отчет агентства',
      show_audiences: 'Показать аудитории',
      agency_none: {
        empty1: 'У вас нет активных агентств',
        empty2: 'У вас нет остановленных агентств',
        empty3: 'У вас нет агентств в архиве',
        empty4: 'У вас нет удаленных агентств',
        empty5: 'У вас еще нет агентств.',
        empty6: 'Нажмите, чтобы создать новое.',
      },
      archive_modal: {
        title_to: 'Добавление в архив',
        title_from: 'Извлечение из архива',
        btn_to: 'В архив',
        btn_from: 'Извлечь',
        text_to1: 'Вы действительно хотите добавить в архив агентство',
        text_to2: '',
        text_from1: 'Вы действительно хотите извлечь из архива агентство',
        text_from2: '',
        alert_to1: 'Агентство',
        alert_to2: 'добавлено в архив',
        alert_from1: 'Агентство',
        alert_from2: 'извлечено из архива',
      },
      modal: {
        title: 'Создание агентства',
        edit_title: 'Редактирование агентства',
        name: 'Название',
        site: 'Сайт',
      },
      agency_create: {
        hint1: 'Агентство было успешно ',
        hint2: 'сохранено',
        hint3: 'добавлено',
      },
      report: {
        title: 'Отчет по аудиториям',
        add_au: 'Добавить аудиторию',
        titleml: 'Аудитории ML',
        reportAuMl: 'Отчет по аудиториям ML',
        del_au: 'Удаление аудитории {{title}}',
        del_ask:
          'Вы действительно хотите удалить аудиторию <bold>{{title}}</bold>?',
        hint: 'Статистика данной кампании будет учитываться в выбранной аудитории ML',
        hint_link: 'агентского отчета',
        au_empty: 'У вас еще нет аудиторий ML.<br/> Добавьте первую аудиторию.',
        success_del: 'Аудитория <bold>{{title}}</bold> успешно удалена',
        table_title: 'Название/ID клиента',
      },
    },
    project_page: {
      page_title: 'Проект',
      main_settings: 'Основные настройки',
      settings: 'Настройки',
      show_columns: 'Показывать в списке клиентов столбцы статистики',
      table: {
        name: 'Название',
        code: 'Код',
        tech_domain: 'Технический домен',
        ext_domain: 'Внешний домен',
      },
      errors: {
        error1: 'ошибка',
      },
      forms: {
        field: {
          required: 'Обязательно',
          off: 'Выключено',
          optional: 'Опционально',
        },
        project_title: 'Проект',
        code: 'Код',
        title: 'Название',
        offer_eng: 'Оферта (английская версия)',
        ext_domain: 'Внешний домен',
        currency: 'Валюта',
        partner: 'Партнер',
        branding_title: 'Брендирование',
        slogan: 'Слоган ',
        logo_medium: 'Лого в авторизации',
        logo_small: 'Лого в хедере',
        logo_letters: 'Логотип письма',
        background_letter: 'Фон письма',
        background: 'Фон ',
        not_selected: 'Не выбрано',
        logotype: 'Логотип проекта',
        background_email: 'Фон для отправки e-mail',
        logo_email: 'Лого e-mail',
        custom_link_title: 'Ссылка на изображение',
        upload_hint:
          'Допустимые форматы: png, jpeg, jpg, svg. Максимальный размер - 6 Мб.',
        registration_title: 'Регистрация',
        registration_partner_title: 'Партнер',
        registration_partner_placeholder: 'Введите ID | xxhash | title',
        registration_partner_hint:
          'Выберите партнера для изменения настроек регистрации',
        registration_allowed: 'Регистрация пользователей разрешена.',
        registration_prohibited: 'Регистрация пользователей запрещена.',
        registration_changed: 'Изменить настройку можно',
        registrations_changed_link: 'в разделе Партнеры',
        registration_form: 'Форма регистрации',
        registration_rules: 'Доступные роли пользователей',
        documents_title: 'Документы',
        offerCustomLinkTitle: 'Ссылка на документ',
        offer_label: 'Оферта',
        offer_hint: 'Допустимые форматы: pdf.',
      },
    },
    history_changes_widget: {
      no_changes: 'Изменений нет',
      look_all_changes: 'Смотреть все изменения',
    },
    history_changes_page: {
      title: 'История изменений',
      header_date: 'Дата и время',
      header_who: 'Кто изменил',
      header_campaign: 'Кампания',
      header_creative: 'Креатив',
      header_parametr: 'Параметр изменения',
      header_changes: 'Что изменилось',
      hide_detail: 'Скрыть изменения',
      show_detail: 'Показать изменения',
      full_view: 'Полный вид',
      compact_view: 'Компактный вид',
      rows_limit: 'Лимит строк',
      all_objects: 'Все объекты',
      all_actions: 'Все действия',
      add: 'Добавление',
      edit: 'Редактирование',
      copy: 'Копирование',
      targets: 'Таргетинги',
      campaign_start_date: 'Дата начала кампании',
      campaign_end_date: 'Дата окончания кампании',
      objects: 'Объекты',
      inter_id_hash: 'Внутренний id | xxhash | Название',
    },
    analysis_page: {
      title: 'Анализ креативов',
      filters_title: 'Фильтры',
      placeholper_partner: 'xxhash | title',
      select_list: 'Выбрать из списка',
      help_au: 'Только одни цифры, больше одного символа в строке',
      help_dir:
        'Формат значений - цифры, символы, английские буквы. Больше 1 символа в строке, например: BTW-34774-605',
      placeholder_textarea: 'Введите названия элементов таргетинга через ENTER',
      search: 'Найти',
      header_table_nameCamp: 'название кампании',
      header_table_idCamp: 'ID РК',
      header_table_nameCreo: 'Название креатива',
      header_table_idCreo: 'ID креатива',
      au: 'Аудитория',
      dir: 'Прямая сделка',
      add_list: 'Добавить список',
      selected: 'Выбрано',
      of: 'из',
      invalid_input_1:
        'Некорректный ввод. Проверьте, чтобы значения были указаны через ENTER',
      invalid_input_2:
        "Некорректный ввод. Для таргета 'аудитории' используйте только цифры. Проверьте, чтобы значения были указаны через ENTER",
      au_shop: 'Аудитории магазинов',
    },
    search_none: {
      none_1: 'По данному запросу ничего не найдено',
      none_2:
        'Возможно, что в запросе одно или несколько слов написаны неправильно.',
      none_3: 'Попробуйте задать другой запрос.',
    },
    filter_none: {
      none_1: 'Нет ни одной кампании соответствующей заданным параметрам.',
      none_2: 'Создайте компанию или измените фильтры.',
    },
    expand_role: {
      ex1: 'Уменьшить шрифт',
      ex2: 'Увеличить шрифт',
      ex3: 'Свернуть',
      ex4: 'Копировать',
    },
    date_pick: {
      days30: 'За 30 дней',
      month: 'За текущий месяц',
      week: 'За неделю',
      day: 'Сегодня',
      yesterday: 'Вчера',
      all_time: 'За все время',
      select: 'Выбор периода',
    },
    filter: {
      customize: 'Настроить фильтры',
      and: 'И',
      date__start: 'Дата начала',
      date__end: 'Дата окончания',
      format__date: 'ДД.ММ.ГГ',
    },
    profile: {
      profile_word: 'Профиль',
      balance: 'Баланс',
      balance_refill: 'ВВЕДИТЕ СУММУ, ₽',
      refill: 'Пополнить',
      top_up_error:
        'Произошла ошибка, средства не зачислены.\nПопробуйте пополнить баланс позднее.',
      top_up_success: 'Баланс успешно пополнен',
      partner_managed: 'управляется агентством',
      ava_funds: 'Доступные средства',
      settings_tit: 'Настройки',
      main: 'Основные',
      app_notification_on: 'Получать информационные оповещения',
      app_notification_off: 'Не получать информационные оповещения',
      push: {
        title: 'Push-уведомления',
        toggle_active: 'Получать Push-уведомления',
        toggle_inactive: 'Не получать Push-уведомления',
        about: 'О чем сообщать',
        bw_lists: 'Загрузка BWLists',
        new_message: 'Новые сообщения',
        refill: 'Пополнение счета',
        end_campaign: 'Завершение кампании',
        check_all: 'Отметить все',
        clear: 'Очистить',
      },
      edit: {
        edit_save: 'Профиль успешно сохранен',
      },
      view: {
        title: 'Внешний вид',
        theme_subtitle: 'Представление таблиц по умолчанию',
        noraml_view: 'Обычный вид',
        extended_view: 'Расширенный вид',
        super_extended_view: 'Полноэкранный вид',
        toggle_animate: 'Анимация',
        animate_info:
          'Отключите анимацию в интерфейсе, чтобы ускорить процесс работы с элементами управления',
        hide_navigation_campaign: 'Скрыть навигацию в кампании',
        hide_navigation_creative: 'Скрыть навигацию в креативах',
        navigation_info_comp:
          'Отключите навигацию в интерфейсе, если не нужна возможность перемещаться между кампаниями',
        navigation_info_creo:
          'Отключите навигацию в интерфейсе, если не нужна возможность перемещаться между креативами',
        show_prompt_in_form: 'Показывать подсказки в формах',
        show_prompt_in_form_info:
          'Не забывайте заполнять важные поля при создаии или редактировании кампаний и креативов',
        hide_hints: 'Скрыть подсказки',
        hide_hints_add:
          'Подсказки, ведущие в документацию Trading Desk к настройкам кампании, креативов, таргетинга и др.',
      },
      safety: {
        title: 'Безопасность',
        subtitle: 'Смена пароля',
        old_password: 'Введите текущий пароль',
        new_password: 'Введите новый пароль',
        confirm_password: 'Повторите пароль',
      },
    },
    alerts: {
      a1: 'Код ifrаme скопирован в буфер обмена',
      a2: 'HTML код скопирован в буфер обмена',
      a3: 'успешно скопировано в буфер обмена',
      a4: 'Код preview успешно скопирован',
      copied: 'Скопировано',
      view_mode: 'Вы находитесь в режиме просмотра.',
      view_mode_cont: ' У вас есть ограничение на редактирование страниц',
    },
    targetings: {
      saveblock: {
        hint1: 'Блок ',
        hint2: ' успешно сохранен',
        hint3: 'Блок успешно сохранен',
      },
      hide: 'Скрыть',
      uncover: 'Раскрыть',
      show_statictics: 'Показать статистику',
      show: 'Показать · ',
      all: 'Все',
      total: 'Всего',
      custom: 'Настроенные',
      search: 'Поиск',
      use_set: 'Используются настройки таргетинга ',
      use_set1: 'Кампании',
      use_set2: 'креатива',
      use_set_creo:
        'Использовать собственные настройки таргетинга для креатива',
      use_set_modal_creo:
        'Использовать настройки таргетинга кампании после очистки таргета',
      stat_not_going: 'Для данного таргета статистика не собирается',
      reset_target_settings:
        'Вы уверены, что хотите очистить настройки таргетинга в кампании',
      stat_unavaiable: 'Статистика по таргету недоступна',
      add_locations: 'Добавить локации',
      add_list: 'Добавить список',
      update_guide: 'Обновить справочник',
      down_stat: 'Скачать статистику',
      update_stat: 'Обновить статистику',
      update_au: 'Обновить аудитории',
      have_camp_set_tit: 'У элементов установлены настройки кампании',
      have_camp_set:
        'Включите “Использовать собственные настройки таргетинга в креативе” для возможности редактировать элементы. Если хотите использовать в последующем настройки кампании снова, выключите свои настройки',
      no_list: {
        general: 'Вы еще не добавили элементы справочника',
        city: 'У вас нет локаций в списке. Нажмите чтобы добавить',
      },
      nocontext:
        'У вас нет контекстов интернет—страниц в списке. Нажмите, чтобы добавить',
      noselected: 'Показывать на выбранных элементах',
      selected: 'Показывать везде, кроме выбранных элементов',
      noselectedprompt:
        'Выберите элементы таргета, если хотите на них ротировать',
      selectedprompt:
        'Используйте, если хотите исключить ротацию на элементах. Достаточно выбрать их в списке',
      no_data: 'Нет данных за указанный период',
      statistic_off: 'Статистика отключена',
      no_statistic: 'Для данного таргета статистика не собирается',
      error_statistics: 'Произошла ошибка загрузки данных',
      add_elements: 'Добавить элементы',
      clear_target: 'Очистить таргет',
      list_copy: 'Список скопирован в буфер обмена',
      text_copy: 'Текст скопирован в буфер обмена',
      there_are_changes: 'Были внесены изменения',
      table: {
        name: 'Название',
      },
      copy_modal: {
        campaign: 'Копирование настроек таргетинга',
        creo: 'Копирование таргетинга креатива',
      },
      prompt: {
        items: {
          source: {
            text: 'Таргетинг “Источники” обязателен к заполнению.\n\nНеобходимо включить источник и обязательно заполнить поле BID - стоимость CPC/CMP/CPA.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B8%D1%81%D1%82%D0%BE%D1%87%D0%BD%D0%B8%D0%BA%D0%B8',
          },
          geo_id: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D1%8B-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0',
          },
          city: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B3%D0%B5%D0%BE-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D1%8B-%D0%B8-%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D0%B0',
          },
          site_domain: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B4%D0%BE%D0%BC%D0%B5%D0%BD%D1%8B',
          },
          site_uri: {
            link: 'https://terratraf.readme.io/docs/td-target-types#url',
          },
          au: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8',
          },
          os: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%8B',
          },
          browser: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D1%8B',
          },
          browser_lang: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%8F%D0%B7%D1%8B%D0%BA-%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D1%84%D0%B5%D0%B9%D1%81%D0%B0-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B0',
          },
          isp: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%BE%D0%B2%D0%B0%D0%B9%D0%B4%D0%B5%D1%80%D1%8B',
          },
          app: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F',
          },
          private_deals: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D1%80%D1%8F%D0%BC%D1%8B%D0%B5-%D1%81%D0%B4%D0%B5%D0%BB%D0%BA%D0%B8',
          },
          viewability: {
            link: 'https://terratraf.readme.io/docs/td-target-types#viewability',
          },
          au_bin_8: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-aidata-8',
          },
          au_bin_15: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-uid-20-15',
          },
          au_bin_20: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0-20',
          },
          au_bin_21: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%BE%D0%BA-%D0%BF%D0%BE-%D1%81%D1%81%D0%BF-uid-21',
          },
          uniq_show_creative: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E',
          },
          uniq_show_creative_hour: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D1%87%D0%B0%D1%81',
          },
          uniq_show_creative_day: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D0%B4%D0%B5%D0%BD%D1%8C',
          },
          days_of_week: {
            text: 'Использовать можно только один из двух предложенных таргетов по дням недели.\n\nТаргеты отличаются выбором часового пояса.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B4%D0%BD%D1%8F%D0%BC-%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D0%B8-%C2%B7-utc3',
          },
          days_of_week_user: {
            text: 'Использовать можно только один из двух предложенных таргетов по дням недели.\n\nТаргеты отличаются выбором часового пояса.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B4%D0%BD%D1%8F%D0%BC-%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D0%B8-%C2%B7-utc-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F',
          },
          hours_of_day: {
            text: 'Использовать можно только один из двух предложенных таргетов по времени дня.\n\nТаргеты отличаются выбором часового пояса.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8-%D0%B4%D0%BD%D1%8F-%C2%B7-utc3',
          },
          hours_of_day_user: {
            text: 'Использовать можно только один из двух предложенных таргетов по времени дня.\n\nТаргеты отличаются выбором часового пояса.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8-%D0%B4%D0%BD%D1%8F-%C2%B7-utc-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F',
          },
          uniq_show_camp_hour: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D1%87%D0%B0%D1%81',
          },
          uniq_show_camp_day: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D0%B4%D0%B5%D0%BD%D1%8C',
          },
          uniq_show_camp: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E',
          },
          adgroup: {
            link: 'https://terratraf.readme.io/docs/td-target-types#adgroup-%C2%B7-%D0%BF%D1%80%D0%B5%D1%81%D0%B5%D1%82%D1%8B-google',
          },
          page_context: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%BA%D1%81%D1%82-%D1%81%D0%B0%D0%B9%D1%82%D1%8B',
          },
          keywords: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%B2%D1%8B%D0%BC-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0%D0%BC-%D0%B8-%D1%84%D1%80%D0%B0%D0%B7%D0%B0%D0%BC',
          },
          actions: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%81%D1%82%D0%B0%D1%82%D0%B8%D1%81%D1%82%D0%B8%D0%BA%D0%B0-%D0%BF%D0%BE-%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D1%8F%D0%BC-%D0%B2-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B5',
          },
          video_type: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D1%83-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE-%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%8B',
          },
          video_size: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%80%D1%8B-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE',
          },
          socdem: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%81%D0%BE%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE-%D0%B4%D0%B5%D0%BC%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%B8%D0%BD%D0%B3',
          },
          sex: {
            text: 'Выберите, кому показывать объявления.\n\nЧтобы охватить пользователей, чей пол мы не знаем, выберите и мужчин, и женщин.',
          },
          age: {
            text: 'Возможно выбрать возраст только из представленных вариантов, диапазон возрастов 5 лет.',
          },
        },
      },
      add_popap_tit: {
        city: 'Добавить локации',
        geo_id: 'Добавить список',
        keywords: 'Добавить набор',
        os: 'Добавить операционные системы',
        browser: 'Добавить браузеры',
        browser_lang: 'Добавить языки',
        isp: 'Добавить провайдера',
        au_bin_8: 'Добавить аудитории',
        au_bin_15: 'Добавить аудитории',
        au_bin_20: 'Добавить аудитории',
        au_bin_21: 'Добавить аудитории',
        app: 'Добавить приложения',
        private_deals: 'Добавить сделки',
        viewability: 'Добавить viewability',
        uniq_show_creative: 'Добавить номер',
        hour: 'Добавить по времени дня · UTC +3',
        hour2: 'Добавить по времени дня · UTC пользователя',
        site_domain: 'Добавить домены',
        site_uri: 'Добавить URL',
        page_context: 'Добавить набор',
        video_type: 'Добавить форматы',
        uniq_show_camp_hour: 'Добавить номер показа кампании в час',
        uniq_show_camp_day: 'Добавить номер показа кампании в день',
        au_bin_info: 'Добавить аудитории',
        au_bin_lada: 'Добавить аудитории',
        general: 'Добавить элементы',
        add_popap_smalltit: {
          site_domain: 'Домены',
          au: 'Аудитории',
          private_deals: 'Прямые сделки',
          app: 'Приложения',
          isp: 'Интернет—провайдеры',
          site_uri: 'URL',
          browser_lang: 'Язык интерфейса браузера',
          adgroup: 'AdGroup Пресеты Google',
          general: 'Добавить элементы',
        },
        add_popap_prompt: {
          title: {
            au: 'Только цифры, больше одного символа в строке',
            private_deals:
              'Формат значений - цифры, символы, английские буквы. Больше 1 символа в строке,  например: BTW-34774-605',
            app: 'Полное совпадение',
            browser_lang:
              'Только английские буквы нижнего регистра, не больше 5 символов в строке.',
            adgroup:
              'Только английские буквы нижнего регистра и цифры, больше 1 символа в строке',
            geo_raw: {
              partOne: 'Сначала код страны и двоеточие (',
              partTwo:
                '), затем латинскими буквами - город, каждый с новой строки. Регистр букв городов не имеет значения, сверьтесь ',
              linkOne: 'Справочник',
              linkTwo: 'со списком городов',
            },
            city: {
              parOne:
                'Можно добавить сразу несколько географических регионов в список. Добавьте названия гео на русском языке, используя перенос строки. Со списоком названий регионов и городов можно ознакомиться в ',
              partTwo: ' справочнике.',
              errorPartOne: 'Следующие гео не будут добавлены: ',
              errorPartTwo:
                'Названия городов должны полностью соответствовать названиям в ',
              errorPartThree: '',
              error_dubl: 'В справочнике имеются дубликаты названий: ',
            },
            geo_id: {
              parOne:
                'Можно добавить сразу несколько географических регионов в список. Добавьте названия гео на русском языке, используя перенос строки. Со списоком названий регионов и городов можно ознакомиться в ',
              partTwo: ' справочнике.',
              errorPartOne: 'Следующие гео не будут добавлены: ',
              errorPartTwo:
                'Названия городов должны полностью соответствовать названиям в ',
              errorPartThree: '',
              error_dubl: 'В справочнике имеются дубликаты названий: ',
              sever_match:
                'Можно добавить сразу несколько стран, регионов и городов в список. Добавьте названия гео, используя перенос строки. Со списком названий регионов и городов можно ознакомиться в ',
            },
            general: 'OTHER',
            all_app: 'ALL APP',
          },
          text: {
            private_deals: 'Например: ABC—12345—123',
            browser_lang: 'Например:  kz, by.',
          },
        },
      },
      geo_id: {
        prompt:
          'Выберите регионы, в которых вы бы хотели показывать рекламу. Обратите внимание, выбрав регион, вы выбираете все входящие в него населенные пункты.',
        quickChoice: 'Быстрый выбор',
        search: 'Введите названия города или региона...',
        without: 'кроме',
        selected: 'Выбраны локации:',
        showStatistics: 'Показывать статистику',
        hideStatistics: 'Скрыть статистику',
        no_statistics: 'Статистика отсутствует',
        matches: 'Совпадения',
        matches4: 'совпадения',
        matches5: 'совпадений',
        added_matches:
          'Были найдены совпадения в запросе. Выберите нужные гео и продолжите добавление списка.',
      },
      categories: {
        prompt:
          'Выберите интересы из предложенных вариантов. Обратите внимание, выбрав родительский сегмент, вы выбираете все входящие в него категории.',
        search: 'Введите название категории',
        selected: 'Выбраны интересы:',
      },
      city: {
        t1: 'Показывать на выбранных локациях',
        t2: 'Показывать везде, кроме выбранных локаций',
        t3: 'Используйте, если хотите ротировать на список городов: например, Москва и Московская область. Важно: Указывайте города страны, указанной в таргетинге ГЕО Страны',
        t4: 'Перечислите города, на которые ротация не должна осуществляться. Не забудьте указать страну, на которую должна идти ротация в таргетинге ГЕО: Страны',
        t5: 'Добавить локации',
      },
      geo: {
        t1: 'Показывать в выбранных странах',
        t2: 'Показывать везде, кроме выбранных стран',
        t3: 'Используйте, если хотите ротировать в строго выбранных локациях. \n Важно: Таргет обязателен к заполнению. Включайте страны, к которым относятся интересующие Вас города.',
        t4: 'Используйте, если не хотите ротировать в выбранных локациях. \n Важно: если список стран будет пустым, то показы будут по всему миру.',
      },
      geo_raw: {
        t1: 'Показывать на выбранных локациях',
        t2: 'Показывать везде, кроме выбранных локаций',
        t3: 'Используйте, если хотите ротировать на список городов: например, Москва и Московская область. Важно: Указывайте города страны, указанной в таргетинге ГЕО Страны',
        t4: 'Перечислите города, на которые ротация не должна осуществляться. Не забудьте указать страну, на которую должна идти ротация в таргетинге ГЕО: Страны',
        t5: 'Добавить локации',
      },
      keywords: {
        t1: 'Показывать по словам и ключевым фразам',
        t2: 'Не показывать по словам и ключевым фразам',
        t3: 'Добавьте один или несколько наборов с перечисленными словами и ключевыми фразами, по которым должны идти показы. Система будет искать полное совпадение слов и всей фразы целиком',
        t4: 'Перечислите запрещенные слова и ключевые фразы в наборе, для которых не должно быть показов. При полном совпадении слов и фраз показа креатива не будет',
        t5: 'Добавить набор',
        t8: 'Изменить набор',
        t9: 'Удалить набор',
        t10: 'По ключевым словам и фразам',
        t11: 'Введите слова или ключевые фразы через запятую...',
        t12: 'Список слов и фраз',
        t13: 'Посмотреть набор слов и фраз',
        t14: 'Изменить набор слов и фраз',
        t15: 'Копировать список',
        t16: 'Название',
      },
      os: {
        t1: 'Показывать на выбранных ОС',
        t2: 'Показывать везде, кроме выбранных ОС',
        t3: 'Из предлагаемого списка всевозможных ОС выберете только те, на которые рассчитана данная РК. И показы будут у пользователей выбранных ОС',
        t4: 'Используйте, если хотите исключить показы на установленных операционных системах пользователя. Достаточно выбрать их в списке',
        t5: 'Добавить операционные системы',
        t6: 'Вы еще не добавили элементы справочника',
        t7: 'Вы еще не добавили элементы справочника',
      },
      browser: {
        t1: 'Показывать на выбранных браузерах',
        t2: 'Показывать везде, кроме выбранных браузеров',
        t3: 'Из предлагаемого списка браузеров выберете только те, на которые рассчитана данная РК. И показы будут у пользователей выбранных браузеров',
        t4: 'Используйте, если хотите исключить показы на определенных браузерах пользователя. Достаточно выбрать их в списке',
        t5: 'Добавить браузеры',
      },
      browser_lang: {
        t1: 'Показывать на выбранных языках',
        t2: 'Показывать везде, кроме выбранных языков',
        t3: 'Показ пойдет только на тех пользователей, язык интерфейса браузера которых соответствует одному из выбранных Вами пунктов в списке таргета',
        t4: 'Показ пойдет на всех пользователей кроме тех, язык интерфейса браузера которых выбран Вами в списке таргета',
        t5: 'Добавить языки',
      },
      isp: {
        t1: 'Показывать на выбранных провайдерах',
        t2: 'Показывать везде, кроме выбранных провайдеров',
        t3: 'Показ пойдет только на тех пользователей, которые пользуются услугами Интернет-провайдеров, выбранных в списке таргета',
        t4: 'Показ пойдет всем пользователям, за исключением тех, которые пользуются услугами Интернет-провайдеров, выбранных в списке таргета',
        t5: 'Добавить провайдера',
      },
      au_bin_8: {
        t1: 'Показывать на выбранных аудиториях',
        t2: 'Показывать везде, кроме выбранных аудиторий',
        t3: 'Используйте, если для показа рекламной кампании нужны аудитории “Aidata” из списка на добавление. Показ пойдет только на те аудитории, которые выбраны в списке таргета',
        t4: 'Показ пойдет на всех пользователей клиента, за исключением тех, которые выберете в списке таргета',
        t5: 'Добавить аудитории',
      },
      au_bin_15: {
        t1: 'Показывать на выбранных аудиториях',
        t2: 'Показывать везде, кроме выбранных аудиторий',
        t3: 'Используйте, если для показа рекламной кампании нужны аудитории “UID 2.0” из списка на добавление. Показ пойдет только на те аудитории, которые выбраны в списке таргета',
        t4: 'Показ пойдет на всех пользователей клиента, за исключением тех, которые выберете в списке таргета',
        t5: 'Добавить аудитории',
      },
      au_bin_20: {
        t1: 'Показывать на выбранных аудиториях',
        t2: 'Показывать везде, кроме выбранных аудиторий',
        t3: 'Используйте, если для показа рекламной кампании нужны аудитории пользователей клиента из списка на добавление. Показ пойдет только на те аудитории, которые выбраны в списке таргета',
        t4: 'Показ пойдет на всех пользователей клиента, за исключением тех, которые выберете в списке таргета',
        t5: 'Добавить аудитории',
      },
      au_bin_21: {
        t1: 'Показывать на выбранных аудиториях',
        t2: 'Показывать везде, кроме выбранных аудиторий',
        t3: 'Используйте, если хотите показывать рекламу пользователям, состоящим в определенных группах. Добавьте ID аудиторий и показы будут только на них',
        t4: 'Показ пойдет на всех пользователей, за исключением тех аудиторий, которые Вы добавите в списке',
        t5: 'Добавить аудитории',
      },
      app: {
        t1: 'Показывать в выбранных приложениях',
        t2: 'Показывать везде, кроме выбранных приложений',
        t3: 'Используйте, если хотите задать список приложений, в которых будет показана реклама. Добавьте в список известные Вам ID приложений',
        t4: 'Выберите, если хотите показывать целевую рекламу во всех приложениях, за исключением тех ID приложений, что добавите в список',
        t5: 'Добавить приложения',
      },
      private_deals: {
        t1: 'Показывать на определенных сделках',
        t2: 'Показывать везде, кроме выбранных сделок',
        t3: 'Используйте, если у Вас есть ID сделки. Ротация будет по заранее согласованным параметрам (цена, формат и т. д.)',
        t4: 'Используйте, если хотите выкупать все, кроме конкретных мест размещения у издателей на основе прямых с ним договоренностей',
        t5: 'Добавить сделки',
      },
      viewability: {
        t1: 'Показывать на определенной видимости',
        t2: 'Показывать везде, кроме определенной видимости',
        t3: 'Используйте, если хотите задать процент видимости блоков, который SSP будет использовать при ротации РК',
        t4: 'Выберите, если хотите засчитывать показы тогда, когда видимость блока не совпадает ни с одним из выбранных в списке таргета процентов видимости',
        t5: 'Добавить viewability',
      },
      uniq_show_creative: {
        t1: 'Показывать по определенному номеру',
        t2: 'Показывать везде, кроме определенного номера',
        t3: 'Креатив будет показан пользователю не более выбранного в таргете числа раз',
        t4: 'Показ пойдет всем пользователям, но не будут показаны выбранные в таргете номера показов',
        t5: '',
      },
      uniq_show_creative_hour: {
        t1: 'Показывать по определенному номеру',
        t2: 'Показывать везде, кроме определенного номера',
        t3: 'Креатив будет показан пользователю не чаще выбранного в таргете числа раз в час',
        t4: 'Показ пойдет всем пользователям, но в течение каждого часа не будут показаны выбранные в таргете номера показов',
        t5: '',
      },
      uniq_show_creative_day: {
        t1: 'Показывать по определенному номеру',
        t2: 'Показывать везде, кроме определенного номера',
        t3: 'Креатив будет показан пользователю не чаще выбранного в таргете числа раз в день',
        t4: 'Показ пойдет всем пользователям, но каждый день не будут показаны выбранные в таргете номера показов',
        t5: '',
      },
      days_of_week: {
        t1: 'Показывать по определенным дням',
        t2: 'Показывать везде, кроме определенных дней',
        t3: 'Показ пойдет только, если день недели по часовому поясу UTC+3 соответствует выбранным Вами пунктам в списке таргета',
        t4: 'Показ пойдет только, если текущий день недели по часовому поясу UTC+3 не совпадает ни с одним из указанных в списке таргета',
        t5: '',
      },
      days_of_week_user: {
        t1: 'Показывать по определенным дням',
        t2: 'Показывать везде, кроме определенных дней',
        t3: 'Показ пойдет только, если день недели по часовому поясу пользователя соответствует выбранным Вами пунктам в списке таргета',
        t4: 'Показ пойдет только, если текущий день недели по часовому поясу пользователя не совпадает ни с одним из указанных в списке таргета',
        t5: '',
      },
      hours_of_day: {
        t1: 'Показывать на определенном времени',
        t2: 'Показывать везде, кроме определенного времени',
        t3: 'Используйте, если хотите настроить ротацию для разных временных периодов дня по часовому поясу UTC+3',
        t4: 'Используйте, если хотите запретить ротацию на все временные периоды дня по часовому поясу UTC+3, кроме добавленных Вами в списке',
        t5: 'Добавить по времени дня · UTC +3',
      },
      hours_of_day_user: {
        t1: 'Показывать на определенном времени',
        t2: 'Показывать везде, кроме определенного времени',
        t3: 'Показ пойдет только, если время по часовому поясу, в котором находится пользователь, соответствует выбранным Вами пунктам в списке таргета',
        t4: 'Показ пойдет только, если текущее время по часовому поясу, в котором находится пользователь, не совпадает ни с одним из указанных в списке таргета',
        t5: 'Добавить по времени дня · UTC пользователя',
      },
      site_domain: {
        t1: 'Показывать на выбранных доменах',
        t2: 'Показывать везде, кроме выбранных доменов',
        t3: 'Используйте, если хотите ротировать на определенные домены, которые Вы добавите в списке',
        t4: 'Используйте, если хотите исключить ротацию на конкретных доменах. Показ пойдет на всевозможные домены, кроме выбранных в списке таргета',
        t5: 'Добавить домены',
      },
      site_uri: {
        t1: 'Показывать на выбранных URL',
        t2: 'Показывать везде, кроме выбранных URL',
        t3: 'Используйте, если хотите ротировать рекламную кампанию на определенные домены или на все URL, содержащие полные ссылки на площадки. Добавьте их в списке',
        t4: 'Используйте, если хотите запретить ротацию на список URL-ов, которые Вы добавите',
        t5: 'Добавить URL',
      },
      adgroup: {
        t1: 'Показывать на выбранных пресетах',
        t2: 'Показывать везде, кроме выбранных пресетов',
        t3: 'Показ пойдет только на тех пользователей, которые соответствуют пресетам Google, выбранным в списке таргета',
        t4: 'Показ пойдет на всех пользователей кроме тех, которые попадают под выбранные Вами пресеты в списке таргета',
      },
      page_context: {
        t1: 'Показывать на выбранных контекстах',
        t2: 'Показывать везде, кроме выбранных контекстов',
        t3: 'Показ пойдет только на на тех площадках, контекстное содержание которых соответствует одному из выбранных Вами наборов слов в списке таргета',
        t4: 'Показ пойдет на всех площадках кроме тех, контекстное содержание которых соответствует выбранным наборам слов в списке таргета. Позволяет исключить нежелательное содержание',
        t5: 'Добавить набор',
      },
      socdem: {
        t1: 'Показывать на выбранных настройках',
        t2: 'Показывать везде, кроме выбранных настроек',
        t3: 'Используйте, если хотите показывать рекламу пользователям по параметрам их пола, возраста, дохода. Выберите настройки из списка',
        t4: 'Используйте, если показывать рекламу нужно всем пользователям, кроме не подходящих по возрасту или полу. Достаточно добавить настройки из списка и ротации не будет для определенной группы пользователей',
      },
      video_type: {
        t1: 'Показывать на выбранных форматах',
        t2: 'Показывать везде, кроме выбранных форматов',
        t3: 'Для видеорекламы выберите из списка доступные форматы, которые подойдут для продвижения Вашего видеоконтента.',
        t4: 'Используйте, если хотите исключить показы на определенных форматах видеорекламы. Достаточно выбрать их в списке.',
        t5: 'Добавить форматы',
      },
      uniq_show_camp_hour: {
        t1: 'Показывать по определенному номеру',
        t2: 'Показывать везде, кроме определенного номера',
        t3: 'Креативы кампании будут показаны пользователю не чаще выбранного в таргете числа раз в час',
        t4: 'Показ пойдет всем пользователям, но в течение каждого часа не будут показаны выбранные в таргете номера показов',
        t5: 'Добавить номер показа кампании в час',
      },
      uniq_show_camp_day: {
        t1: 'Показывать по определенному номеру',
        t2: 'Показывать везде, кроме определенного номера',
        t3: 'Креативы кампании будут показаны пользователю не чаще выбранного в таргете числа раз в день',
        t4: 'Показ пойдет всем пользователям, но каждый день не будут показаны выбранные в таргете номера показов',
        t5: 'Добавить номер показа кампании в день',
      },
      uniq_show_camp: {
        t1: 'Показывать по определенному номеру',
        t2: 'Показывать везде, кроме определенного номера',
        t3: 'Креативы кампании будут показаны пользователю не более выбранного в таргете числа раз',
        t4: 'Показ пойдет всем пользователям, но не будут показаны выбранные в таргете номера показов',
        t5: '',
      },
      au: {
        enter_au: 'Введите аудитории',
        add_several:
          'Можно добавить сразу несколько аудиторий в список через перенос строки. Добавьте номер аудитории, используя только цифры.',
        following_au: 'Следующие аудитории не будут добавлены:',
        au_numbers: 'Номера аудиторий не найдены в списке, обновите справочник',
      },
      au_bin_info: {
        t1: 'Показывать на выбранных аудиториях',
        t2: 'Показывать везде, кроме выбранных аудиторий',
        t3: 'Используйте, если хотите показывать рекламу пользователям, состоящим в определенных группах. Добавьте ID аудиторий и показы будут только на них',
        t4: 'Показ пойдет на всех пользователей, за исключением тех аудиторий, которые Вы добавите в списке',
        t5: 'Добавить аудитории',
      },
      au_bin_lada: {
        t1: 'Показывать на выбранных аудиториях',
        t2: 'Показывать везде, кроме выбранных аудиторий',
        t3: 'Используйте, если хотите показывать рекламу пользователям, состоящим в определенных группах. Добавьте ID аудиторий и показы будут только на них',
        t4: 'Показ пойдет на всех пользователей клиента, за исключением тех, которые выберете в списке таргета',
        t5: 'Добавить аудитории',
      },
      limits: {
        title: 'Лимиты',
        set_limits: 'Настроить лимиты',
        del_limits: 'Удалить лимиты',
        del_set: 'Удаление настроек',
        del_set_limit: 'Удалить настройки лимитов',
      },
    },
    creative: {
      screenshot: {
        hint: 'При создании нового скриншота, старый скриншот заменяется на новый',
        screen: 'Скриншот',
        refresh: 'Обновить статус',
        create: 'Создать скриншот',
        pending: 'Ожидает обработки',
        sended: 'Отправлено на обработку',
        processing: 'Обработка',
        error: 'Ошибка создания',
        wait: 'Процесс займет немного времени',
        download: 'Скачать',
      },
      moderation: {
        statuses: {
          APPROVED: 'Подтвержден',
          DISAPPROVED: 'Отклонен',
          ON_MODERATION: 'На модерации',
          UNKNOWN: 'Неизвестно',
          READY: 'Готов к модерации',
          NOT_SENT: 'Не отправлен на модерацию',
          AWAITING_DATA_COMPLETION: 'Не готов к модерации',
          CONDITIONALLY_APPROVED: 'Креатив разрешен везде, кроме России',
        },
        modal: {
          google: {
            title: 'Модерация Google',
            description:
              'Свяжитесь со службой поддержки Google, чтобы разобраться с проблемой модерации креатива. Для успешного решения вопроса вам потребуется номер ID размещенного объявления:',
            actionBtn: 'Перейти к форме запроса',
          },
          yandex: {
            title: 'Модерация Yandex',
            description:
              'Свяжитесь со службой поддержки Yandex, чтобы разобраться с проблемой модерации креатива. Для успешного решения вопроса вам потребуется номер ID размещенного объявления, который необходимо указать в письме с описанием проблемы. Адрес для запросов: ',
          },
          labelInput: 'ID креатива',
          labelBtn: 'Скопировать',
          needHelp: 'Нужна помощь?',
          collapse: 'Свернуть',
          expand: 'Развернуть',
          variants: {
            variant1: 'Вариант 1',
            variant2: 'Вариант 2',
          },
        },
      },
      constructor: {
        create: 'Создать в конструкторе',
        business_area: 'Сфера бизнеса',
        upload_logo: 'Загрузить логотип',
        select_style: 'Выбрать стиль',
        upload_your_logo: 'Загрузите логотип',
        formats: '(PNG, JPEG, JPG до 2 Mb)',
        add_text: 'Добавьте текст',
        text_limit: '(до 80 знаков)',
        text_of_btn: 'Текст кнопки',
        name: 'Название',
        logo: 'Логотип',
        style: 'Стиль',
        illustrations: 'Иллюстрации',
        illustration: 'Иллюстрация',
        select_a_style: 'Выберите стиль оформления',
        select_illustration: 'Выберите иллюстрацию',
        write_text: 'Напишите текст',
      },
      create_menu: {
        title: 'Тип креатива',
        TEASER: 'Тизер',
        PUSH: 'Push',
        HTML: 'HTML',
        YANDEX: 'Yandex HTML',
        VIDEO_INLINE: 'Видео',
        VIDEO_VAST: 'Видео VAST',
        CLICKUNDER: 'Кликандер',
        MULTI_FORMAT: 'Мультиформат',
        COMPANION: 'COMPANION',
        HTML_VIDEO: 'Видео HTML',
      },
      crea_type: {
        html: 'Тип · HTML',
        push: 'Тип · PUSH',
        teaser: 'Тип · Тизер',
        yandex: 'Тип · Yandex HTML',
      },
      allstatrk: 'Общая статистика РК',
      push_text: {
        t1: 'Иконка',
        t2: 'Изображение',
        t3: 'Заголовок',
        t4: 'Текст',
        t5: 'Ссылка на иконку',
        t6: 'Допустимые форматы: png, jpeg, jpg, gif. Максимальный размер - 200 Mb.',
        t7: 'Минимальное разрешение 80х80 px.',
        t8: 'Максимальное — 4096×4096 px.',
        t9: 'Ссылка на изображение',
        t10: 'Минимальное разрешение 360х240 px. Максимальное — 4096×4096 px.',
        t11: 'Ресайз картинки в двух разрешениях 360×240 px, 492×328 px.',
        t12: 'Удалить',
      },
      yandex_html_text: {
        t1: 'ZIP–архив',
        t2: 'Картинка-заглушка',
        t3: 'Формат: ZIP-архив. Максимальный размер — 150 кб.',
        t4: 'Ссылка на ZIP–архив',
        t5: 'Требования к рекламным материалам',
        t6: '150 кб',
        t7: 'Альтернативный текст',
        t8: 'Яндекс бренд',
        t9: 'Яндекс категории',
        t10: 'Ссылка на картинку–заглушку',
        t11: 'Форматы: jpeg, jpg, png, gif, svg. Максимальный размер — 150 кб.',
        t12: 'Минимальное разрешение 80 × 80 px. Максимальное — 4096 х 4096 px.',
        t13: '',
        t14: '',
        t15: '',
      },
      widget_text: {
        html_code: 'HTML код баннера',
        size: 'Размеры креатива',
        link: 'Ссылка для перехода',
        adomain: 'Конечный домен',
        iab: 'ИАБ - категория',
        language: 'Язык',
        advertiser_name: 'Рекламодатель',
        zip_yandex_html: 'Ссылка на архив',
        img_yandex_html: 'Ссылка на заглушку',
        alt_text: 'Альтернативный текст',
        yandex_brand: 'Яндекс бренд',
        yandex_category: 'Яндекс категории',
        video_url: 'Ссылка на видеоролик',
        duration: 'Продолжительность',
        skip_duration: 'Продолж. пропуска',
        video_bitrate: 'Битрейт',
        vast_url: 'Ссылка на видеоролик (XML-файл)',
        title: 'Заголовок',
        text: 'Текст',
        icon: 'Иконка',
        image: 'Изображение',
        iconMask: 'Иконка',
        imgMask: 'Изображение',
        image_1: 'Изображение (600 х 600)',
        image_2: 'Изображение (1080 х 607)',
        ID_ERID: 'ID ERIR',
        check_ERID: 'Проверка ERID',
      },
      video: {
        video_input: {
          label: 'Видео (URL)',
          custom_link: 'Ссылка на видеоролик',
          hint1:
            'Допустимые форматы: MP4 (MPEG-4): кодеки видео H.264, кодек аудио - AAC.',
          hint2: 'Рекомендуемый размер до 10 Mb.',
        },
        tabs: {
          tab1: 'Основные настройки',
          tab2: 'Опциональные настройки',
        },
        fields: {
          video_bitrate: 'Битрейт, кбит/с',
          video_duration: 'Продолжительность, сек',
          video_duration_pass: 'Продолж. пропуска, сек',
          video_description: 'Описание',
          video_show: 'Show (URL трекера показа) ',
          video_show2: 'Show (Дополнительный URL трекера показа) ',
          video_click: 'Click (URL трекера клика) ',
          video_view_0: 'Start (URL трекера показа) ',
          video_view_25: 'FirstQuartile (URL трекера показа 25%) ',
          video_view_50: 'Midpoint (URL трекера показа 50%) ',
          video_view_75: 'ThirdQuartile (URL трекера показа 75%) ',
          video_view_100: 'Complete (URL трекера показа 100%) ',
          video_skip: 'Skip (URL трекера нажатия «Skip») ',
          video_mute:
            'Mute (URL трекера нажатия «Mute» или снижения громкости до 0) ',
          video_unmute: 'Unmute (URL трекера нажатия «Unmute») ',
          video_pause: 'Pause (URL трекера паузы) ',
          video_rewind:
            'Rewind (URL трекера нажатия «Rewind» или перехода по прогрессбару) ',
          video_resume: 'Resume (URL трекера нажатия «Resume») ',
          video_expand: 'Expand (URL трекера разворачивались плеера) ',
          video_collapse: 'Collapse (URL трекера уменьшения плеера) ',
          video_close: 'Close (URL трекера закрытия плеера) ',
          video_fullscreen:
            'Fullscreen (URL трекера перехода в полноэкранный режим) ',
          video_exitfs:
            'ExitFullscreen (URL трекера выхода из полноэкранного режима) ',
          video_sprogresshow: 'Progress (URL трекера прогресса просмотра)',
        },
        switch: {
          all: 'Все поля',
          quartiles: 'Только квартили',
          show: 'Только показ',
        },
      },
      vast: {
        create_hint: {
          title: 'Видео VAST',
          text: 'VAST (Video Ad Serving Template) - это спецификация, разработанная Interactive Advertising Bureau (IAB), которая определяет стандарт требований к передаче данных между рекламными серверами и видеопроигрывателями для показа видеорекламы.\nЭто структура данных с использованием XML.',
        },
        tabs: {
          tab0: 'Ссылка на видеоролик (XML-файл)',
          tab1: 'Основные настройки',
          tab2: 'Опциональные настройки',
        },
        fields: {
          video_url: 'Видео (URL)',
          video_duration: 'Продолжительность, сек',
          video_duration_pass: 'Продолжительность пропуска, сек',
        },
      },
      html: {
        no_tag1: 'В HTML-коде отсутствует тег',
        no_tag2: '',
        error:
          'При показе баннера на нем не будет отображаться токен, пожалуйста обратитесь в тех. поддержку',
        copy_code_preview: 'Скопировать код preview',
      },
      clickunder: {
        create_hint: {
          title: 'Кликандер',
          text: 'Кликандер (clickunder) – это разновидность pop-рекламы. Клик в любом месте страницы приведет к открытию сайта рекламодателя в новом окне браузера.',
        },
      },
      crea_statuses: {
        s1: 'Подтвержден',
        s2: 'Креатив на обработке',
        s3: 'Креатив разрешен везде, кроме России',
        s4: 'Неизвестно',
        s5: 'На модерации',
        s6: 'Отклонен',
        s7: 'Готов к модерации',
        s8: 'Не готов к модерации',
        s9: 'Креатив разрешен везде, кроме России',
        s10: 'Отправить на модерацию',
        s11: 'Cкопировать код iframe',
        s12: 'После изменения параметров необходимо сохранить блок "Тип · HTML" для предпросмотра актуальной версии креатива.',
        s13: 'После изменения параметров необходимо сохранить блок "Тип · PUSH" для предпросмотра актуальной версии креатива.',
        s14: 'В предпросмотре  выведена статическая картинка–заглушка.',
        s15: 'Предпросмотр недоступен. Не заполнены обязательные поля в блоке "Тип · HTML"',
        s16: 'Предпросмотр недоступен. Не заполнены обязательные поля в блоке "Тип · PUSH"',
        s17: 'Предпросмотр недоступен. Не заполнены обязательные поля в блоке "Тип · Yandex HTML"',
        s18: 'Предпросмотр недоступен. Не заполнены обязательные поля в блоке "Тип · ВИДЕО"',
        s19: 'Ожидает отправки',
        s20: 'Предпросмотр недоступен. Не заполнены обязательные поля в блоке "Тип · COMPANION"',
        s21: 'Предпросмотр недоступен. Не заполнены обязательные поля в блоке "Тип · ВИДЕО VAST"',
        NOT_SENT: 'Не отправлен на модерацию',
        MULTI_FORMAT:
          'После изменения параметров необходимо сохранить блок "Тип · Мультиформат" для предпросмотра актуальной версии креатива.',
        noAccessPreview:
          'Предпросмотр недоступен. Не заполнены обязательные поля в блоке "Тип · ',
      },
      mass_action: {
        modal_test_part1: 'Вы действительно хотите',
        modal_test_part2: 'креативы кампании',
        modal_test_part3: '',
        modal_test_part4: 'очистить все таргеты креатива кампании',
        successfully_text_part1: 'Креативы успешно',
        successfully_text_part2: '',
        successfully_text_dublicate: 'Креативы успешно продублированы',
        successfully_text_clear: 'Креативы успешно очищены',
      },
      js_code_audit: 'JS код аудит',
      js_code: 'JS код',
      click_audit_pixel: 'Пиксель аудита при клике',
      erir_hint_1: 'Не забудьте подумать про',
      erir_hint_2: 'ID',
      erir_hint_3: '',
      crea_size: 'Размер · ',
      cleartarg: 'Очистить все таргеты',
      clear_all_target:
        'Вы действительно хотите очистить все таргеты кампании ',
      stop_all_creatives:
        'Вы действительно хотите остановить все креативы кампании',
      start_all_creatives:
        'Вы действительно хотите запустить все креативы кампании',
      clear_done_targ: 'Вы действительно хотите очистить все таргеты креативов',
      fullname: 'Полное название',
      shortname: 'Сокращенное название',
      fullwidth: 'Во всю ширину',
      shortwidth: 'Компактный вид',
      tables_type: 'Тип',
      tables_name: 'Название',
      valid_vendor: 'Макрос {LINK_UNESC} не возможен в ссылке перехода',
      errs: {
        e1: 'Ошибка валидации данных. Файл не удовлетворяет требованиям загрузки',
      },
      copy_creative: {
        copy_set: {
          t1: 'Копировать настройки',
          t2: 'Выберите креативы, в которые необходимо скопировать настройки таргетов',
          t3: 'Копирование настроек',
          t4: 'Это может занять несколько секунд...',
          t5: 'Настройки таргетов креатива ',
          t5_2: 'Настройки таргетов и претаргетов креатива ',
          t6: ' cкопированы в креативы',
          t7: 'Скопировать с настройками претаргетинга',
        },
        text_creative:
          'Выберите кампании, в которые необходимо скопировать креатив',
        text: 'Выберите креативы, в которые необходимо скопировать настройки таргетов',
        errors: {
          tit: 'Ошибка копирования',
          text: 'Настройки будут скопированы не полностью, для креативов ниже не доступны некоторые параметры.',
          err1: 'Неизвестная ошибка копирования',
        },
        succes: {
          t1: 'Креатив ',
          t2: ' успешно скопирован',
          t3: ' в кампании ',
          t4: 'Креативы ',
          t5: ' успешно скопированы',
        },
        copying_tit: 'Копирование креатива',
        copying_text: 'Это может занять несколько секунд...',
      },
      dubl_creative: {
        t1: 'Дубликат креатива ',
        add_eng_prefix_one: '',
        add_eng_prefix_many: '',
      },
      del_crea: {
        t1: 'Креатив ',
        t2: ' удален',
      },
      cop: 'Копировать настройки',
      clea: 'Очистить все таргеты',
      none: {
        no1: 'У вас еще нет креативов.',
        no2: 'Нажмите, чтобы создать новый.',
        none_text: 'Вы можете создать креатив используя:',
        none_text_of: 'видео, изображения, html код.',
      },
      type: {
        type: 'Тип · ',
        teaser: 'Тизер',
        video: 'Видео',
        companion: 'Companion',
        video_vast: 'Видео VAST',
        clickunder: 'Кликандер',
      },
      header_menu: {
        back: 'Назад',
        settings: 'Настройки',
        targetings: 'Таргетинг',
        basic_settings: 'Основные настройки',
      },
      use_VPAID: 'Использовать VPAID',
      create_btn: 'Создать креатив',
      creative_was_created: 'Креатив создан',
      creative_was_edited: 'Последнее изменение',
      creative_requare: 'Обязательно для заполнения',
      creative_text: 'Креатив',
      search: 'Поиск...',
      Name: 'Название',
      Impressions: 'Показы',
      cpm: 'CPM',
      Clicks: 'Клики',
      cpc: 'CPC',
      ctr: 'CTR',
      Amount_spent: 'Расход',
      Moderation: 'Модерация',
      DocumentationGoogle: 'Документация Google',
      Status: 'Статус',
      stat_act: 'Активен',
      stat_pause: 'Остановлен',
      creative_prompt: {
        creative_text: {
          link: 'https://terratraf.readme.io/docs/td-creatives#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2',
        },
        type: {
          HTML: {
            link: 'https://terratraf.readme.io/docs/td-creatives-htmlbanner#%D0%B1%D0%BB%D0%BE%D0%BA-%D1%82%D0%B8%D0%BF-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%B4%D0%BB%D1%8F-html-%D0%B1%D0%B0%D0%BD%D0%BD%D0%B5%D1%80%D0%B0',
          },
          PUSH: {
            link: 'https://terratraf.readme.io/docs/td-creatives-push',
          },
          TEASER: {
            link: 'https://terratraf.readme.io/docs/td-creatives-teaser#%D0%B1%D0%BB%D0%BE%D0%BA-%D1%82%D0%B8%D0%BF-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%B4%D0%BB%D1%8F-%D1%82%D0%B8%D0%B7%D0%B5%D1%80%D0%B0',
          },
          VIDEO_INLINE: {
            link: 'https://terratraf.readme.io/docs/td-creatives-video#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D1%82%D0%B8%D0%BF%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE',
          },
          VIDEO_VAST: {
            link: 'https://terratraf.readme.io/docs/td-creatives-video-vast#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D1%82%D0%B8%D0%BF%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE-vast',
          },
          MULTI_FORMAT: {
            link: 'https://terratraf.readme.io/docs/td-creatives-multiformat',
          },
          COMPANION: {
            link: '',
          },
          CLICKUNDER: {
            link: 'https://terratraf.readme.io/docs/td-creatives-clickander',
          },
        },
        additional_settings: {
          link: 'https://terratraf.readme.io/docs/td-creatives#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B8',
        },
      },
      create_creative: {
        newTitle: 'Новый креатив',
        success: {
          t1: 'Креатив ',
          t2: ' успешно создан',
        },
        tizmacr: {
          m1: {
            t1: 'Макрос · campaign ID',
            t2: 'Макрос будет заменен идентификатором кампании, например:',
          },
          m2: {
            t1: 'Макрос · creative ID',
            t2: 'Макрос будет заменен идентификатором креатива, например:',
          },
          m3: {
            t1: 'Макрос · domain ID',
            t2: 'Макрос будет заменен идентификатором домена, например:',
          },
          m4: {
            t1: 'Макрос · click ID',
            t2: 'Макрос будет заменен идентификатором клика, например:',
          },
        },
        create_tit: 'Создание креатива',
        Advertiser_name: 'Рекламодатель',
        Please_fill:
          'Необходимо заполнить поле для успешной отправки креатива на модерацию Google',
        Name: 'Название *',
        Creative_name: 'Креатив',
        select_type: 'Выбрать тип...',
        symbols: ' символов',
        type_tit: 'Тип',
        type_tit_html: 'HTML',
        File: 'Файл ',
        Supported_format:
          'Поддерживаемый формат: zip архив. Максимальный размер — 6 Mb.',
        Max_size: ' ',
        Add_archive: 'Добавить архив',
        HTML_Code: 'HTML Код',
        Actions: 'Действия ',
        Convert_code: 'Преобразовать код',
        Add_macros: 'Добавить макросы',
        Size_px: 'Размер, px',
        Please_select: 'Выберите…',
        Set_sizes: 'Задать собственные размеры',
        Width: 'Ширина',
        Height: 'Высота',
        select_from_list: 'Выберите из списка',
        Additional_settings: 'Дополнительные настройки',
        URL_settings: 'Настройки URL',
        Navigation_link: 'Ссылка для перехода',
        Extension_link: 'Доп. ссылка для перехода',
        Link: 'Адрес ссылки',
        Domain: 'Конечный домен',
        Domain_address: 'Адрес домена',
        Optional_URL: 'Опциональные настройки',
        Pixel_audit: 'Пиксель аудита',
        Optional_pixel: 'Доп. пиксель аудита',
        Categories: 'Категории',
        CategoriesAndLanguage: 'Категории и язык',
        Language: 'Язык',
        optimization: {
          title: 'Алгоритм оптимизации',
          algorithm: 'Алгоритм',
          default: 'Не выбран',
          max_ctr: 'Оптимизация по ctr',
          min: 'Минимальное значение',
          max: 'Максимальное значение',
        },
        Creative_preview: 'Предпросмотр креатива',
        Preview: 'Предпросмотр',
        To_preview:
          'Для предварительного просмотра необходимо создать креатив, после этого кнопка станет активной.',
        not_available: 'Предпросмотр недоступен',
        went_wrong: 'Что-то пошло не так.',
        try_to_refresh: 'Попробуйте обновить страницу',
        This_format:
          'В данном формате поддерживается 3 варианта создания креатива:',
        Upload_zip: 'Загрузить zip архив',
        Import_html: 'Импортировать html код',
        animation: 'Анимация',
        type_jest: 'Тип жести',
        type_erotick: 'Тип эротики',
        ani_yes: 'Да',
        ani_no: 'Нет',

        ertype: {
          t1: 'Нет',
          t2: 'Лайт',
          t3: 'Средне',
          t4: 'Мегажесть',
          t5: 'Точно без секса',
          t6: 'Части тела',
          t7: 'Легкая эротика',
          t8: 'Тяжелая эротика',
          t9: 'Порно',
        },
        show_html: 'show (url трекера показа html)',
      },
      delete_creative_modal: {
        delete_tit: 'Удаление креатива',
        delete_quest: 'Вы хотите удалить',
      },
      no_result_for_filter: 'По выбранному фильтру ничего не найдено',
      tizer_word: 'Тизер',
      targeting_settings:
        'Используются собственные настройки таргетинга креатива',
      pretargeting_settings: 'Используются собственные настройки претаргетинга',
      targetAndPretarget_settings:
        'Используются cобственные настройки претаргетинга и таргетинга',
      no_selected_pretarget: 'Не найдено выбранных настроек претаргетинга',
      creasmall_set: {
        linktoicon_word: 'Ссылка на иконку',
        icon_word: 'Иконка',
        teaser_formats1:
          'Допустимые форматы: png, jpeg, jpg, gif. Максимальный размер — 6 Mb.',
        teaser_formats2:
          'Минимальное разрешение 80 × 80 px. Максимальное — 4096 × 4096px.',
        linktoimage_word: 'Ссылка на изображение',
        image_word: 'Изображение',
        teaser_formats3:
          'Допустимые форматы: png, jpeg, jpg, gif. Максимальный размер - 6 Мб.',
        teaser_formats4:
          'Минимальное разрешение 100 × 100 px. Максимальное — 4096 × 4096 px.',
        html_format1:
          'Допустимые форматы: png, jpeg, jpg, gif. Максимальный размер — 6 Mb.',
        html_format2:
          'Минимальное разрешение 100 × 100 px. Максимальное — 4096 × 4096px.',
        title_word: 'Заголовок',
        text_word: 'Текст',
        upload_comp: 'Загрузить с компьютера',
        upload_word: 'Загрузить',
        push_formats1:
          'Допустимые форматы: png, jpeg, jpg, gif. Максимальный размер - 6 Mb.',
        push_formats2: 'Допустимое разрешение 192 х 192 пикселей.',
        push_formats3: 'Допустимое разрешение 360 х 240 пикселей.',
        multuformat_formats1:
          'Допустимые форматы: png, jpeg, jpg. Максимальный размер - 2 Mb.',
        multuformat_formats2: 'Допустимое разрешение 256 х 256 пикселей.',
        multuformat_formats3: 'Допустимое разрешение 600 х 600 пикселей.',
        multuformat_formats4: 'Допустимое разрешение 1080 х 607 пикселей.',
        type_hint: {
          HTML: 'Для просмотра необходимо заполнить блок "Тип · HTML" и создать креатив, после этого кнопка станет активной.',
          PUSH: 'Для просмотра необходимо заполнить блок "Тип · PUSH" и создать креатив, после этого кнопка станет активной.',
          MULTI_FORMAT:
            'Для просмотра необходимо заполнить блок "Тип · Мультиформат" и создать креатив, после этого кнопка станет активной.',
          YANDEX:
            'Для  просмотра необходимо заполнить раздел “Картинка–заглушка” и создать креатив, после этого кнопка станет активной.',
          VIDEO_INLINE:
            'Для  просмотра необходимо загрузить видео и создать креатив, после этого кнопка станет активной.',
        },
        multiformat: {
          type_of_btn: 'Тип кнопки',
          legal_info_title: 'Юридическая информация',
          icon: 'Иконка (256 x 256)',
          image_1: 'Картинка (600 х 600)',
          image_2: 'Картинка (1080 х 607)',
          title_hint:
            'Введите заголовок объявления. Нельзя начинать предложение с маленькой буквы или символа. Используйте большую букву в начале предложения.',
          text_hint:
            'Введите текст объявления. Обратите внимание, что в объявлении может быть только один восклицательный знак: или в заголовке, или в тексте. Нельзя начинать предложение с маленькой буквы или символа. Используйте большую букву в начале предложения.',
          legal_info:
            'В поле «Юридическая информация» добавьте данные о продавце (ОГРН, ИНН и т.д.) либо оставьте это поле пустым.',
          type_btn_hint:
            'Кнопка призыва к действию. Используйте подходящий Текст кнопки для увеличения привлекательности вашего объявления.',
        },
        type_html_hint:
          'Для просмотра необходимо заполнить блок "Тип · HTML" и создать креатив, после этого кнопка станет активной.',
        type_push_hint:
          'Для просмотра необходимо заполнить блок "Тип · PUSH" и создать креатив, после этого кнопка станет активной.',
        type_multiformat_hint:
          'Для просмотра необходимо заполнить блок "Тип · Мультиформат" и создать креатив, после этого кнопка станет активной.',
        type_yandex_hint:
          'Для  просмотра необходимо заполнить раздел “Картинка–заглушка” и создать креатив, после этого кнопка станет активной.',
        type_video_hint:
          'Для  просмотра необходимо загрузить видео и создать креатив, после этого кнопка станет активной.',
        type_video_vast_hint:
          'Для  просмотра необходимо загрузить видео VAST и создать креатив, после этого кнопка станет активной.',
        type_companion_hint:
          'Для просмотра необходимо загрузить видео с html кодом и создать креатив, после этого кнопка станет активной.',
        notarg_text: 'Для настройки таргетов необходимо создать креатив',
        zip_link: 'Ссылка на ZIP–архив',
        zip_arr: 'ZIP–архив',
        zip_arrrull: 'Требования к рекламным материалам',
        yand_format: 'Формат: ZIP-архив. Максимальный размер — 150 кб.',
        yand_link_img: 'Ссылка на картинку–заглушку',
        yand_stub: 'Картинка–заглушка',
        yand_formats1:
          'Форматы: jpeg, jpg, png, gif, svg. Максимальный размер — 150 кб.',
        yand_formats2:
          'Минимальное разрешение 80 × 80 px. Максимальное — 4096 × 4096px.',
        yand_alt_text: 'Альтернативный текст',
        yand_brand: 'Яндекс бренд',
        yand_cat: 'Яндекс категории',
      },
      settings: {
        set_tit: 'Настройки',
        ext_sour: 'Привязка внешних источников',
        ids: 'Внешние идентификаторы',
        id_from_ss: 'Введите id из СС',
        enter_date: 'Введите данные',
        help_text:
          'Для пользователей с правами администратора доступен раздел с широким спектром настроек, позволяющий задавать коэффициенты цены по дням (для типа цены CPA), привязывать внешние источники, регулировать список доступных отчетов для кампании и многое другое.',
      },
      mass_editing: 'Массовое редактирование',
      set_url_incl_links:
        'Настройки URL применятся только в тех креативах, которые содержат данные ссылки.',
      liks_stays:
        'Ссылки, не добавленные в массовом редактировании, останутся без изменений',
      will_be_changed:
        'Будут изменены только те поля, в которые добавлены значения',
      error: 'Не удалось сохранить массовое редактирование',
      add_url: 'Добавить URL для редактирования',
      enter_link: 'Введите ссылку',
      link_will_clean: 'Поле будет очищено',
      discard_changes: 'Отменить изменения',
      all_changes_saved:
        'Все изменения успешно сохранены в выбранных {{value}} креативах',
      used_in_creatives: 'Используется в креативах: {{creos}}',
      the_field_be_deleted:
        'Содержимое поля будет удалено в выбранных креативах',
      eridWarning_confirm:
        'ERID не зарегистрирован в DSP! Вы подтверждаете его использование в ротации?',
      eridWarning_what_show:
        'Вы используете незарегистрированный ERID в DSP! В рекламном блоке не будет отображаться ИНН и название контрагента.',
    },
    containers_page: {
      tit: 'Контейнеры',
      name: 'Название',
      size_aut: 'Объем аудитории',
      size_aut_day: 'Объем аудитории в день',
      back: 'Назад',
      date: 'Дата',
      stat_tit: 'Статистика',
      download: 'Скачать',
      more_info: 'Более подробную информацию можно узнать, перейдя по ссылке',
      containers_none: 'У Вас нет ни одного контейнера',
      not_linked: 'Контейнеры не привязаны',
    },
    search_by_id_popup: {
      filter: {
        placeholder: 'Фильтр',
      },
      search: {
        placeholder: 'ID кампании / креатива',
        clear: 'Очистить',
      },
      empty_list: 'По данному запросу ничего не найдено',
    },
    type_parther: {
      ORGANIZATION: 'Организация',
      AGENCY: 'Агентство',
      CLIENT: 'Клиент',
    },
    report: {
      download: 'Скачать отчет',
    },
    translations: {
      title: 'Статус',
      title_hint_camp: 'Статус трансляции кампании.',
      title_hint_creo: 'Статус трансляции объявления.',
      title_hint_link: 'Подробнее.',
      status_paused: 'Статус организации приостановлен',
      agency_no_start: 'Агентство не запущено',
      client_not_start: 'Клиент не запущен',
      campaign_stoppen: 'Кампания остановлена',
      outdated_period: 'Неактуальный период ротации',
      zero_target: 'Нулевая цель оптимизации',
      balance_exhausted: 'Баланс исчерпан',
      campaign_ready: 'Кампания готова для запуска креативов',
      adv_uploaded: 'Объявление выгружается для трансляции',
      adv_not_uploaded: 'Объявление не выгружается, показов не будет.',
      ad_stopped: 'Объявление остановлено',
      ad_not_pass: 'Объявление не прошло модерацию',
      broadcast: 'Транслируется',
      no_broadcast: 'Не транслируется',
      title_widget: 'Статус трансляции',
      no_results_status: 'По выбранному статусу трансляции ничего не найдено',
      contact_adnim_toggle:
        'Обратитесь к администратору для изменения статуса.',
      check_toggle: 'Для запуска - нажмите на переключатель.',
      check_toggle_need_help: 'Если нужна помощь, обратитесь к администратору.',
      check_toggle_check_settings:
        'Проверьте настройки креатива и отправьте повторно на модерацию.',
      check_date:
        'Проверьте дату начала и окончания в настройках кампании. Подробнее читайте по ',
      check_date_link: 'ссылке.',
      check_price:
        'Проверьте цену для заданного типа оптимизации в настройках кампании. Подробнее читайте по ',
      check_price_link: 'ссылке.',
      no_funds:
        'На лицевом счете клиента нет средств, обратитесь к администратору.',
      prepare_creatives:
        'Осталось подготовить креативы для показов:создайте креативы, пройдите модерацию и заполните обязательные таргеты.',
      campaign_no_ready: 'Кампания не готова для запуска креативов в ротацию.',
      dsp_disable: 'Отключено в DSP',
      no_impressions: 'Отключено в DSP, показов не будет',
      camp_stop: 'Кампания остановлена в DSP.',
      call_admin: 'Обратитесь к администратору',
      client_stop: 'Клиент остановлен в DSP',
      ad_stop: 'Объявление остановлено в DSP',
      dsp_problem: 'Проблемы в DSP',
      fixed_soon: 'Совсем скоро все заработает',
      ad_is_run: 'Внимание: Объявление в ротации в DSP',
      camp_is_run: 'Внимание: Кампания в ротации в DSP',
      error_creo: 'Ошибка в данных креатива',
      contact_adnim: 'Обратитесь к администратору.',
      geo_target_empty: 'Не заполнен ГЕО таргет',
      set_local:
        'Проверьте в таргетинге креатива и задайте локацию. Подробнее читайте по',
      set_local_link: 'ссылке.',
      source_target_empty: 'Не заполнен таргет "Источники"',
      select_ssp:
        'Проверьте в таргетинге креатива и выберите SSP. Подробнее читайте по ',
      select_ssp_link: 'ссылке.',
      profile_settings: 'Показывать статус трансляции',
      profile_set_info:
        'Если кампании созданы для показов объявлений, то включите настройку. Вы увидите статус трансляции кампании и объявления на страницах ТД.',
      see_statistics:
        'Если кампания/креатив прошли проверки, то вы увидите статистику с количеством показов и/или кликов. Если показов нет более часа, обратитесь к администратору.',
      prepare_creo:
        'Осталось подготовить креативы для показов: создайте креативы, пройдите модерацию и заполните обязательные таргеты.',
    },
    ord: {
      title: 'ОРД',
      registration_in_ord: 'регистрация в орд',
      сounterparties_title: 'Контрагенты',
      сounterparties_search: 'Поиск по ИНН / Названию юр. лица',
      counterparte_create: 'Создать контрагента',
      сounterparties_none:
        'У вас еще нет контрагентов. Нажмите, чтобы создать нового.',
      contracts_title: 'Изначальные договоры',
      counterparte_creating: 'Создание контрагента',
      parameters: 'Параметры',
      type: 'ТИП *',
      name_of_legal: 'НАЗВАНИЕ ЮР. ЛИЦА*',
      synonyms_name: 'СИНОНИМЫ НАЗВАНИЯ',
      inn: 'ИНН / АЛЬТЕРНАТИВА',
      synonyms_name_1: 'СИНОНИМЫ НАЗВАНИЯ (ЧЕРЕЗ ЗАПЯТУЮ)',
      name_for_export: 'НАЗВАНИЕ ДЛЯ ВЫГРУЗКИ',
      synonyms_enter: 'Введите синонимы',
      contract_create: 'Создать договор',
      contract_search:
        'UUID / Номер договора / Внешний ID / ИНН исполнителя / ИНН заказчика',
      customer_select: 'Выберите заказчика',
      contractor_select: 'Выберите исполнителя',
      contract_delete: 'Удалить договор',
      want_to_del:
        'Вы действительно хотите удалить договор <bold>{{title}}</bold>?',
      contract_deleted: 'Договор <bold>{{title}}</bold> удален',
      contacts_none: 'У вас еще нет договоров. Нажмите, чтобы создать новый.',
      creating_contact: 'Создание договора',
      contact_info: 'Информация по договору',
      contragents_info: 'Информация по контрагенту',
      registered: 'Регистрируется в ОРД',
      original_contract: 'Является изначальным договором',
      contract_data: 'Данные договора',
      additional_contract_data: 'Доп. данные договора',
      inn_name: 'ИНН / Название',
      contractor: 'Исполнитель',
      contract_number: 'номер договора *',
      enter_number: 'Введите номер',
      contract_date: 'дата договора *',
      enter_date: 'Введите дату',
      contract_type: 'тип договора *',
      select_type: 'Выберите тип',
      information_on_contract: 'Сведения о предмете договора *',
      contract_amount: 'сумма договора, ₽ *',
      transferring_money: 'направление денежных средств',
      select_transfer: 'Выберите направление',
      actions: 'Действия, осуществляемые посредником-представителем *',
      report_contract: 'договор отчета',
      obligation_to_register:
        'Обязанность регистрировать и репортить креативы исполнителем',
      inc: 'С НДС',
      contact_created: 'Договор <bold>{{title}}</bold> успешно создан',
      exported: 'Экспортировано в ЕРИР',
      revenue_contract: 'Доходный договор',
      parental_contract: 'Родительский договор',
      financial_closure:
        'По этому договору будет происходить финансовое закрытие.',
      impossible_export: 'Не выгружается в ЕРИР',
      send: 'Отправить в ЕРИР',
      export_planned: 'Запланирован экспорт',
      original_agreement:
        'Если изначальный договор клиента имеет тип "Дополнительное соглашение", необходимо передать и информацию о соответствующем исходном договоре(-а). Обязателен к заполнению только для доп. соглашений.',
      impossible_send: 'Не отправляется',
      pending: 'В ожидании',
      legal_entity: 'Юридическое лицо',
      individual_entity: 'Физическое лицо',
      sole_trader: 'Индивидуальный предприниматель',
      foreign_individual_entity: 'Иностранное физическое лицо',
      foreign_legal_entity: 'Иностранное юридическое лицо',
      registration_number: 'Регистрационный номер *',
      identification_number: 'Номер налогоплательщика либо его аналог *',
      counterparty_created: 'Контрагент <bold>{{title}}</bold> успешно создан',
      inn_or: 'ИНН',
      status: 'Статусы',
      customer: 'ЗАКАЗЧИК',
      waiting_export: 'Ожидает выгрузки',
      successfully: 'Успешно',
      error: 'Ошибка',
      date: 'ДАТА',
      td_clients: 'КЛИЕНТЫ TD*',
      td_agencis: 'АГЕНТСТВА TD*',
      intermediary_contract: 'Посреднический договор',
      other: 'Иное',
      agent_acts: 'Агент действует в интересах РР',
      yes: 'Да',
      no: 'Нет',
      contract_info: 'Договор',
      clients: 'Клиенты *',
      no_del_contracts: 'Нет удаленных договоров',
      fio: 'ФИО',
      actions_for_dist: 'Действия в целях распространения рекламы',
      contracts_concl: 'Заключение договоров',
      commercial_rep: 'Коммерческое представительство',
      agent_acts_behalf: 'Агент действует в интересах РД',
      service_contract: 'Договор оказания услуг',
      additional_agreement: 'Дополнительное соглашение',
      self_advertisement: 'Самореклама',
      representation: 'Представительство',
      distribution_org: 'Организация распространения',
      intermediation: 'Посредничество',
      distribution: 'Распространение',
      registration_country: 'Страна регистрации',
      mobile_phone: 'АБОНЕНТСКИЙ НОМЕР МОБИЛЬНОГО ТЕЛЕФОНА *',
      unknow_ord: 'Неизвестная ОРД',
      active: 'Активный',
      moderation: 'На модерации',
      deleted: 'Удален',
      contracts: {
        title: 'ОКВЭД',
        code: 'Коды ОКВЭД для объекта рекламирования.',
        hint1: 'Поле обязательно для заполнения, если в изначальном договоре заказчик физическое лицо или иностранный контрагент.',
        hint2: 'ОКВЭД будет автоматически применяться в тех креативах, в которых используется данный изначальный договор.',
        placeholder_search: 'Поиск по № договора / ИНН',
        one: 'Изначальный договор',
        open: 'Открыть договор',
      },
      errors: {
        required_field: 'Обязательное для заполнения поле',
        already_exists: 'Контрагент с таким значением ИНН уже существует',
        inn_invalid: 'ИНН не валиден',
        should_not_match: 'Заказчик и исполнитель не должны совпадать',
        min_6_characters: 'Минимум 6 знаков',
        max_40_characters: 'Максимум 40 знаков',
        min_1_character: 'Минимум 1 знак',
        max_255_characters: 'Максимум 255 знаков',
        max_1000_characters: 'Максимум 1000 знаков',
        min_value_0: 'Минимальное значение 0',
        max_value_exceeded: 'Превышено максимальное значение',
        incorrect_value: 'Некорректное значение',
        field_only_digits: 'Поле должно содержать только цифры',
        fill_field_or_epay: 'Заполните это поле или EPAY',
        fill_field_or_phone:
          'Заполните это поле или "Абонентский номер мобильного телефона"',
        field_only_latin_letters:
          'Поле должно содержать только цифры и латинские буквы',
        fill_filed_or_equivalent:
          'Заполните это поле или "Номер налогоплательщика либо его аналог"',
        fill_field_or_number: 'Заполните это поле или "Регистрационный номер"',
        taxpayer_equivalent: 'Номер налогоплательщика либо его аналог',
        customer_contract_type:
          'Заказчик и исполнитель должны совпадать для типа договора "Самореклама"',
        unsupported_contract: 'Неподдерживаемый тип договора для ОРД',
        cus_refer:
          'Заказчик и исполнитель должны относится к тому же ОРД, что и договор',
        should_written:
          'ФИО должно состоять из кириллицы, содержать между словами один пробел либо тире',
        okved_field: 'Поле ОКВЭД обязательно для заполнения',
      },
    },
    found: 'Найдено',
    save_btn: 'Сохранить',
    cancel_btn: 'Отмена',
    ok_btn: 'ОК',
    continue_btn: 'Продолжить',
    copy_btn: 'Копировать',
    edit_btn: 'Редактировать',
    add_btn: 'Добавить',
    create_btn: 'Создать',
    select_btn: 'Выбрать',
    btn: 'Кнопка',
    no_result: 'Не найдено',
    apply_btn: 'Применить',
    stop: 'Остановить',
    stopped: 'остановлены',
    launched: 'запущены',
    deleted: 'удалены',
    start: 'Запустить',
    search_btn: 'Искать',
    clean_btn: 'Очистить',
    more_btn: 'Подробнее',
    yes: 'Да',
    no: 'Нет',
    all: 'Все',
    email: 'Email',
    selected: 'Выбранные',
    delete_btn: 'Удалить',
    update_btn: 'Обновить',
    change_btn: 'Изменить',
    in_archive_btn: 'В архив',
    from_archive_btn: 'Извлечь',
    retrieved: 'извлечены из архива',
    archived: 'добавлены в архив',
    archive_title_to: 'Добавить в архив',
    archive_title_from: 'Извлечь из архива',
    expand_btn: 'Развернуть',
    collapse_btn: 'Свернуть',
    duplicate_btn: 'Дублировать',
    statistics_btn: 'Статистика',
    download_btn: 'Скачать',
    show_btn: 'Показать',
    copy_to_another_campaign_btn: 'Копировать в другую кампанию',
    wider_then: 'Во всю ширину',
    wider_center: 'Компактный вид',
    disable_edit: 'У Вас есть ограничение на редактирование этого блока',
  },
};
