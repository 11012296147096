export default {
  translations: {
    error_page: {
      err404: {
        title: 'Fehler 404. Seite nicht gefunden',
        text: 'Versuchen Sie, von der Homepage aus zu starten.',
        button: 'Zur Startseite',
      },
      err500: {
        title: 'Fehler 500',
        text: 'Entschuldigung, wir haben einen Fehler',
        button: 'Zurück zu Hause',
      },
      errNC: {
        title: 'Keine Internetverbindung',
        text: 'Sie scheinen Probleme mit der Verbindung zum Netzwerk zu haben.',
      },
      errUC: {
        title: 'Technische Arbeiten werden durchgeführt.',
        text: 'Alles wird sehr bald funktionieren. Wir entschuldigen uns für die Unannehmlichkeiten.',
      },
      errAB: {
        title: 'Wir sehen, dass Sie einen Adblocker eingeschaltet haben.',
        text: 'Er blockiert die Teile der Seite, die Ihre Surfaktivitäten verfolgen könnten. Bitte deaktivieren Sie den Adblocker, um auf unsere TD-Webseite zuzugreifen.',
      },
    },
    errors: {
      upload: {
        e1: 'Ungültiges Dateiformat. Zip ist erforderlich',
        e2: 'Ungültiges Dateiformat. png, jpeg, jpg, gif, svg ist erforderlich',
        e3: 'Dateigröße ist mehr als 150 kb',
        e4: 'Dateigröße ist mehr als',
        e5: 'Ungültiges Dateiformat. Benötigt ',
      },
      unknown_error: 'Unbekannter Fehler in der Methode',
      formdom: 'Validierungsfehler: Ungültiges Format des Domains oder URL',
      additional_cat_err:
        'Diese Kategorie kann das verfügbare Inventar erheblich einschränken.',
      err_http_lorem:
        'Falsches Format des Links. Bitte http:// oder https:// oder {LOREM} benutzen',
      err_http_lorem2:
        'Falsches Format des Links. Bitte http:// oder https:// benutzen',
      limitsym: 'Zeichennummer ist überschritten.',
      percent_limit: 'Werte bis 100 sind möglich',
      parameter_d_n_match:
        'Der Wert des ID-Übergabeparameters stimmt nicht mit der Referenz überein',
      f1_d_n_cont_id:
        'Im 1F Link gibt es kein Parameter der Übergabe von Impressionen-ID',
      limit_symbol: {
        start: 'Numerischer Wert ist überschritten. Max - ',
        pixel: ' Pixel',
      },
      err_arh: 'Ungültiges Dateiformat. Zip ist erforderlich',
      err_value: 'Ungültiger Wert',
      err1: 'Systemfehler ',
      err_file: 'Fehler beim Hochladen der Datei. \n',
      err40: 'Der Dateiname ist nicht gültig.',
      err40_1: 'Validierungsfehler: Ungültiges Format des Optionswertes.',
      err40_link: 'Fehler beim Laden. Ungültige URL',
      err41: 'Die Zahl ist zu groß',
      err42: 'Zu kleine Zahl',
      err43: 'maximale Zeichennummer ist überschritten',
      err44: 'Die Mindestlänge ist mehr als 1 Zeichen',
      err45: 'Das ist ein Pflichtfeld. Bitte geben Sie die Daten ein',
      err46: 'Ungültiges Format des Optionswertes',
      err47: 'Ungültiges Format der Zeichenfolge',
      err47_container: 'Fehler. Containernummer ist falsch',
      err48: 'Daten werden nicht gespeichert, Validierungsfehler.',
      err49: 'Ungültiges Format der Aufzählung',
      err50:
        'Datenvalidierungsfehler. Die Datei entspricht den Ladenanforderungen nicht',
      err90: 'Fehler: Element wurde nicht gefunden',
      err91: 'Fehler: Keinen Zugang ',
      err92: 'Fehler: Keine Daten für Änderungen sind verfügbar ',
      err93: 'Fehler: Antwort-Timeout ist überschritten',
      err94:
        'Datenfehler. Der Service ist derzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal',
      err100: 'Ungültiges Token',
      err101: 'Fehler bei der Überprüfung der Signatur',
      err102: 'Token ist nicht mehr gültig',
      err103: 'Ungültige Tokendaten (keine Parameter)',
      err104: 'Ungültiger Typ des Tokens',
      err105: 'Token wurde geändert oder ist ungültig',
      err109: 'Ungültiger Typ des Tokens',
      err121: 'Fehler beim Erstellen des Tokens',
      err154_title: 'Da ist etwas schief gelaufen.',
      err154_desc: 'Zu großer Zeitraum. Bitte wählen Sie maximal 30 Tage',
      err201:
        'Kunde mit dieser E-Mail-Adresse wurde blockiert. Wenden Sie sich an den Systemadministrator',
      err202:
        'Kunde mit dieser E-Mail-Adresse ist nicht aktiviert. Wenden Sie sich an den Systemadministrator',
      err203: 'Registrierung ist nicht möglich',
      err218: 'Die Passwörter stimmen nicht überein',
      err219: 'Kunde mit dieser E-Mail-Adresse ist schon registriert',
      err220: 'Ungültiges E-Mail-Format',
      err221: 'Ungültiges E-Mail-Format',
      err222: 'Fehler beim Erstellen des Creatives',
      err223: 'Validierungsfehler: Ungültiges Format des Domains oder URL',
      err223_https:
        'Validierungsfehler: Ungültiges Linkformat. Verwenden Sie https: // ..',
      err224: 'Validierungsfehler: Ungültiges Format der Zielgruppe-ID',
      err225: 'Validierungsfehler: ungültiges Format des Targetwertes',
      err400: 'Datenbankfehler',
      err2010: 'Fehler beim Entpacken der Datei ',
      err227: 'Das falsche alte Passwort',
      err900: 'Die Statistik ist derzeit nicht verfügbar',
      err2011: 'Fehler beim Kopieren der Datei ',
      err2020: 'Fehler beim Abrufen der Daten ',
      err2021: 'Fehler: HTML-Datei ist nicht gefunden',
      err2030: 'Ungültiger Wert',
      err2031: 'Ungültige Dateierweiterung ',
      err2032: 'Dateigröße ist mehr als ',
      err2032_200: 'Die Dateigröße überschreitet 200 Mb.',
      err2033: 'Datei ist nicht gefunden ',
      err2034: 'Ungültiges Format',
      err2035: 'Ungültige Bildbreite oder -höhe',
      err2026: 'Fehler beim Laden der Datei',
      err2040: 'Datei ist nicht gefunden. Ungültige URL',
      err2041: 'Fehler beim Laden der Datei unter dem Link ',
      err2050: 'Kode muss Makro {LINK_UNESC} enthalten',
      err2051: 'Jeder Link muss Makro {LINK_UNESC} enthalten.',
      err2052:
        'Fehler beim Laden der Metadaten. Ungültiges Format des Metatags',
      err2053:
        'Fehler beim Laden der Metadaten. Größe des Metatags ist nicht gefunden ',
      err2054: 'Fehler beim Laden der Metadaten. Ungültige Dateigröße.',
      err2060: 'Ungültige Videodauer',
      err2061: 'Ungültige Videoauflösung ',
      err2062: 'Ungültiger Typ des Videocodecs',
      err2063: 'Ungültiger Typ des Audiocodecs',
      errAll: 'Ups… da ist etwas schief gelaufen',
      err_none: 'Bitte versuchen Sie, eine andere Datei hochzuladen',
      err_valid: 'Validierungsfehler:',
      err_access: {
        owner:
          'Zugriff verweigert. Bitte wenden Sie sich an Ihren Administrator',
        organization: 'Zugriff verweigert. Organisation wurde archiviert',
        agency: 'Zugriff verweigert. Agentur wurde archiviert ',
        client: 'Zugriff verweigert. Kunde wurde archiviert',
        campaign: 'Zugriff verweigert. Kampagne wurde archiviert',
      },
    },
    errors_desc: {
      hours_of_day: {
        t1: '„Nach der Tageszeit · UTC +3" Target wurde hinzugefügt',
        t2: '„Nach der Tageszeit · UTC +3" Target wurde zu dieser Kampagne hinzugefügt.',
        t3: 'Deaktivieren Sie das Target „Nach der Tageszeit · UTC +3“ in folgenden Kreativen: ',
      },
      hours_of_day_user: {
        t1: '„Nach der Tageszeit · UTC des Kunden" Target wurde hinzugefügt',
        t2: '„Nach der Tageszeit · UTC des Kunden" Target wurde zu dieser Kampagne hinzugefügt.',
        t3: 'Deaktivieren Sie das Target „Nach der Tageszeit · UTC des Kunden“ in folgenden Kreativen: ',
      },
      days_of_week: {
        t1: '„Nach den Wochentagen · UTC +3" Target wurde hinzugefügt.',
        t2: '„Nach den Wochentagen · UTC +3" Target wurde zu dieser Kampagne hinzugefügt.',
        t3: 'Deaktivieren Sie das Target „Nach den Wochentagen · UTC +3“ in folgenden Kreativen: ',
      },
      days_of_week_user: {
        t1: '„Nach den Wochentagen · UTC des Kunden" Target wurde hinzugefügt.',
        t2: '„Nach den Wochentagen · UTC des Kunden" Target wurde zu dieser Kampagne hinzugefügt.',
        t3: 'Deaktivieren Sie das Target „Nach den Wochentagen · UTC des Kunden“ in folgenden Kreativen: ',
      },
    },
    upload: {
      error: 'Nicht alle Dateien wurden hochgeladen',
      success: 'Alle Dateien wurden hochgeladen ',
    },
    just_words: {
      w1: 'Kampagne',
      w2: 'Kreativ · ',
      w3: 'Kunde',
      w4: 'Fehler',
      w5: 'Agentur',
      w6: 'Kampagnen',
      w7: 'Benutzer',
      Width: 'Breite',
      Height: 'Höhe',
      confirm_action: 'Bestätigen',
      exit_page: 'Navigation bestätigen',
      exit_page_currect: 'Möchten Sie die Seite wirklich verlassen?',
      exit_page_yes: 'Seite verlassen',
      exit_page_no: 'Auf dieser Seite bleiben',
      one_duplicate: 'Das Duplikat von',
      several_duplicates: 'Die Duplikate von',
      one_is_successful_create: 'wurde erfolgreich erstellt',
      several_successful_create: 'wurden erfolgreich erstellt',
    },
    offers_page: {
      offer: 'Angebot',
      offers: 'Angebote',
      create_offer: 'Das Angebot erstellen',
      change_offer: 'Änderung des Angebotes',
      add_in_archive: 'Möchten Sie wirklich das {{offer}}-Angebot archivieren??',
      from_in_archive: 'Möchten Sie wirklich das {{offer}}-Angebot aus dem Archiv entpacken??',
      empty_offers: 'Sie haben keine Angebote',
      links_will_chaned: 'Die Links werden in <bold>{{creoCount}}</bold> Kreativen geändert, wo das <bold>{{offer}}-Angebot</bold> verwendet wird',
      links_chaned_1: 'Die Links wurden in',
      links_chaned_2: 'Kreativen geändert',
      some_time: 'Das kann einige Zeit dauern...',
      save_chanes: 'Möchten Sie die für das Angebot vorgenommenen Änderungen speichern? Das kann einige Zeit dauern',
      back_to_list: 'Zurück zur Angebotsliste',
      use_offer: 'Das <bold>{{currentOfferTitle}}-Angebot</bold> ist in der Kampagne verwendet, es ist nicht möglich, Links aus dem Angebot zu bearbeiten',
      enter_name: 'Geben Sie den Namen',
      archive: 'Archiv',
      chanes_saved: 'Änderungen wurden erfolgreich gespeichert',
      saving_chanes: 'Speicherung von Änderungen',
      select_cliient: 'Wählen Sie den Kunden aus',
      creating_offer: 'Angebot erstellen',
      confirm: 'Bestätigen',
      somthing_wrong: 'Ups, hier ist etwas schief gelaufen',
      search_with_name: 'Suche nach Titel',
      active_offer_none: 'Keine aktiven Angebote',
      arhiv_offer_none: 'Es gibt keine Angebote im Archiv',
      no_availibale_offers: 'Keine Angebote verfügbar',
      offer_archived: 'Das <bold>{{title}}-Angebot</bold> wurde archiviert',
      offer_launched: 'Das <bold>{{title}}-Angebot</bold> wurde aus dem Archiv extrahiert',
      offer_has_created: '<bold>{{title}}-Angebot</bold> erstellt',
    },
    common: {
      savedBlock_part1: 'Block ',
      savedBlock_part2: ' erfolgreich gespeichert',
      targeting_key: {
        hour: 'Act (h)',
      },
      target_items: {
        ru: 'Russland',
        ua: 'Die Ukraine',
        rs: 'Serbien',
        lv: 'Lettland',
        kz: 'Kasachstan',
        by: 'Weißrussland',
        kg: 'Kirgisien',
        uz: 'Usbekistan',
        de: 'Deutschland',
        gb: 'Großbritannien (GB)',
        uk: 'England (UK)',
        pl: 'Polen',
        'n/a': 'OTHER',
        us: 'die USA',
        6252001: 'die USA (6252001)',
        ng: 'Nigeria',
        my: 'Malaysia',
        ge: 'Georgien',
        ae: 'VAE',
        tr: 'Die Türkei',
        ar: 'Argentinien',
        vn: 'Vietnam',
        in: 'Indien',
        ph: 'Philippinen',
        tj: 'Tadschikistan',
        br: 'Brasilien',
        th: 'Thailand',
        1: 'Montag',
        2: 'Dienstag',
        3: 'Mittwoch',
        4: 'Donnerstag',
        5: 'Freitag',
        6: 'Samstag',
        0: 'Sonntag',
      },
      targetings: {
        sourse: 'Quellen',
        network: 'Network SSP',
        geo_raw: 'GEO +',
        geo_id: 'Regionen',
        city: 'Geo · Regionen & Städte',
        os: 'Betriebssysteme',
        video_type: 'Nach Videoanzeigenformat',
        browser: 'Browsers',
        browser_lang: 'Browsersprache',
        traffic_type: 'Das Typ des Traffics',
        au: 'Zielgruppen',
        au_bin_8: 'bin_au: Aidata (8)',
        au_bin_15: 'bin_au: UID 2.0 (15)',
        au_bin_20: 'bin_au: Zielgruppe des Kunden (20)',
        isp: 'Internetanbieter',
        app: 'Anwendungen',
        private_deals: 'Direktgeschäfte',
        viewability: 'Viewability',
        uniq_show_creative:
          'Nach der Nummer der Schau des Kreatives an den Benutzer',
        uniq_show_creative_hour:
          'Nach der Nummer der Schau des Kreatives an den Benutzer pro Stunde',
        uniq_show_creative_day:
          'Nach der Nummer der Schau des Kreatives an den Benutzer pro Tag',
        days_of_week: 'Nach den Wochentagen · UTC +3',
        days_of_week_user: 'Nach den Wochentagen laut · UTC des Benutzers',
        hours_of_day: 'Nach der Tageszeit · UTC +3',
        hours_of_day_user: 'Nach der Tageszeit laut · UTC des Benutzers',
        domain: 'Domains',
        site_uri: 'URL',
        adgroup: 'AdGroup · Google Presets',
        page_context: 'Kontext (Webseiten)',
        socdem: 'Sozialdemographischer Parameter',
        au_bin_info: 'Zielgruppen',
        au_bin_lada: 'Zielgruppen',
        uroven_dohoda: 'Einkommensstufe',
        actions: 'Video-Werbe-Event-Statistik',
        actions_domain: 'Domain-Aktivität',
        au_bin_21: 'Publikum von Websites nach SSP uid (21)',
        semeinoe_polozhenie: 'Familienstand',
        interesy: 'Interessen',
        clean_all_targeting_creative: 'Alle Targets von dem Kreativ',
        clean_all_targeting_campaign: 'Alle Targets von der Kampagne',
        clean_all_targeting_success: 'wurden entfernt',
        clean_target_success: 'wurde gelöscht',
        uniq_show_camp: 'Nach der Nummer der Schau der Kampagne an den Kunden',
        uniq_show_camp_hour:
          'Nach der Nummer der Schau der Kampagne an den Kunden pro Stunde',
        uniq_show_camp_day:
          'Nach der Nummer der Schau der Kampagne an den Kunden pro Tag',
        keywords: 'Nach Schlüsselwörtern und Phrasen',
        video_size: 'Videoabmessungen',
        sex: 'Geschlecht',
        age: 'Alter',
        sex_rtb: 'Geschlecht RTB',
        categories_rtb: 'Interessen RTB',
        age_rtb: 'Alter RTB',
        adn: 'Werbenetzwerke',
        publisher: 'Von Verlegern',
        os_versions: 'Nach OS-Version',
        spotid: 'Block ID',
        rewarded: 'Werbung gegen Belohnung',
        video_size_site_domain: 'Videogrößen gruppiert nach Domains',
        fullscreen: 'Fullscreen',
        exact_url: 'Target nach vollständiger URL-Übereinstimmung',
      },
      pretargeting: {
        comp_retarget_enable_now:
          'Pretargeting-Einstellungen der Kampagne sind aktiviert',
        comp_retarget_enable:
          'Pretargeting-Einstellungen der Kampagne aktivieren',
      },
      targetings_error: {
        site_domain: {
          countModal: 'Die Anzahl der Domains übersteigt 1000, verwenden Sie Whitelist in Pretargets',
          count:
            'Die Anzahl der Domains übersteigt 1000, verwenden Sie Whitelist in Pretargets',
          help: 'Sie können bis zu 1000 Domains hinzufügen. Um Target mit einer großen Anzahl von URLs einzurichten, verwenden Sie Whitelist in Pretargets.',
        },
        site_uri: {
          countModal: 'Die Anzahl der URLs übersteigt 100, verwenden Sie Whitelist in Pretargets',
          count:
            'Die Anzahl der URLs übersteigt 100, verwenden Sie Whitelist in Pretargets',
          help: 'Sie können bis zu 100 URLs hinzufügen. Um Target mit einer großen Anzahl von URLs einzurichten, verwenden Sie Whitelist in Pretargets',
        },
        app: {
          resource_not_found:
            'Die Ressource mit der angeforderten ID wurde nicht gefunde',
        },
      },
      tags: {
        revert_change: 'Änderung rückgängig machen',
        second: 's',
      },
      reports: {
        day: 'Gesamtbericht',
        day_techfee: 'Gesamtbericht. Gruppiert nach tech fee',
        yandex: 'Yandex.Metrika. Konsolidierter Bericht',
        metrika: 'Yandex.Metrika. Konsolidierter Bericht',
        nmi_report: 'NMI Bericht',
        'nmi-integration-src': 'NMI Bericht',
        integr_report: 'Integrierter Bericht',
        integration: 'Integrierter Bericht',
        socdem: 'Soziodemografischer Bericht',
        soc_dem_report: 'Soziodemografischer Bericht',
        geo_city: 'Geo Bericht',
        geo_report: 'Geo Bericht',
        shownum: 'Frequenz-Bericht',
        extended: 'Detaillierter Bericht',
        frequency_shows: 'Frequenz-Bericht',
        extended_report: 'Detaillierter Bericht',
        none: 'Sie haben keine Berichte',
        no_data: 'Keine Daten für Bericht verfügbar',
        no_reports: 'Keine Berichte verfügbar',
        general: {
          t1: 'Impressionen',
          t2: 'Datum',
          t3: 'Klicks',
          t4: 'Kosten',
          t5: 'Kosten: ',
          t6: 'Stadt',
          t7: 'Frequenz der Impressionen',
          t8: 'Sonstiges',
          t9: '',
          t10: '',
          t11: '',
          t12: '',
          t13: '',
          t14: '',
          t15: '',
          actions: 'Aktionen',
        },
      },
      modal: {
        copy_list: 'Liste kopieren',
      },
      empty_data: 'Keine Daten verfügbar',
    },
    charts: {
      impression: 'Visite',
      click: 'Klicks',
      view: 'Impressionen',
      spent: 'Ausgegeben',
      total: 'Insgesamt',
      utm_tags: 'UTM-Parameter',
    },
    statuses: {
      launched: 'Aktiviert',
      stopped: 'Deaktiviert',
    },
    form: {
      common: {
        authorization: 'Genehmigung',
        login: 'Anmeldung',
        signin: 'Anmelden',
        signup: 'Konto anlegen',
        forgot: 'Passwort vergessen?',
        email: 'E-mail',
        password: 'Passwort',
        register: 'Anmelden',
        have_acc: 'Sind Sie schon registriert?',
        new_user: 'Neuer Benutzer?',
        confirm: 'Passwort bestätigen',
        login_lab: 'Anmeldung',
        mob_phone: 'Telefonnummer',
        skype: 'Skype',
        telegram: 'Telegram',
        wrongPassword: 'Passwort ist falsch',
        passesMiss: 'Passwörter stimmen nicht überein',
        passMiss: 'Passwort stimmt nicht überein',
        new_password: 'Neues Passwort',
        saveChanges: 'Änderungen speichern',
        enter_email: 'E-Mail-Adresse eingeben',
        wrong_format: 'Ungültiges E-Mail-Format',
        phone: 'Telefon',
        login_rules: 'Die Anmeldung muss zwischen 3 und 100 Zeichen lang sein',
        phone_rules: 'Das Telefon muss zwischen 3 und 100 Zeichen lang sein',
        required: 'Pflichtfeld',
        password_rules: 'Das Passwort muss aus mehr als 6 Zeichen bestehen',
        password_long: 'Passwort ist zu lang',
        password_short: 'Passwort ist zu kurz',
        email_exist: 'Eine solche E-Mail existiert bereits',
        login_long: 'Login ist zu lang',
        login_short: 'Login ist zu kurz',
        field_long: 'Feld ist zu lang',
        field_short: 'Feld ist zu kurz',
        offer: 'Mit der Anmeldung erklären Sie sich mit den Bedingungen der ',
        offer__link: 'Offerte einverstanden',
        offer__link__auth: 'EULA',
        not_valid_link: 'Dieser Link ist nicht gültig',
        fill_the_field: 'Füllen Sie das Feld aus', 
      },
    },
    top: {
      partner_managed: 'von der Werbeagentur verwaltet',
      partner_organization: 'verwaltet von der Organisation',
      exit: 'Abmelden',
      menu: {
        clients: 'Kunden',
        campaigns: 'Kampagnen',
        containers: 'Containers',
        load_data: 'Daten hochladen',
        account: 'Konto',
        bwlists: 'Schwarze und Weiße Liste',
        agency: 'Agenturen',
        partners: 'Partner',
        users: 'Benutzer',
        uploadbwl: 'BWLists laden',
        history_list: 'Geschichte der Änderungen',
        control_panel: 'Dashboard',
        project: 'Projekt',
      },
    },
    balance_modal: {
      from: 'Von',
      to: 'Auf',
      enter_am: 'Geben Sie den Betrag ein',
      transfer: 'Überweisen',
      not_enough: 'Unzureichende Mittel für Überweisung',
      success_1: '{{amount}} {{currency}} wurden erfolgreich auf {{label}} überwiesen',
      select_client: 'Wählen Sie ein Abbuchungskonto aus',
      select_count: 'Wählen Sie ein Einzahlungskonto aus',
    },
    bwlist_modal: {
      title: 'BWLists hochladen',
      dropzone_text:
        'Wählen Sie Dateien für schwarze Listen mit zulässigen Namen aus oder ziehen Sie sie per Drag & Drop. Wenn eine Datei mit demselben Namen bereits vorhanden ist, wird sie ersetzt.',
      button_ok: 'OK',
    },
    blackLists: {
      title: 'DMP Schwarze Liste',
      file_type: 'Dateityp',
      global: 'Global',
      clients: 'Kunden',
      exported: 'Exportiert',
      publisher: 'Publisher',
      who_deleted: 'Entfernt vom',
      selected_string: '<bold>{{value}}</bold> Zeilen ausgewählt',
      del_from_bl: 'Von der schwarzen Liste entfernen',
      add_from_bl: 'Zu der schwarzen Liste hinzufügen',
      del_from_bl_real: 'Möchten Sie wirklich ausgewählte Zeilen von der schwarzen Liste entfernen?',
      add_from_bl_real: 'Möchten Sie wirklich ausgewählte Zeilen zu der schwarzen Liste hinzufügen?',
      source: 'Quelle',
      domain: 'Domain',
      adding_to_bl: 'Hinzufügen zu der schwarzen Liste',
      deleting_from_bl: 'Entfernen von der schwarzen Liste',
      date_del: 'Datum, wenn der Datensatz entfernt wurde',
      date_add: 'Datum, wenn der Datensatz hinzugefügt wurde',
      on_in_bl: 'Auf die schwarze Liste gesetzt',
      off_from_bl: 'Von der schwarzen Liste gestrichen',
      something_wrong: 'Etwas ist schief gelaufen. Die Datei ist nicht mehr aktualisiert',
    },
    multiple_upload: {
      status: {
        all: 'Alle Dateien wurden hochgeladen',
        not_all: 'Nicht alle Dateien wurden hochgeladen',
      },
    },
    login_page: {
      error_email: 'Ungültiges E-Mail-Format',
      error_password: 'Ungültige E-Mail-Adresse oder ungültiges Passwort',
      language_tit: 'Sprache',
      user_blocked:
        'Kunde mit dieser E-Mail-Adresse wurde blockiert. Wenden Sie sich an den Systemadministrator',
      user_noact:
        'Kunde mit dieser E-Mail-Adresse ist nicht aktiviert. Wenden Sie sich an den Systemadministrator',
      registration_unavailable: 'Registrierung ist nicht möglich',
      mail_already_registered:
        'Kunde mit dieser E-Mail-Adresse ist schon registriert',
      confirm_pass: 'Die Passwörter stimmen nicht überein',
      unknown_error: 'Unbekannter Fehler',
      user_is_not_active:
        'Kunde mit dieser E-Mail-Adresse ist nicht aktiviert. Wenden Sie sich an den Systemadministrator',
      hasEmail: 'Kunde mit dieser E-Mail-Adresse ist schon registriert',
      weSendInstructions:
        'Wir haben Ihnen die E-Mail mit den Anweisungen geschickt',
      successfullyVerified: 'E-Mail-Adresse wurde erfolgreich bestätigt',
      passChange: 'Passwort wurde erfolgreich geändert',
      forgotPass: 'Passwort vergessen?',
      forgotYourPass: 'Passwort vergessen?',
      resetPass: 'Setzen Sie das Passwort zurück',
      changeYourPass: 'Passwort ändern',
    },
    clients_page: {
      clients_tit: 'Kunden',
      add_client: 'Kunden hinzufügen',
      client_name: 'Name',
      clients_search: 'Suchen',
      clients_all: 'Alle Kunden',
      clients_stopped: 'Pausiert',
      clients_deleted: 'Entfernt',
      active_clients: 'Aktiviert',
      archive_clients: 'Archiviert',
      edit_icon: 'Bearbeiten',
      add_arc_icon: 'Archivieren',
      rem_arc_icon: 'Archiv entpacken',
      report_client_name: 'Name des Kunden',
      report_client_id: 'ID des Kunden',
      client_report: 'Bericht des Kunden',
      client_archive_from_text:
        'Möchten Sie wirklich den Kunden aus dem Archiv extrahieren ',
      client_archive_to_text: 'Möchten Sie wirklich den Kunden archivieren: ',
      client_archive_from_alert: ' wurde aus dem Archiv extrahiert',
      client_archive_to_alert: ' wurde zum Archiv hinzugefügt',
      show_columns: 'Statistikspalten in der Kampagnenliste anzeigen',
      nav_menu: {
        basic_settings: 'Grundlegende Einstellungen',
        reports: 'Berichte',
      },
      client_create: {
        hint1: 'Pflichtfeld',
        hint2: 'Fehler beim Laden. Dateigröße ist mehr als 1 Mb',
        hint3: 'Fehler beim Laden. Bildgröße ist mehr als 2000x2000 px',
        hint4: 'Fehler beim Laden. Ungültiges Format ',
        hint5: 'Fehler beim Laden. Ungültige URL',
        hint6: 'Kunde wurde erfolgreich ',
        hint7: 'gespeichert',
        hint8: 'hinzugefügt',
        hint9: 'Fehler beim Laden. ',
        hint10: 'Dateigröße ist mehr als 1 Mb',
        hint11: 'Datei ist nicht gefunden',
        hint12: 'Ungültiger Dateityp',
        hint13:
          'Ein Fehler ist aufgetreten. Änderungen können nicht gespeichert werden',
        client_create_tit: 'Kunden hinzufügen',
        client_load_img: 'Bild hochladen',
        client_img_link: 'Link zum Bild',
        client_img_formats: 'Erlaubte Dateitypen: png, jpeg, jpg, svg.',
        client_img_size: 'Max Größe: 1 Mb',
        client_img_maxsize: 'Max Auflösung: 2000×2000',
        client_name: 'Name *',
        client_site: 'Webseite',
        client_reports: 'Berichte',
        client_use_systemset: 'Systemeinstellungen verwenden',
        client_report_list:
          'Die Liste der verfügbaren Berichte kann nach dem Hinzufügen eines Kunden geändert werden',
        client_agency: 'Agentur',
        client_default_title: 'Neukunde',
      },
      client_edit: {
        client_edit_tit: 'Kunden hinzufügen',
        client_edit_delete_avatar: 'Entfernen',
        client_edit_reports: 'Berichte',
        client_edit_use_settings: 'Systemeinstellungen verwenden',
        day: 'Gesamtbericht',
        tech_fee: 'Gesamtbericht. Gruppiert nach tech fee',
        metrika: 'Yandex.Metrika. ',
        consolidated_report: 'Konsolidierter Bericht',
        nmi_report: 'NMI Bericht',
        integr_report: 'Integrierter Bericht',
        soc_dem_report: 'Soziodemografischer Bericht',
        geo_report: 'Geo Bericht',
        frequency_shows: 'Frequenz-Bericht',
        extended_report: 'Detaillierter Bericht',
        included: 'Aktiviert',
        turned_off: 'Deaktiviert',
        available: 'Verfügbar',
        moderation_creatives: 'Moderation von Kreativen',
        update_hand: 'Kreative manuell akzeptieren',
        update_auto: 'Kreative automatisch akzeptieren',
        categories_creatives: 'Kategorien von Kreativen',
        switch_points: 'Aktivierte Kreative werden im Tab Kategorie gezeigt',
        animation: 'Animation',
        jest: 'Brutalität',
        erotic: 'Erotik',
        link_to_image: 'Link zum Bild',
        link_to_image_link: 'Link',
        link_to_image_add: 'Ok',
        link_to_image_cancel: 'Abbrechen',
      },
      clients_none: {
        empty1: 'Sie haben keine aktiven Kunden',
        empty2: 'Sie haben keine gestoppten Kunden',
        empty3: 'Sie haben keine Kunden im Archiv',
        empty4: 'Sie haben keine entfernten Kunden',
        empty5: 'Sie haben noch keine Kunden.',
        empty6: 'Hinzufügen',
      },
      limit_groups: {
        title: 'Limitgruppen',
        title2: 'Limitgruppe',
        group: 'Die Gruppe',
        created: 'wurde erfolgreich erstellt',
        changed: 'wurde erfolgreich geändert',
        add_new: 'Neue Gruppe hinzufügen',
        no_settings:
          'Die Limitgruppen wurden für diesen Kunden nicht eingestellt',
        clear_group: 'Gruppe löschen',
        search_camp: 'Suche nach einem Unternehmen',
        show_groups: 'Gruppen anzeigen (Limite)',
        show_groups_hints:
          'Einstellungen von Limitgruppen in Kampagnen und Kreativen',
        cancel_del: 'Löschen rückgängig machen',
      },
    },
    campaigns_page: {
      camp_hint: {
        hint1: 'Daten werden nicht gespeichert, Validierungsfehler',
        hint2: '',
        hint3: '',
        hint4: '',
        hint5: '',
      },
      coef_name: 'Name des Algorithmus',
      choose_action: 'Wählen Sie die Aktion aus dem Feld aus',
      icons: 'Icons',
      popular: 'Beliebte',
      campaigns: 'Kampagnen',
      camp_search: 'In Kampagnen suchen',
      show_full_name: 'Voller Name von Werbekampagne',
      show_short_name: 'Gekürzter Name von Werbekampagne',
      create_btn: 'Kampagne hinzufügen',
      campaign_additional: {
        special: {
          title: 'Besondere Einstellungen',
          lifetime_label: 'Die Lebensdauer von Klicks',
          lifetime_help: 'Die Lebensdauer von Klicks wird in Tagen angegeben, max - 60 Tage',
        }
      },
      experimental: {
        by_default: 'Standartwert festlegen',
      },
      campaign_category: {
        "Video": "Video",
        "MS_performance_audience": "Performance-Zielgruppe von MS",
        "MS_performance_context_audience": "Performance-Kontext-Zielgruppe von MS",
        "Performance_audience_pbd": "Performance-Zielgruppe von der Platform von Big Data",
        "Performance_audience_outside_DMP": "Performance-Zielgruppe von der dritten DMP",
        "Reach_WL": "Reichweite White List",
        "Reach_Premium": "Reichweite Premium",
        "Reach_Middle": "Reichweite Middle",
        "Reach_Budget": "Reichweite Budget",
        "inn_app": "In-App",
      },
      campaigns_black_header_main: {
        act: 'Aktiviert',
        pause: 'Pausiert',
        del: 'Entfernt',
        arch: 'Archiv',
        all: 'Alle Kampagnen',
      },
      campaigns_comments: {
        title: 'Kommentare',
        add_comment_btn: 'Einen Kommentar einfügen',
        add_comment_tit: 'Einen Kommentar einfügen',
        del_comment: 'Löschen eines Kommentars',
        edit_comment: 'Kommentar bearbeiten',
        comment_deleted: 'Kommentar wurde gelöscht',
        confirm_del: 'Möchten Sie wirklich diesen',
        show_comments_part: 'Alle Kommentare anzeigen',
        hide_comments: 'Kommentare ausblenden',
        roll_up: 'Reduzieren',
        expand: 'Erweitern',
        saved: 'Gespeichert',
        save_error: 'Speicherfehler. In einer Minute wiederholen.',
        no_search: 'Nichts gefunden',
        search_placeholder: 'Einen Kommentar oder eine E-Mail eingeben',
        symbols: 'Symbole',
        empty: 'Keine Kommentare',
      },
      campaigns_header_main: {
        total: 'Allgemeine Statistik',
        name: 'Name',
        show: 'Impressionen',
        click: 'Klicks',
        spent: 'Kosten',
        budget: 'Budget',
        status: 'Status',
        cpc: 'CPC',
        cpm: 'CPM',
        ctr: 'CTR',
      },
      campaigns_status: {
        act: 'Aktiviert',
        pause: 'Pausiert',
        del: 'Entfernt',
      },
      campaigns_copy_modal: {
        title: 'Kopieren der Kampagne',
        de_start_predix_one: 'Die',
        de_start_predix_many: 'Die',
        mass_copy_success_one: 'wurde erfolgreich',
        mass_copy_success_many: 'wurden erfolgreich',
        mass_copy_in_one_client: 'in den Kunden kopiert',
        mass_copy_in_many_clients: 'in die Kunden kopiert',
        unknow_error: 'Unbekannter Kopierfehler',
        error_copy: 'Fehler beim Kopieren',
        limit_copy: 'Kopieren ist wegen eines Targetkonflikts eingeschränkt',
        select_comp:
          'Wählen Sie die Kunden aus, in die die Kampagnen kopiert werden sollen',
      },
      campaign_stat_icon: 'Statistik',
      campaign_del_icon: 'Entfernen',
      campaign_stat_modal: {
        tit: 'Statistik',
        shows: 'Impressionen',
        clicks: 'Klicks ',
        spent: 'Kosten',
        budget: 'Budget',
      },
      chart: {
        show: 'Die Grafik anzeigen',
        hide: 'Die Grafik ausblenden',
        no_stats: 'Keine Statistik verfügbar',
      },
      empty: {
        act: 'Sie haben keine aktivierte Kampagne',
        no1: 'Sie haben keine Kampagne.',
        no2: 'Hinzufügen',
        no3: 'Sie haben keine Kampagne pausiert',
        no4: 'Sie haben keine Kampagne entfernt',
        no5: 'Sie haben keine Kampagnen im Archiv',
      },
      create_campaign: {
        camp_create_hint: 'Kampagne wurde erfolgreich erstellt',
        black_header: {
          back: 'Zurück',
          set: 'Einstellungen',
          targ: 'Targeting',
        },
        newcamp_tit: 'Neue Kampagne',
        req_fill: 'Pflichtfeld',
        tit: 'Kampagne hinzufügen ',
        client: 'Kunde',
        adv_name: 'Werbekunde',
        name: 'Name *',
        categories_campaign: 'Kategorien der Kampagne',
        status: 'Status',
        period: 'Zeitraum',
        data_start: 'Startdatum',
        date_stop: 'Enddatum ',
        budget_tit: 'Budget',
        budget_text:
          'Bitte beachten Sie, dass wenn Geldmittel aufgewendet werden, wird die Kampagne gestoppt. Das Budget kann jederzeit erhöht werden.',
        budget: 'Budget',
        price: 'Kosten',
        enter: 'Bitte eingeben…',
        without_limit: 'Unbeschränkt',
        limits: 'Beschränkungen',
        clicks: 'Klicks',
        shows: 'Impressionen',
        shows_one: 'Impressionen pro Benutzer',
        limits_budget: 'Budget',
        hour: 'Stunde',
        day: 'Tag',
        hour_rub: 'Stunde',
        day_rub: 'Tag',
        all: 'Insgesamt',
        create_btn: 'OK',
        cancel_btn: 'Abbrechen',
        create_text:
          'Erstellen Sie neue Werbekampagnen mit unserer Trading Desk Plattform. Für alle Kampagnen steht Ihnen eine Vielzahl von verschiedenen Aktionen zur Verfügung. Sie können jederzeit Status von einer Kampagne sehen sowie regelmäßig Statistik und detaillierte Berichte bekommen.',
        create_text2:
          'Wenn Sie Fragen haben oder zusätzliche Informationen benötigen, lesen Sie bitte die TD',
        create_text_link: ' Dokumentation',
        ord_name: 'Name im Werbedatenbetreiber',
      },
      pretarg_text:
        'Um Targeting einzusetzen, erstellen Sie bitte diese Kampagne',
      targeting_word: 'Targeting',
      campaign_settings: {
        date_hint: {
          h1: 'Das Enddatum der Kampagne sollte länger als das Startdatum sein',
          h2: 'Das Datumsformat muss TT/MM/JJJJ sein',
          h3: '',
        },
        campaign_prompt: {
          main: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%BD%D0%B0%D1%8F-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D1%8F',
          },
          period: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4',
          },
          moreOptimisation: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D1%80%D0%B0%D1%81%D1%88%D0%B8%D1%80%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F-%D0%BE%D0%BF%D1%82%D0%B8%D0%BC%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F',
          },
          limits: {
            link: 'https://terratraf.readme.io/docs/campaigns-limits',
          },
          container: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%B9%D0%BD%D0%B5%D1%80',
          },
          optimization: {
            link: 'https://terratraf.readme.io/docs/td-campaigns-adding#%D0%BE%D0%BF%D1%82%D0%B8%D0%BC%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F',
          },
        },
        hint_for_card: {
          optim_part1: 'Um die Werbung zu starten, ',
          optim_part2: 'füllen Sie die Sektion "Optimierung" aus.',
          limit_part1: 'Setzen Sie Grenzen ',
          limit_part2: 'für das Budget und andere Optionen.',
        },
        camp_name_fied: {
          name1: '"Werbekampagne"',
          name2: '"Zeitraum"',
          name3: '"Optimierung"',
          name4: '"Beschränkungen"',
        },
        on_edit: {
          hint1: 'Die Sektion ',
          hint2: ' wurde erfolgreich gespeichert',
          hint3: 'Die Sektion wurde erfolgreich gespeichert',
        },
        enter_data_tip: 'Geben Sie die Daten ein',
        click_hint: {
          hint1: '[TT/MM/JJJJ]',
          hint2: 'Leerzeichen',
          hint3: '[Wert]',
          hint4: '[Parameter]',
          hint5: 'z.B.:: 18.12.2019 300 1.4',
          hint6: 'z.B.:: 18.12.2019 1.4',
        },
        creative_hint: 'Sie können Targets für die gesamte ',
        creative_hint2: 'Kampagne ',
        creative_hint3: 'oder für jedes Kreativ separat festlegen',
        nostat: 'Keine Statistik ist verfügbar',
        black_menu: {
          Main: 'Haupteinstellungen',
          Back: 'Zurück',
          Main_settings: 'Haupteinstellungen',
          Targeting: 'Targeting',
          Creatives: 'Kreative',
          Reports: 'Berichte',
          Settings: 'Einstellungen',
          Settings_aditional: 'Zusätzliche Einstellungen',
        },
        ListSRMethods: {
          enter_data: 'Daten eingeben',
          label: 'Methode zur Rechnungsführung von Statistik',
          CAMPAIGN_TYPE: 'nach dem Typ der Kampagne',
          COEFFICIENTS: 'nach dem Koeffizient',
          DSP_CALCULATION: 'nach den Kosten des Kunden aus DSP',
          TD_CALCULATION: 'nach den Kosten des Kunden aus TD',
          RAW_STAT: 'nach dem RAW STAT',
          MAX_FIX_CPC_MARGINALITY: 'maximale kosten pro klick',
          max_cpc: {
            label: 'Max CPC',
            hint: 'Maximale Kosten pro Klick für die Methode MAX_FIX_CPC_MARGINALITY',
          },
          marginality: {
            label: 'Marginalität, %',
            hint: 'Marginalität für die CPC-Umrechnungsmethode MAX_FIX_CPC_MARGINALITY, sollte als Prozentsatz festgelegt werden',
          },
          hidden_coef: {
            label: 'Versteckte Gebühr, %',
          },
        },
        Client: 'Kunde',
        Advertiser_name: 'Werbekunde',
        Campaign_name: 'Werbekampagne',
        tags_label: 'Tags',
        tags_placeholder: 'Daten eingeben',
        Running: 'Aktiviert',
        Paused: 'Deaktiviert',
        Edit: 'Bearbeiten',
        Period: 'Zeitraum',
        Start_date: 'Startdatum',
        End_date: 'Enddatum ',
        Optimization: 'Optimierung',
        moreOptimisation: {
          title: 'Erweiterte Optimierung',
          algoritm: 'Optimierungsalgorithmus',
        },
        Container: 'Container',
        container_block: {
          cid: 'Container-ID',
          save: 'Klick-ID (strk) bei Weiterleitung in Cookie speichern',
          additionally: 'Zusätzlich',
        },
        Budget: 'Budget',
        Budget_RAW: 'Budget RAW',
        Please_note:
          'Bitte beachten Sie, dass wenn Geldmittel aufgewendet werden, wird die Kampagne gestoppt. Das Budget kann jederzeit erhöht werden.',
        cost: 'Kosten',
        Please_enter: 'Bitte eingeben…',
        Limit_display_Adriver: 'Adriver Frequency-Cap aktivieren',
        Unlimited: 'Unbeschränkt',
        Limits: 'Beschränkungen ',
        Clicks: 'Klicks ',
        Impressions: 'Impressionen (Gesamt)',
        per_user: 'Impressionen (Benutzer)',
        Hour: 'Stunde',
        Day: 'Tag',
        Total: 'Insgesamt',
        Total_raw: 'Budget',
        additional: {
          set_tit: 'Einstellungen',
          coefficient: 'Preisparameter',
          method: 'Methode zur Berechnung der Kosten der Agentur',
          range: 'KOEFFIZIENT DER SPANNENBERECHNUNG',
          info_1: 'Koeffizienten der Spannenberechnung gelten für die Kampagnen, die die Methode "Nach Kampagnentyp" für die Statistikberechnung verwenden.',
          info_2: 'Wenn Sie möchten, dass die Statistikberechnungsmethode "Nach Kampagnentyp" automatisch auf Kampagnen in der TD-Admin angewendet wird, aktivieren Sie die Option <bold>lable - {{method}}</bold> in der Agentur',
          type: 'Typ',
          clicks: 'Klicks ',
          from: 'Von',
          to: 'Bis',
          maxToBeMax: 'Die maximale Anzahl des Bereichs muss größer als die minimale sein',
          hide_real_statistics: {
            title: 'Echte Statistik ausblenden',
            hintStrong: 'Die Kampagnenstatistik ist ausgeblendet!',
            hintSpan:
              'Um die Statistik anzuzeigen, entfernen Sie das Häkchen in dem Kästchen',
          },
          aggregation: 'Aggregation von Kampagnen',
          ddmm: 'Wählen Sie aus...',
          eg: 'z.B.:',
          ext_sour: 'Externe Quellen',
          ids: 'ID',
          id_from_ss: 'Geben Sie ID aus CC ein',
          dashboard_ids: 'Dashboard',
          reports: 'Berichte',
          use_client: 'Kundeneinstellungen verwenden',
          enabled: 'Aktiviert',
          extend_rep: 'Detaillierter Bericht',
          data: 'Daten',
          enter_date: 'Geben Sie die Daten ein',
          data_based: 'Die Daten basieren sich auf dem Gesamtbericht',
          use_special:
            'Verwenden Sie ein Sonderzeichen “ | “  als Spaltentrennzeichen',
          help_text:
            'Für die Kunden mit Adminrechten sind mehrere Einstellungen verfügbar: Festlegung von täglichen Preisparameter (für CPA-Preistyp) festlegen, Hinzufügen von externen Quellen, Verwaltung der verfügbaren Berichte usw.',
          pre_targ_tit: 'Pretargeting',
          pre_targ_main: 'Haupteinstellungen',
          pre_targ_search: 'Suchen',
          pre_targ_brand_on: 'Aktiviert',
          pre_targ_brand_off: 'Deaktiviert',
          pre_targ_brand_rot: 'Rotation',
          pre_targ_brand_rotall: 'Anzeigen auf allen Websites schalten ',
          pre_targ_brand_rotrus:
            'Anzeigen auf Websites schalten, die einen Text auf Russisch enthalten',
          pre_targ_brand_rotcat: 'Von der Anzeigenschaltung ausschließen',
          pre_targ_categories: {
            drugs_n_diseases: 'Drogen und Krankheiten',
            indecency: 'Obszönität',
            media_test: 'Arzneimittel',
            obscenity_br: 'Russischer Mat (Wortwurzeln)',
            obscenity_r: 'Russischer Mat (erweitert)',
            slang: 'Slang',
            swearing_en: 'Englischer Mat',
            swearing_ru: 'Russischer Mat (meistgebräuchlich)',
            tragedy: 'Katastrophen',
            ujc: 'Benutzer-Content',
          },
          statistics: {
            title: 'Statistik',
            text: 'Statistikspalten in der Kampagne anzeigen',
            select_all: 'Alles auswählen',
            selected: 'ausgewählt',
            of: 'von',
            save_empty_message:
              'Sie haben keine Option ausgewählt. Nach dem Speichern werden die Standardeinstellungen angezeigt',
            exit_message: 'Möchten Sie diese Seite wirklich verlassen?',
            show_columns: 'Statistikspalten in der Kreativliste anzeigen',
          },
          links: {
            copy: 'Disapprove-IDs wurden erfolgreich in die Zwischenablage kopiert',
            title: 'Links und Genehmigungen von Kreativen',
            tooltip: 'Disapprove-IDs kopieren',
            tabs: {
              links: 'Links',
              google: 'Google',
              yandex: 'Yandex',
            },
            table: {
              link: 'Navigationslink',
              pixel: 'Pixel Audit',
              pixel_additional: 'Zusätzliches Pixel Audit:',
              status: 'Status',
            },
          },
        },
        reports: {
          reports_tit: 'Berichte',
          back: 'Zurück',
          donwload: 'Herunterladen',
          see: 'Ansicht ',
          nodata: 'Für diese Periode sind keine Daten verfügbar',
          gen_report: 'Gesamtbericht',
          targ_report: 'Targeting report',
          shows_report: 'Frequenz-Bericht',
          geo_report: 'Geo Bericht',
          socdem_report: 'Soziodemografischer Bericht',
          integr_report: 'Integrierter Bericht',
          yandex_report: 'Yandex.Metrika. Konsolidierter Bericht',
          fee_report: 'Gesamtbericht. Gruppiert nach tech fee',
          hide_fromchart: 'In dem Chart ausblenden',
          show_fromchart: 'Auf dem Chart anzeigen',
          stat_tit: 'Statistik',
          sort_high: 'Aufsteigend sortieren',
          sort_low: 'Absteigend sortiere',
          sort_cancel: 'Sortierung abbrechen',
        },
        push_notifications: {
          title: 'Push-Benachrichtigungen',
          hint: 'Push-Benachrichtigungen für Kampagnenänderungen erhalten',
          hint2:
            'PUSH-Benachrichtigungen über Veränderungen von Kreativen erhalten',
          browser_push_error_1:
            'Benachrichtigungen auf Ihrer Webseite sind nicht erlaubt.',
          browser_push_error_2:
            ' Lassen Sie Benachrichtigungen in Ihren Browsereinstellungen zu.',
        },
      },
      stat_chart: {
        tit: 'Statistik',
        shows: 'Impressionen',
        clicks: 'Klicks',
      },
      camp_del_tit: 'Kampagne löschen',
      camp_is_deleted: 'gelöscht',
      camp_del_quest: 'Möchten Sie wirklich das löschen',
      camp_archive_from_text:
        'Möchten Sie wirklich die Kampagne aus dem Archiv extrahieren ',
      camp_archive_to_text: 'Möchten Sie wirklich die Kampagne archivieren ',
      camp_archive_from_alert: ' wurde aus dem Archiv extrahiert',
      camp_archive_to_alert: ' wurde zum Archiv hinzugefügt',
      copy_campaign: {
        t1: 'Einstellungen von Targets der Kampagne ',
        t1_2: 'Die Target- und Pretarget-Einstellungen des Kampagne',
        t2: ' wurden in Kampagnen kopiert',
        t3: 'Wählen Sie die Kampagnen aus, in die Sie die Targeteinstellungen kopieren möchten.',
      },
      mass_copy: 'In einen anderen Kunden kopieren',
      mass_actions_success: 'Kampagnen wurden erfolgreich',
      mass_actions_success_eng: '',
      bulk_action_alert: 'Möchten Sie wirklich die Kampagnen vom Kunden',
      client_company_alert: '',
      client_company_alert_for_del: '',
      client_company_alert_for_double:
        'Möchten Sie wirklich die Kampagnen des Kunden dublieren:',
      client_company_expect_to: 'aus dem Archiv extrahieren',
    },
    users_activity: {
      users: 'Benutzer',
      client: 'Kunde',
      organisation: 'Organisation',
      agency: 'Agentur',
    },
    users_api: {
      create_title: 'Erstellen eines API-Benutzers',
      info_1: 'Erstellen von technischen Benutzern ermöglicht den Zugang zu API2 mit einem bestimmten Satz von Methoden. Er wird für Operationen mit TD aus externen Systemen verwendet.',
      select_partners: 'Wählen Sie die Partner, zu denen Sie Zugang haben werden',
      set_groups: 'Legen Sie Gruppen für den Benutzer fest. Shalten Sie einfach Default ein',
      use_tegs: 'Verwenden Sie Tags, wenn Sie Statistiken über bestimmte Kampagnen erhalten möchten. Beachten Sie, dass Tags auch in den Kampagneneinstellungen mit demselben Namen hinzugefügt werden sollen.',
      methods_list: 'Die Liste von Methoden erlaubt es, den Zugriff auf Informationen zu steuern.',
      see_methods_info: 'S. Beschreibung von Methoden',
      methods: 'Methoden',
      token: 'Token',
      passwordHelp: 'Passwort soll mehr als 3 Zeichen erhalten',
    },
    users_page: {
      page_title: 'Benutzer',
      search_placeholder: 'Nach Login suchen',
      create_btn: 'Einen Benutzer erstellen',
      users_api: 'API-Benutzer',
      update_token: 'Token erneuern',
      token_has_update: 'Token wurde erfolgreich erneuert',
      token: 'Token',
      userDel: 'Benutzer',
      userHBD: 'wurde entfernt',
      navigation: {
        allUsers: 'Alle Benutzer',
        online: 'Online',
        statistics: 'Statistik',
      },
      table: {
        name: 'Name',
        type: 'Typ',
        login: 'Login',
        groups: 'Gruppen',
        status: 'Status',
        delete_user: 'Entfernen',
        edit_user: 'Bearbeiten',
        active_time: 'Aktivitätszeit',
        count_pages: 'Seiten',
        count_get: 'Ansichten',
        count_edit: 'Aktionen',
        hinits: {
          active_time:
            'Die Zeit der Benutzeraktivität. Nach 5 Minuten der Inaktivität wird der Benutzer inaktiv',
          count_pages: 'Die Anzahl aller geladenen Seiten',
          count_get:
            'Die Anzahl der Anfragen zur Anzeige der Einstellungen von Kampagnen, Kreativen und Targetstatistik',
          count_edit:
            'Die Anzahl der Anfragen zum Hinzufügen oder Ändern allgemeiner Einstellungen von Kampagnen oder Kreativen sowie zusätzliche Einstellungen: Tags, Kommentare, Targeting',
        },
      },
      statuses: {
        active: 'Aktiv',
        blocked: 'Blockiert',
      },
      delete_modal: {
        title: 'Benutzer löschen',
        text: 'Sind Sie sicher, dass Sie den Benutzer löschen möchten',
      },
      settings_modal: {
        create_title: 'Erstellen eines Benutzers',
        edit_title: 'Benutzer bearbeiten',
        complete_registration: 'Registrierung abschließen',
        registration_done: 'Die Registrierung wurde abgeschlossen',
        email: 'E-Mail-Adresse',
        roles_title: 'Die Rollen',
        parthner_title: 'Partner',
        select_roles: 'Rollen auswählen',
        select_parthner: 'Einen Partner auswählen',
      },
    },
    partners_page: {
      page_title: 'Partner',
      search_placeholder: 'Suchen id | xxhash | Name',
      table: {
        name: 'Name',
        type: 'Typ',
        login: 'Login',
        balance: 'Bilanz',
        delete: 'Entfernen',
        edit: 'Bearbeiten',
        enable_registration: 'Registrierung ist aktiviert. Aberkennen?',
        deny_registration: 'Registrierung verweigert. Erlauben?',
        change_registration: 'Registrierung geändert',
      },
      statuses: {
        active: 'Aktiv',
        blocked: 'Blockiert',
      },
      delete_modal: {
        title: 'Löschen',
        text: 'Möchten Sie wirklich das löschen ',
      },
    },
    agencies_page: {
      agency_title: 'Agenturen',
      agency_new: 'Neue Agentur',
      create_btn: 'Agentur hinzufügen',
      search_placeholder_agency: 'In Agenturen suchen ',
      agencies_active: 'Aktiviert',
      agencies_all: 'Alle Agenturen',
      agencies_report: 'Bericht der Agentur',
      show_audiences: 'Zielgruppen anzeigen',
      agency_none: {
        empty1: 'Sie haben keine aktiven Agenturen',
        empty2: 'Sie haben keine gestoppten Agenturen',
        empty3: 'Sie haben keine Agenturen im Archiv',
        empty4: 'Sie haben keine entfernten Agenturen',
        empty5: 'Sie haben noch keine Agenturen. ',
        empty6: 'Hinzufügen',
      },
      archive_modal: {
        title_to: 'Zum Archiv hinzufügen',
        title_from: 'Archiv entpacken',
        btn_to: 'Zum Archiv',
        btn_from: 'Extrahieren',
        text_to1: 'Möchten Sie wirklich die Agentur',
        text_to2: 'archivieren',
        text_from1: 'Möchten Sie wirklich die Agentur',
        text_from2: 'aus dem Archiv entpacken',
        alert_to1: 'Agentur',
        alert_to2: 'wurde zum Archiv hinzugefügt',
        alert_from1: 'Agentur',
        alert_from2: 'wurde aus dem Archiv extrahiert',
      },
      modal: {
        title: 'Agentur hinzufügen',
        edit_title: 'Agentur bearbeiten',
        name: 'Name',
        site: 'Webseite',
      },
      agency_create: {
        hint1: 'Agentur wurde erfolgreich ',
        hint2: 'gespeichert',
        hint3: 'hinzugefügt',
      },
      report: {
        title: 'Zielgruppe-Bericht',
        add_au: 'Zielgruppe hinzufügen',
        titleml: 'ML-Zielgruppen',
        reportAuMl: 'ML-Zielgruppe-Bericht',
        del_au: 'Entfernen der {{title}}-Zielgruppe',
        del_ask: 'Möchten Sie wirklich <bold>{{title}}</bold>-Zielgruppe entfernen?',
        hint: 'Die Statistiken dieser Kampagne werden in die ausgewählte ML-Zielgruppe',
        hint_link: 'des Agenturberichts',
        au_empty: 'Sie haben noch keine ML-Zielgruppen.<br/> Fügen Sie die erste Zielgruppe hinzu.',
        success_del: '<bold>{{title}}</bold>-Zielgruppe wurde erfolgreich entfernt',
        table_title: 'Name / Kunden-ID',
      }
    },
    project_page: {
      page_title: 'Projekt',
      main_settings: 'Haupteinstellungen',
      settings: 'Einstellungen',
      show_columns: 'Statistikspalten in der Kundenliste anzeigen',
      table: {
        name: 'Name',
        code: 'KODE',
        tech_domain: 'TECHNISCHE DOMÄNE',
        ext_domain: 'EXTERNE DOMÄNE',
      },
      errors: {
        error1: 'Fehler',
      },
      forms: {
        field: {
          required: 'Erforderlich',
          off: 'Ausgeschaltet',
          optional: 'Optional',
        },
        project_title: 'Projekt',
        code: 'KODE',
        title: 'Name',
        offer_eng: 'Die Offerte (Englische Version)',
        ext_domain: 'EXTERNE DOMÄNE',
        currency: 'Währung',
        partner: 'Partner',
        branding_title: 'Branding',
        slogan: 'Slogan',
        logo_medium: 'Logo in der Autorisierung',
        logo_small: 'Logo im Kopf',
        logo_letters: 'Brieflogo',
        background_letter: 'Briefhintergrund',
        background: 'Hintergrund',
        not_selected: 'Nicht ausgewählt',
        logotype: 'Projekt-Logo',
        background_email: 'Hintergrund für das Senden von E-Mails',
        logo_email: 'Logo E-Mail',
        custom_link_title: 'Link zum Bild',
        upload_hint:
          'Unterstützte Formate: png, jpeg, jpg, svg. Die maximale Größe beträgt 6 MB.',
        registration_title: 'Registrierung',
        registration_partner_title: 'Partner',
        registration_partner_placeholder: 'ID eingeben | xxhash | Titel',
        registration_partner_hint:
          'Wählen Sie einen Partner aus, um die Registrierungseinstellungen zu ändern',
        registration_allowed: 'Benutzerregistrierung erlaubt.',
        registration_prohibited: 'Benutzerregistrierung ist verboten.',
        registration_changed: 'Sie können die Einstellung ändern',
        registrations_changed_link: 'im Partnerbereich',
        registration_form: 'Registrierungsformular',
        registration_rules: 'Verfügbare Benutzerrollen',
        offerCustomLinkTitle: 'Dokumentlink',
        offer_label: 'Angebot',
        offer_hint: 'Erlaubte Formate: pdf.',
      },
    },
    history_changes_widget: {
      no_changes: 'Alle Änderungen sehen',
      look_all_changes: 'Es gibt keine Änderungen',
    },
    history_changes_page: {
      title: 'Geschichte der Änderungen',
      header_date: 'Datum und Uhrzeit',
      header_who: 'Wer hat sich verändert',
      header_campaign: 'Kampagne',
      header_creative: 'Kreativ',
      header_parametr: 'Änderungs-Parameter',
      header_changes: 'Was hat sich geändert',
      hide_detail: 'Änderungen ausblenden',
      show_detail: 'Änderungen anzeigen',
      full_view: 'Vollansicht',
      compact_view: 'Kompaktansicht',
      rows_limit: 'Zeilenlimit',
      all_objects: 'Alle Objekte',
      all_actions: 'Alle Aktionen',
      add: 'Hinzufügen',
      edit: 'Bearbeiten',
      copy: 'Kopieren',
      targets: 'Targets',
      campaign_start_date: 'Startdatum der Kampagne',
      campaign_end_date: 'Enddatum der Kampagne',
      objects: 'Objekte',
      inter_id_hash: 'INTERNE ID | XXHASH | NAME',
    },
    analysis_page: {
      title: 'Analyse von Kreativen',
      filters_title: 'Filter',
      placeholper_partner: 'xxhash | Titel',
      select_list: 'Aus der Liste auswählen',
      help_au: 'Nur Ziffern, mehr als ein Zeichen pro Zeile',
      help_dir:
        'Werteformat: Ziffern, Symbole, englische Buchstaben. Mehr als ein Zeichen pro Zeile, z.B.: BTW-34774-605',
      placeholder_textarea:
        'Geben Sie die Namen der Targetelementen mit ENTER ein',
      search: 'Suchen',
      header_table_nameCamp: 'Name der Kampagne',
      header_table_idCamp: 'ID der Werbekampagne',
      header_table_nameCreo: 'Name des Kreatives',
      header_table_idCreo: 'ID des Kreatives',
      au: 'Zielgruppe',
      dir: 'Direktes Geschäft',
      add_list: 'Liste hinzufügen',
      selected: 'ausgewählt',
      of: 'von',
      invalid_input_1:
        'Falsche Eingabe. Stellen Sie sicher, dass die Werte mit ENTER angegeben werden.',
      invalid_input_2:
        'Falsche Eingabe. Für Zielgruppe-Target können nur die Ziffern verwendet werden. Stellen Sie sicher, dass die Werte mit ENTER angegeben werden',
      au_shop: 'Zielgruppen von Geschäften',
    },
    search_none: {
      none_1: 'Für diese Anfrage wurden keine Ergebnisse gefunden',
      none_2:
        'Vielleicht sind ein oder mehrere Wörter in der Anfrage falsch geschrieben.',
      none_3: 'Bitte versuchen Sie es erneut.',
    },
    filter_none: {
      none_1:
        'Es gibt kein Unternehmen, das den angegebenen Parametern entspricht.',
      none_2: 'Erstellen Sie ein Unternehmen oder ändern Sie Filter.',
    },
    expand_role: {
      ex1: 'Schriftart verkleinern',
      ex2: 'Schriftart vergrößern',
      ex3: 'Minimieren',
      ex4: 'Kopieren',
    },
    date_pick: {
      days30: 'Letzte 30 Tage',
      month: 'Dieser Monat',
      week: 'Diese Woche',
      day: 'Heute',
      yesterday: 'Gestern',
      all_time: 'Für die ganze Zeit',
      select: 'Periode auswählen',
    },
    filter: {
      customize: 'Filter einrichten',
      and: 'UND',
      date__start: 'Startdatum',
      date__end: 'Enddatum',
      format__date: 'TT.MM.JJ',
    },
    profile: {
      profile_word: 'Profil',
      balance: 'Bilanz',
      balance_refill: 'BITTE GEBEN SIE DEN BETRAG EIN, ₽',
      refill: 'Guthaben aufladen',
      top_up_error:
        'Es ist ein Fehler aufgetreten, das Guthaben wurde nicht aufgeladen. Bitte versuchen Sie es später.',
      top_up_success: 'Aufgeladen war erfolgreich',
      partner_managed: 'von der Werbeagentur verwaltet',
      ava_funds: 'Verfügbares Budget',
      settings_tit: 'Einstellungen',
      main: 'Haupteinstellungen',
      app_notification_on: 'Informative Nachrichten erhalten',
      app_notification_off: 'Informative Nachrichten blockieren',
      push: {
        title: 'Push-Benachrichtigungen',
        toggle_active: 'Push-Benachrichtigungen erhalten',
        toggle_inactive: 'Keine Push-Benachrichtigungen erhalten',
        test: 'Отправить тест',
        about: 'О чем сообщать',
        bw_lists: 'Загрузка BWLists',
        new_message: 'Новые сообщения',
        refill: 'Пополнение счета',
        end_campaign: 'Завершение кампании',
        check_all: 'Отметить все',
        clear: 'Löschen',
      },
      edit: {
        edit_save: 'Profil erfolgreich gespeichert',
      },
      view: {
        title: 'Ansicht',
        theme_subtitle: 'Standard-Tabellenansicht',
        noraml_view: 'Normale Ansicht',
        extended_view: 'Erweiterte Ansicht',
        super_extended_view: 'Vollbildmodus',
        toggle_animate: 'Animation',
        animate_info:
          'Deaktivieren Sie die Interface-Animation, um die Arbeit mit Kontrollen zu beschleunigen.',
        hide_navigation_campaign: 'Navigation in Kampagnen ausblenden',
        hide_navigation_creative: 'Navigation in Kreativen ausblenden',
        navigation_info_comp:
          'Deaktivieren Sie die Interface-Navigation, wenn Sie es nicht benötigen, zwischen Kampagnen zu navigieren',
        navigation_info_creo:
          'Deaktivieren Sie die Interface-Navigation, wenn Sie es nicht benötigen, zwischen Kreativen zu navigieren',
        show_prompt_in_form: 'Tooltips in Folmularfeldern anzeigen',
        show_prompt_in_form_info:
          'Bitte denken Sie daran, beim Erstellen oder Bearbeiten von Kampagnen und Kreativen die wichtigen Felder auszufüllen',
        hide_hints: 'Hinweise ausblenden',
        hide_hints_add: 'Hinweise, die in die Trading Desk-Dokumentation zu Einstellungen von Kampagnen, Kreativen, Targets usw. führen',
      },
      safety: {
        title: 'Sicherheit',
        subtitle: 'Passwort ändern',
        old_password: 'Geben Sie das aktuelle Passwort ein',
        new_password: 'Geben Sie das neue Passwort ein',
        confirm_password: 'Wiederholen Sie das neue Passwort',
      }
    },
    alerts: {
      a1: 'iframe-Kode wurde in die Zwischenablage kopiert',
      a2: 'HTML-Code wurde in die Zwischenablage kopiert',
      a3: 'erfolgreich in Zwischenablage kopiert copied',
      copied: 'Kopiert',
      view_mode: 'Anzeigemodus.',
      view_mode_cont: ' Sie können die Seiten nicht bearbeiten',
      a4: 'Vorschau-Kode wurde erfolgreich kopiert',
    },
    targetings: {
      saveblock: {
        hint1: 'Die Sektion ',
        hint2: ' wurde erfolgreich gespeichert',
        hint3: 'Die Sektion wurde erfolgreich gespeichert',
      },
      hide: 'Hide',
      uncover: 'Anzeigen',
      show_statictics: 'Statistik anzeigen',
      show: 'Anzeigen · ',
      all: 'Alle',
      total: 'Insgesamt',
      custom: 'Konfiguriert',
      search: 'Suchen',
      use_set: 'Die Einstellungen von Targeting ',
      use_set1: 'der Kampagne sind verwendet',
      use_set2: 'des Creatives sind verwendet ',
      use_set_creo: 'Ihre Einstellungen für Targeting verwenden ',
      use_set_modal_creo:
        'Einstellungen von Kampagnentargeting nach der Targetentfernung verwenden',
      stat_not_going: 'Für dieses Target wird keine Statistik gesammelt',
      reset_target_settings: 'Möchten Sie wirklich Target-Einstellungen in der Kampagne zurücksetzen',
      stat_unavaiable: 'Targetstatistik ist nicht verfügbar',
      add_locations: 'Ort hinzufügen',
      add_list: 'Liste hinzufügen',
      update_guide: 'Referenzbook aktualisieren',
      down_stat: 'Statistik herunterladen',
      update_stat: 'Statistik aktualisieren',
      update_au: 'Zielgruppe aktualisieren',
      have_camp_set_tit: 'Elemente haben Kampagnen-Einstellungen',
      have_camp_set:
        'Aktivieren Sie "Ihre eigenen Einstellungen für Targeting von Kreativen verwenden", um Elemente bearbeiten zu können. Wenn Sie die Kampagnen-Einstellungen später wieder verwenden möchten, schalten Sie Ihre Einstellungen aus',
      no_list: {
        general: 'Sie haben noch keine Katalogartikel hinzugefügt',
        city: 'Sie haben keine Standorte in der Liste. Klicken Sie zum Hinzufügen ',
      },
      noselected: 'An ausgewählten Elementen anzeigen',
      selected: 'Überall außer ausgewählten Elementen anzeigen',
      noselectedprompt:
        'Wählen Sie Targetelemente aus, wenn Sie die Werbung auf sie richten möchten',
      selectedprompt:
        'Benutzen Sie diese Option, wenn Sie die Schaltung von Anzeigen auf diesen Elementen ausschließen möchten. Wählen Sie diese Elemente in der Liste aus',
      no_data: 'Für diese Periode ist keine Statistik verfügbar',
      statistic_off: 'Statistik deaktiviert',
      no_statistic: 'Für dieses Target wird keine Statistik gesammelt',
      error_statistics: 'Es ist ein Datenladefehler aufgetreten',
      error_statistics_update: 'Aktualisieren',
      add_elements: 'Elemente hinzufügen',
      clear_target: 'Target entfernen',
      list_copy: 'Die Liste wird in die Zwischenablage kopiert',
      text_copy: 'Der Text wird in die Zwischenablage kopiert',
      there_are_changes: 'Die Änderungen wurden eingetragen',
      copy_modal: {
        campaign: 'Kopieren der Target-Einstellungen',
        creo: 'Kopieren der Kreativen-Targets',
      },
      prompt: {
        items: {
          source: {
            text: '“Quellen” Target ist ein obligatorisches Feld.\n\nBitte aktivieren Sie Quelle und füllen Sie das Feld BID - CPC/CMP/CPA aus.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B8%D1%81%D1%82%D0%BE%D1%87%D0%BD%D0%B8%D0%BA%D0%B8',
          },
          geo_id: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D1%8B-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0',
          },
          city: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B3%D0%B5%D0%BE-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D1%8B-%D0%B8-%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D0%B0',
          },
          site_domain: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B4%D0%BE%D0%BC%D0%B5%D0%BD%D1%8B',
          },
          site_uri: {
            link: 'https://terratraf.readme.io/docs/td-target-types#url',
          },
          au: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8',
          },
          os: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%8B',
          },
          browser: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D1%8B',
          },
          browser_lang: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%8F%D0%B7%D1%8B%D0%BA-%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D1%84%D0%B5%D0%B9%D1%81%D0%B0-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B0',
          },
          isp: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%BE%D0%B2%D0%B0%D0%B9%D0%B4%D0%B5%D1%80%D1%8B',
          },
          app: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F',
          },
          private_deals: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D1%80%D1%8F%D0%BC%D1%8B%D0%B5-%D1%81%D0%B4%D0%B5%D0%BB%D0%BA%D0%B8',
          },
          viewability: {
            link: 'https://terratraf.readme.io/docs/td-target-types#viewability',
          },
          au_bin_8: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-aidata-8',
          },
          au_bin_15: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-uid-20-15',
          },
          au_bin_20: {
            link: 'https://terratraf.readme.io/docs/td-target-types#bin_au-%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%B0-20',
          },
          au_bin_21: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%BE%D0%BA-%D0%BF%D0%BE-%D1%81%D1%81%D0%BF-uid-21',
          },
          uniq_show_creative: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E',
          },
          uniq_show_creative_hour: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D1%87%D0%B0%D1%81',
          },
          uniq_show_creative_day: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D0%B4%D0%B5%D0%BD%D1%8C',
          },
          days_of_week: {
            text: 'Sie können nur eines von zwei vorgeschlagenen Targets nach Wochentagen verwenden.\n\nTargets unterscheiden sich durch die Möglichkeit des Zeitzonenwechsels.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B4%D0%BD%D1%8F%D0%BC-%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D0%B8-%C2%B7-utc3',
          },
          days_of_week_user: {
            text: 'Sie können nur eines von zwei vorgeschlagenen Targets nach Wochentagen verwenden.\n\nTargets unterscheiden sich durch die Möglichkeit des Zeitzonenwechsels.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B4%D0%BD%D1%8F%D0%BC-%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D0%B8-%C2%B7-utc-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F',
          },
          hours_of_day: {
            text: 'Sie können nur eines von zwei vorgeschlagenen Targets für die Tageszeit verwenden.\n\nTargets unterscheiden sich durch die Möglichkeit des Zeitzonenwechsels.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8-%D0%B4%D0%BD%D1%8F-%C2%B7-utc3',
          },
          hours_of_day_user: {
            text: 'Sie können nur eines von zwei vorgeschlagenen Targets für die Tageszeit verwenden.\n\nTargets unterscheiden sich durch die Möglichkeit des Zeitzonenwechsels.',
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8-%D0%B4%D0%BD%D1%8F-%C2%B7-utc-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F',
          },
          uniq_show_camp_hour: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D1%87%D0%B0%D1%81',
          },
          uniq_show_camp_day: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E-%D0%B2-%D0%B4%D0%B5%D0%BD%D1%8C',
          },
          uniq_show_camp: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D1%83-%D0%BF%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8E',
          },
          adgroup: {
            link: 'https://terratraf.readme.io/docs/td-target-types#adgroup-%C2%B7-%D0%BF%D1%80%D0%B5%D1%81%D0%B5%D1%82%D1%8B-google',
          },
          page_context: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%BA%D1%81%D1%82-%D1%81%D0%B0%D0%B9%D1%82%D1%8B',
          },
          keywords: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%B2%D1%8B%D0%BC-%D1%81%D0%BB%D0%BE%D0%B2%D0%B0%D0%BC-%D0%B8-%D1%84%D1%80%D0%B0%D0%B7%D0%B0%D0%BC',
          },
          actions: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%81%D1%82%D0%B0%D1%82%D0%B8%D1%81%D1%82%D0%B8%D0%BA%D0%B0-%D0%BF%D0%BE-%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D1%8F%D0%BC-%D0%B2-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D0%B5',
          },
          video_type: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D0%BF%D0%BE-%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D1%83-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE-%D1%80%D0%B5%D0%BA%D0%BB%D0%B0%D0%BC%D1%8B',
          },
          video_size: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%80%D1%8B-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE',
          },
          socdem: {
            link: 'https://terratraf.readme.io/docs/td-target-types#%D1%81%D0%BE%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE-%D0%B4%D0%B5%D0%BC%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D1%82%D0%B0%D1%80%D0%B3%D0%B5%D1%82%D0%B8%D0%BD%D0%B3',
          },
          sex: {
            text: 'Wählen Sie die potenziellen Werbungsempfänger.\n\nUm sowohl Männer als auch Frauen zu erreichen, wählen Sie beide aus.',
          },
          age: {
            text: 'Sie können das Alter aus der vorgeschlagenen Varianten wählen. Die Altersspanne beträgt 5 Jahre.',
          },
        },
        more_btn: 'Mehr Informationen',
      },
      add_popap_tit: {
        t1: 'Viewability hinzufügen',
        t2: 'Nach der Nummer der Schau des Kreatives an den Benutzer',
        t3: 'Nummer der Schau des Kreatives pro Stunde  hinzufügen',
        t4: 'Nummer der Schau des Kreatives pro Tag  hinzufügen',
        t5: 'Nach der Tageszeit · UTC +3 hinzufügen',
        t6: 'Nach der Tageszeit des Kunden hinzufügen',
        t7: 'Zielgruppen hinzufügen',
        t8: 'Browsersprache hinzufügen',
        t9: 'Liste hinzufügen',
        t10: 'Set hinzufügen',
        t11: 'Wochentage hinzufügen',
        t12: 'Sozialdemographischer Targeting hinzufügen',
        t13: '',
        t14: '',
        t15: '',
        t16: 'Nummer der Schau der Kampagne hinzufügen',
        t17: 'Nummer der Schau der Kampagne pro Stunde hinzufügen ',
        t18: 'Nummer der Schau der Kampagne pro Tag hinzufügen',
        geo_id: 'Liste hinzufügen',
        add_popap_smalltit: {
          t1: 'Domains',
          t2: 'Zielgruppen',
          t3: 'Direktgeschäfte',
          t4: 'Anwendungen',
          t5: 'Internetanbieter',
          t6: 'Browsersprache',
          t7: 'AdGroup Google Presets',
        },
        add_popap_prompt: {
          title: {
            au: 'Nur die Nummern, mehr als 1 Symbol in der Zeile',
            private_deals:
              'Wertformat: Zahlen, Symbole, englische Buchstaben. Mehr als 1 Symbol in der Zeile, z.B.: BTW-34774-605',
            browser_lang:
              'Nur englische Kleinbuchstaben, nicht mehr als 5 Zeichen pro Zeile.',
            adgroup:
              'Nur englische Kleinbuchstaben und Zahlen, mehr als 1 Zeichen pro Zeile',
            geo_raw: {
              partOne: 'Zuerst Landeskennzahl und Doppelpunkt (',
              partTwo:
                '), dann die Stadt (in lateinischen Buchstaben), jede in einer neuen Zeile. Die Groß- und Kleinschreibung hat keine Bedeutung, sehen ',
              linkOne: 'Handbuch',
              linkTwo: 'Sie die Liste der Städte',
            },
            city: {
              parOne:
                'Sie können mehrere geografische Regionen auf einmal zur Liste hinzufügen. Fügen Sie die Geonamen in Russisch mit Zeilenumbruch hinzu. Eine Liste der Namen von Regionen und Städten finden Sie im ',
              partTwo: ' Verzeichnis.',
              errorPartOne: 'Folgende Geos werden nicht hinzugefügt: ',
              errorPartTwo:
                'Die Städtenamen müssen vollständig mit den Namen im ',
              errorPartThree: ' übereinstimmen',
              error_dubl: 'Im Verzeichnis finden Sie die Kopien der Namen: ',
            },
            geo_id: {
              parOne:
                'Sie können mehrere geografische Regionen auf einmal zur Liste hinzufügen. Fügen Sie die Geonamen in Russisch mit Zeilenumbruch hinzu. Eine Liste der Namen von Regionen und Städten finden Sie im ',
              partTwo: ' Verzeichnis.',
              errorPartOne: 'Folgende Geos werden nicht hinzugefügt: ',
              errorPartTwo:
                'Die Städtenamen müssen vollständig mit den Namen im ',
              errorPartThree: ' übereinstimmen',
              error_dubl: 'Im Verzeichnis finden Sie die Kopien der Namen: ',
              sever_match: 'Sie können mehrere Ländern, Regionen und Städte zu der Liste hinzufügen. Fügen Sie die Geos mit dem Zeilenumbruch hinzu. Die Liste der Regionen und Städten finden Sie im ',
            },
            general: 'OTHER',
            all_app: 'ALL APP',
          },
          text: {
            private_deals: 'z.B.: ABC-12345-123',
            browser_lang: 'z.B.:  kz, by.',
          },
        },
      },
      source: {
        tit: 'Quellen',
        name: 'Name',
      },
      geo_id: {
        prompt:
          'Wählen Sie die Regionen aus, wo die Werbung gezeigt werden soll. Bitte beachten Sie, dass Sie mit der Auswahl einer Region alle darin enthaltenen Städte und Dörfer auswählen.',
        quickChoice: 'Schnellauswahl',
        search: 'Bitte geben Sie den Namen einer Stadt oder Region ein...',
        without: 'außer',
        selected: 'Ausgewählte Orte:',
        showStatistics: 'Statistik anzeigen',
        no_statistics: 'Keine Statistik verfügbar',
        matches: 'Treffer',
        matches4: 'Treffer',
        matches5: 'Treffer',
        added_matches: 'Es wurden Treffer zu Ihrer Suchanfrrage gefunden. Wählen Sie bitte Geos aus und fahren Sie mit dem Hinzufügen der Liste fort.',
      },
      categories: {
        prompt:
          'Wählen Sie Interessen aus den vorgeschlagenen Varianten aus.Bitte beachten Sie, dass Sie durch die Auswahl des übergeordneten Segments alle Kategorien auswählen, die in diesem Segment enthalten sind.',
        search: 'Geben Sie den Namen der Kategorie',
        selected: 'Ausgewählte Interessen:',
      },
      city: {
        t1: 'An ausgewählten Orten anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Orten',
        t3: 'Verwenden Sie diese Option, wenn Sie zur Liste der Städte wechseln möchten: zum Beispiel Moskau und Region Moskau. Wichtig: Bitte geben Sie die Städte des Landes an, das in den Ziel-GEO-Ländern angegeben ist',
        t4: 'Listen Sie die Städte auf, in die die Rotation nicht durchgeführt werden soll. Vergessen Sie nicht, das zu rotierende Land im GEO-Targeting anzugeben: Länder',
        t5: 'Standorte hinzufügen',
      },
      geo: {
        t1: 'In ausgewählten Ländern anzeigen',
        t2: 'Überall anzeigen, außer ausgewählten Ländern',
        t3: 'Bitte benutzen Sie es, wenn Sie die Werbung in ausgewählten Ländern zeigen möchten.\n Bitte beachten Sie, dass Targeting ein Pflichtfeld ist. Wählen Sie die Länder, in denen sich die Städte befinden, die Sie interessieren.',
        t4: 'Bitte benutzen Sie es, wenn Sie die Werbung in ausgewählten Ländern ausschließen möchten.\n Bitte beachten Sie, wenn Sie kein Land gewählt haben, wird die Werbung weltweit angezeigt.',
      },
      geo_raw: {
        t1: 'An ausgewählten Orten anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Orten',
        t3: 'Verwenden Sie diese Option, wenn Sie zur Liste der Städte wechseln möchten: zum Beispiel Moskau und Region Moskau. Wichtig: Bitte geben Sie die Städte des Landes an, das in den Ziel-GEO-Ländern angegeben ist',
        t4: 'Listen Sie die Städte auf, in die die Rotation nicht durchgeführt werden soll. Vergessen Sie nicht, das zu rotierende Land im GEO-Targeting anzugeben: Länder',
        t5: 'Standorte hinzufügen',
      },
      keywords: {
        t1: 'Nach Wörtern und Schlüsselphrasen anzeigen',
        t2: 'Nach Wörtern und Schlüsselphrasen nicht anzeigen',
        t3: 'Bitte fügen Sie ein oder mehrere Sets mit Wörtern und Schlüsselphrasen hinzu, für die die Impressionen angezeigt werden sollen. Das System wird nach einer Übereinstimmung der Wörter und der gesamten Phrase suchen',
        t4: 'Zählen Sie die verbotenen Wörter und Schlüsselphrasen in dem Set auf, für die keine Impressionen angezeigt werden sollen. Wenn die Wörter und Phrasen übereinstimmen, wird der Kreativ nicht angezeigt',
        t5: 'Set hinzufügen',
        t8: 'Set ändern',
        t9: 'Set entfernen',
        t10: 'Nach Schlüsselwörtern und Phrasen',
        t11: 'Bitte geben Sie Wörter oder Schlüsselphrasen getrennt durch Komma ein',
        t12: 'Liste der Wörter und Phrasen',
        t13: 'Set von Wörtern und Phrasen ansehen',
        t14: 'Wort- und Phrasenliste ändern',
        t15: 'Liste kopieren',
        t16: 'Name',
        t17: 'Ok',
        t18: 'Abbrechen',
      },
      os: {
        t1: 'Auf ausgewähltem Betriebssystem anzeigen',
        t2: 'Überall anzeigen außer ausgewähltem Betriebssystem',
        t3: 'Wählen Sie aus der vorgeschlagenen Liste verschiedener Betriebssysteme nur diejenigen aus, für die dieser RK ausgelegt ist. Und die Eindrücke werden auf die Benutzer des ausgewählten Betriebssystems ',
        t4: 'Verwenden Sie diese Option, wenn Sie Impressionen auf den installierten Betriebssystemen des Benutzers ausschließen möchten. Es genügt, sie in der Liste auszuwählen ',
        t5: 'Betriebssysteme hinzufügen',
      },
      browser: {
        t1: 'In ausgewählten Browsern anzeigen',
        t2: 'Überall anzeigen außer in ausgewählten Browsern',
        t3: 'Wählen Sie aus der vorgeschlagenen Liste von Browsern nur diejenigen aus, für die dieser RK entwickelt wurde. Und die Impressionen sind für Benutzer der ausgewählten Browser',
        t4: 'Verwenden Sie diese Option, wenn Sie Impressionen in den Browsern bestimmter Benutzer ausschließen möchten. Es genügt, sie in der Liste auszuwählen ',
        t5: 'Browser hinzufügen',
      },
      browser_lang: {
        t1: 'In ausgewählten Sprachen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Sprachen',
        t3: 'Die Anzeige wird nur den Benutzern angezeigt, deren Sprache der Browseroberfläche mit einem der Elemente übereinstimmt, die Sie in der Zielliste ausgewählt haben',
        t4: 'Die Anzeige geht an alle Benutzer außer denen, deren Browser-Oberflächensprache Sie in der Zielliste ausgewählt haben',
        t5: 'Sprachen hinzufügen',
      },
      isp: {
        t1: 'Bei ausgewählten Anbietern anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Anbietern',
        t3: 'Die Anzeige geht nur an diejenigen Benutzer, die die Dienste der in der Zielliste ausgewählten Internet-Provider nutzen',
        t4: 'Die Anzeige geht an alle Benutzer, mit Ausnahme derer, die die Dienste der in der Zielliste ausgewählten Internet-Provider nutzen',
        t5: 'Anbieter hinzufügen',
      },
      au_bin_8: {
        t1: 'Bei ausgewählten Zielgruppen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Zielgruppen',
        t3: 'Verwenden Sie diese Option, wenn Sie "Aidata"-Zielgruppen aus der Liste hinzufügen möchten, um Ihre Werbekampagne anzuzeigen. Die Impression geht nur an die Zielgruppen, die in der Zielliste ausgewählt sind ',
        t4: 'Die Impression geht an alle Benutzer des Clients, mit Ausnahme derer, die Sie in der Zielliste auswählen',
        t5: 'Zielgruppen hinzufügen',
      },
      au_bin_15: {
        t1: 'Bei ausgewählten Zielgruppen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Zielgruppen',
        t3: 'Verwenden Sie diese Option, wenn Sie "UID 2.0"-Zielgruppen aus der Liste hinzufügen möchten, um Ihre Werbekampagne anzuzeigen. Die Impression geht nur an die Zielgruppen, die in der Zielliste ausgewählt sind ',
        t4: 'Die Impression geht an alle Benutzer des Clients, mit Ausnahme derer, die Sie in der Zielliste auswählen',
        t5: 'Zielgruppen hinzufügen',
      },
      au_bin_20: {
        t1: 'Bei ausgewählten Zielgruppen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Zielgruppen',
        t3: 'Verwenden Sie diese Option, wenn Sie Zielgruppen von Kundenbenutzern aus der Liste hinzufügen müssen, um eine Werbekampagne anzuzeigen. Die Impression geht nur an die Zielgruppen, die in der Zielliste ausgewählt sind ',
        t4: 'Die Impression geht an alle Benutzer des Clients, mit Ausnahme derer, die Sie in der Zielliste auswählen',
        t5: 'Zielgruppen hinzufügen',
      },
      au_bin_21: {
        t1: 'Bei ausgewählten Zielgruppen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Zielgruppen',
        t3: 'Verwenden Sie diese Option, wenn Sie Benutzern in bestimmten Gruppen Anzeigen zeigen möchten. Fügen Sie Zielgruppen-IDs hinzu und Impressionen werden nur auf ihnen angezeigt ',
        t4: 'Die Impression geht an alle Nutzer, mit Ausnahme der Zielgruppen, die Sie der Liste hinzufügen',
        t5: 'Zielgruppen hinzufügen',
      },
      app: {
        t1: 'In ausgewählten Anwendungen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Anwendungen',
        t3: 'Verwenden Sie diese Option, wenn Sie eine Liste von Anwendungen angeben möchten, in denen Anzeigen angezeigt werden. Fügen Sie der Liste bekannte Anwendungs-IDs hinzu ',
        t4: 'Wählen Sie aus, ob Sie in allen Apps zielgerichtete Anzeigen schalten möchten, mit Ausnahme der App-IDs, die Sie der Liste hinzufügen',
        t5: 'Anwendungen hinzufügen',
      },
      private_deals: {
        t1: 'Bei bestimmten Angeboten anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Deals',
        t3: 'Verwenden, wenn Sie eine Deal-ID haben. Die Rotation erfolgt nach zuvor vereinbarten Parametern (Preis, Format usw.) ',
        t4: 'Verwenden Sie, wenn Sie alles außer bestimmten Platzierungen von Publishern aufgrund direkter Vereinbarungen mit ihm einlösen möchten',
        t5: 'Angebote hinzufügen',
      },
      viewability: {
        t1: 'Bei bestimmter Sichtbarkeit anzeigen',
        t2: 'Überall anzeigen außer bei bestimmter Sichtbarkeit',
        t3: 'Verwenden, wenn Sie den Prozentsatz der Blocksichtbarkeit einstellen möchten, den SSP beim Drehen des RC verwendet',
        t4: 'Wählen Sie aus, ob Sie Impressionen zählen möchten, wenn die Sichtbarkeit des Blocks keinem der in der Zielliste ausgewählten Sichtbarkeitsprozentsätze entspricht',
        t5: 'Sichtbarkeit hinzufügen',
      },
      uniq_show_creative: {
        t1: 'Nach bestimmter Nummer anzeigen',
        t2: 'Überall anzeigen außer einer bestimmten Nummer',
        t3: 'Das Creative wird dem Nutzer nicht öfter als so oft angezeigt, wie es im Ziel ausgewählt wurde',
        t4: 'Die Impression geht an alle Nutzer, aber die ausgewählten Impressionszahlen werden nicht angezeigt',
        t5: '',
      },
      uniq_show_creative_hour: {
        t1: 'Nach bestimmter Nummer anzeigen',
        t2: 'Überall anzeigen außer einer bestimmten Nummer',
        t3: 'Das Creative wird dem Nutzer nicht öfter als so oft pro Stunde angezeigt, wie es im Ziel ausgewählt wurde',
        t4: 'Die Impression geht an alle Nutzer, aber die ausgewählten Impressionszahlen werden nicht für jede Stunde angezeigt',
      },
      uniq_show_creative_day: {
        t1: 'Nach bestimmter Nummer anzeigen',
        t2: 'Überall anzeigen außer einer bestimmten Nummer',
        t3: 'Das Creative wird dem Nutzer nicht öfter als so oft pro Tag angezeigt, wie es im Ziel ausgewählt wurde',
        t4: 'Die Impression geht an alle Nutzer, aber die ausgewählten Impressionszahlen werden nicht jeden Tag angezeigt',
        t5: '',
      },
      days_of_week: {
        t1: 'An bestimmten Tagen anzeigen',
        t2: 'Überall anzeigen außer an bestimmten Tagen',
        t3: 'Die Anzeige wird nur ausgeführt, wenn der Wochentag in der Zeitzone UTC + 3 den von Ihnen in der Zielliste ausgewählten Einträgen entspricht',
        t4: 'Die Anzeige wird nur ausgeführt, wenn der aktuelle Wochentag in der Zeitzone UTC + 3 keinem der in der Liste angegebenen Ziele entspricht',
        t5: '',
      },
      days_of_week_user: {
        t1: 'An bestimmten Tagen anzeigen',
        t2: 'Überall anzeigen außer an bestimmten Tagen',
        t3: 'Die Anzeige wird nur ausgeführt, wenn der Wochentag in der Zeitzone des Benutzers mit den von Ihnen in der Zielliste ausgewählten Elementen übereinstimmt',
        t4: 'Die Anzeige wird nur ausgeführt, wenn der aktuelle Wochentag in der Zeitzone des Benutzers keinem der in der Liste angegebenen Ziele entspricht',
        t5: '',
      },
      hours_of_day: {
        t1: 'Zu einer bestimmten Zeit anzeigen',
        t2: 'Überall anzeigen außer zu bestimmten Zeiten',
        t3: 'Verwenden, wenn Sie die Rotation für verschiedene Tageszeiten gemäß UTC + 3 Zeitzone anpassen möchten',
        t4: 'Verwenden Sie diese Option, wenn Sie die Rotation für alle Zeiträume des Tages gemäß der Zeitzone UTC + 3 verbieten möchten, mit Ausnahme derjenigen, die Sie der Liste hinzugefügt haben',
        t5: 'Nach Tageszeit hinzufügen · UTC +3',
      },
      hours_of_day_user: {
        t1: 'Zu einer bestimmten Zeit anzeigen',
        t2: 'Überall anzeigen außer zu bestimmten Zeiten',
        t3: 'Die Anzeige wird nur ausgeführt, wenn die Zeit in der Zeitzone, in der sich der Benutzer befindet, mit den von Ihnen in der Zielliste ausgewählten Einträgen übereinstimmt',
        t4: 'Die Anzeige wird nur ausgeführt, wenn die aktuelle Uhrzeit in der Zeitzone, in der sich der Benutzer befindet, mit keinem der in der Liste angegebenen Ziele übereinstimmt',
        t5: 'Nach Uhrzeit UTC-Benutzer hinzufügen',
      },
      site_domain: {
        t1: 'Auf ausgewählten Domains anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Domänen',
        t3: 'Verwenden, wenn Sie zu bestimmten Domains wechseln möchten, die Sie der Liste hinzufügen',
        t4: 'Verwenden Sie diese Option, wenn Sie die Rotation auf bestimmten Domains ausschließen möchten. Die Anzeige wechselt zu allen Arten von Domänen, mit Ausnahme der in der Zielliste ausgewählten ',
        t5: 'Domains hinzufügen',
      },
      site_uri: {
        t1: 'Auf ausgewählten URLs anzeigen',
        t2: 'Überall anzeigen außer ausgewählten URLs',
        t3: 'Verwenden Sie diese Option, wenn Sie Ihre Werbekampagne auf bestimmte Domains oder auf alle URLs mit vollständigen Links zu Websites ausweiten möchten. Fügen Sie sie der Liste hinzu ',
        t4: 'Verwenden Sie diese Option, wenn Sie die Rotation der Liste der hinzugefügten URLs verhindern möchten',
        t5: 'URL hinzufügen',
      },
      adgroup: {
        t1: 'Auf ausgewählten Voreinstellungen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Presets',
        t3: 'Die Anzeige geht nur an die Benutzer, die den in der Zielliste ausgewählten Google-Voreinstellungen entsprechen',
        t4: 'Die Anzeige geht an alle Benutzer außer denen, die unter die Voreinstellungen fallen, die Sie in der Zielliste ausgewählt haben',
      },
      page_context: {
        t1: 'In ausgewählten Kontexten anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Kontexten',
        t3: 'Die Anzeige geht nur zu den Sites, deren kontextueller Inhalt mit einer der Wortgruppen übereinstimmt, die Sie in der Zielliste ausgewählt haben',
        t4: 'Die Anzeige wird auf allen Sites angezeigt, außer auf denen, deren kontextueller Inhalt den ausgewählten Wortgruppen in der Zielliste entspricht. Ermöglicht das Ausschließen unerwünschter Inhalte ',
        t5: 'Set hinzufügen',
      },
      socdem: {
        t1: 'Bei ausgewählten Einstellungen anzeigen',
        t2: 'Überall anzeigen außer den ausgewählten Einstellungen',
        t3: 'Verwenden Sie diese Option, wenn Sie Nutzern Anzeigen basierend auf ihrem Geschlecht, Alter und Einkommen zeigen möchten. Wählen Sie Einstellungen aus der Liste',
        t4: 'Verwenden Sie diese Option, wenn Sie Anzeigen für alle Nutzer schalten möchten, mit Ausnahme von Nutzern, die nicht für Alter oder Geschlecht geeignet sind Es reicht aus, Einstellungen aus der Liste hinzuzufügen und es erfolgt keine Rotation für eine bestimmte Benutzergruppe',
      },
      video_type: {
        t1: 'Bei ausgewählten Formaten anzeigen',
        t2: 'Überall anzeigen außer den ausgewählten Formaten',
        t3: 'Wählen Sie für Videoanzeigen aus der Liste der verfügbaren Formate aus, die zum Bewerben Ihres Videocontents geeignet sind.',
        t4: 'Verwenden Sie diese Option, wenn Sie Impressionen bei bestimmten Videoanzeigenformaten ausschließen möchten. Es genügt, sie in der Liste auszuwählen. ',
        t5: 'Formate hinzufügen',
      },
      uniq_show_camp_hour: {
        t1: 'Nach bestimmter Nummer anzeigen',
        t2: 'Überall anzeigen außer einer bestimmten Nummer',
        t3: 'Kampagnen-Creatives werden dem Nutzer nicht häufiger als die im Ziel ausgewählte Anzahl pro Stunde angezeigt',
        t4: 'Die Impression geht an alle Nutzer, aber die ausgewählten Impressionszahlen werden nicht für jede Stunde angezeigt',
        t5: 'Kampagnen-Impressionsanzahl pro Stunde hinzufügen',
      },
      uniq_show_camp_day: {
        t1: 'Nach bestimmter Nummer anzeigen',
        t2: 'Überall anzeigen außer einer bestimmten Nummer',
        t3: 'Kampagnen-Creatives werden dem Nutzer nicht öfter als so oft pro Tag angezeigt, wie sie im Ziel ausgewählt wurden',
        t4: 'Die Impression geht an alle Nutzer, aber die ausgewählten Impressionszahlen werden nicht jeden Tag angezeigt',
        t5: 'Kampagnen-Impressionsanzahl pro Tag hinzufügen',
      },
      uniq_show_camp: {
        t1: 'Nach bestimmter Nummer anzeigen',
        t2: 'Überall anzeigen außer einer bestimmten Nummer',
        t3: 'Kampagnen-Creatives werden dem Nutzer höchstens so oft angezeigt, wie im Ziel ausgewählt',
        t4: 'Die Impression geht an alle Nutzer, aber die ausgewählten Impressionszahlen werden nicht angezeigt',
        t5: '',
      },
      au: {
        enter_au: 'Geben Sie die Zielgruppen ein',
        add_several: 'Sie können mehrere Zielgruppen zur Liste durch Zeilenumbruch hinzufügen. Fügen Sie die Nummer der Zielgruppe mithilfe von Zahlen hinzu.',
        following_au: 'Folgende Zielgruppen werden nicht hinzugefügt:',
        au_numbers: 'Nummer der Zielgruppen wurden nicht gefunden, aktualisieren Sie das Referenzbuch',
      },
      au_bin_info: {
        t1: 'Bei ausgewählten Zielgruppen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Zielgruppen',
        t3: 'Verwenden Sie diese Option, wenn Sie Benutzern in bestimmten Gruppen Anzeigen zeigen möchten. Fügen Sie Zielgruppen-IDs hinzu und Impressionen werden nur auf ihnen angezeigt ',
        t4: 'Die Impression geht an alle Nutzer, mit Ausnahme der Zielgruppen, die Sie der Liste hinzufügen',
        t5: 'Zielgruppen hinzufügen',
      },
      au_bin_lada: {
        t1: 'Bei ausgewählten Zielgruppen anzeigen',
        t2: 'Überall anzeigen außer ausgewählten Zielgruppen',
        t3: 'Verwenden Sie diese Option, wenn Sie Benutzern in bestimmten Gruppen Anzeigen zeigen möchten. Fügen Sie Zielgruppen-IDs hinzu und Impressionen werden nur auf ihnen angezeigt ',
        t4: 'Die Impression geht an alle Benutzer des Clients, mit Ausnahme derer, die Sie in der Zielliste auswählen',
        t5: 'Zielgruppen hinzufügen',
      },
      limits: {
        title: 'Limite',
        set_limits: 'Limite setzen',
        del_limits: 'Limite entfernen',
        del_set: 'Einstellungen zurücksetzen',
        del_set_limit: 'Einstellungen von Limiten entfernen',
      },
    },
    creative: {
      screenshot: {
        hint: 'Beim Erstellen eines neuen Screenshots wird der alte Screenshot durch einen neuen ersetzt',
        screen: 'Screenshot',
        refresh: 'Status aktualisieren',
        create: 'Screenshot erstellen',
        pending: 'Wartet auf Bearbeitung',
        sended: 'Geshickt zur Bearbeitung',
        processing: 'Bearbeitung',
        error: 'Erstellungsfehler',
        wait: 'Dieser Prozess wird einige Zeit dauern',
        download: 'Herunterladen'
      },
      moderation: {
        statuses: {
          approved: 'Bestätigt',
          disapproved: 'Abgelehnt',
          on_moderation: 'In Moderation',
          not_checked: 'In Bearbeitung',
          wait_sending: 'Bereit zum Versandi',
          approved_and_disapproved_for_russia: 'Überall außer Russland erlaubt',
          unknown: 'Unknown',
          ready: 'Zur Moderation bereit',
          awaiting_data_completion: 'Zur Moderation nicht bere',
          conditionally_approved: 'Überall außer Russland erlaubt',
        },
        modal: {
          google: {
            title: 'Google-Moderation ',
            description:
              'Wenden Sie sich an den Google-Support, um ein Problem mit der Moderation des Kreatives zu lösen. Dazu benötigen Sie die ID-Nummer der platzierten Werbung:',
            actionBtn: 'Zum Anfrageformular',
          },
          yandex: {
            title: 'Yandex-Moderation',
            description:
              'Wenden Sie sich an den Yandex-Support, um das Problem der Moderation des Kreatives zu lösen. Dazu benötigen Sie die ID-Nummer der platzierten Werbung. Sie muss in dem Brief, der das Problem beschreibt, angegeben werden. Adresse für Anfragen: ',
          },
          labelInput: 'ID des Kreatives',
          labelBtn: 'Kopie',
          needHelp: 'Brauchen Sie Hilfe?',
          collapse: 'Zusammenbruch',
          expand: 'Erweitern',
          variants: {
            variant1: 'Variante 1',
            variant2: 'Variante 2',
          },
        },
      },
      constructor: {
        create: 'Im Builder erstellen',
        business_area: 'Geschäftsfeld',
        upload_logo: 'Logo hochladen',
        select_style: 'Style wählen',
        upload_your_logo: 'Logo hochladen',
        formats: '(PNG, JPEG, JPG max. 2 Mb)',
        add_text: 'Text hinzufügen',
        text_limit: '(maximal 80 Zeichen)',
        text_of_btn: 'Text der Schaltfläche',
        name: 'Name',
        logo: 'Logo',
        style: 'Stil',
        illustrations: 'Illustrationen',
        illustration: 'Illustration',
        select_a_style: 'Wählen Sie einen Stil aus',
        select_illustration: 'Abbildung auswählen',
        write_text: 'Texte schreiben',
      },
      create_menu: {
        title: 'Kreativtyp',
        TEASER: 'Teaser',
        PUSH: 'Push',
        HTML: 'HTML',
        YANDEX: 'Yandex HTML',
        VIDEO_INLINE: 'Video',
        VIDEO_VAST: 'VAST Video',
        CLICKUNDER: 'Сlickunder',
        MULTI_FORMAT: 'Multiformat',
        COMPANION: 'COMPANION',
        HTML_VIDEO: 'HTML-Video',
      },
      crea_type: {
        html: 'Typ · HTML',
        push: 'Typ · PUSH',
        teaser: 'Typ · Teaser',
        yandex: 'Typ · Yandex HTML',
        video: 'Video',
        video_vast: 'VAST Video',
      },
      allstatrk: 'Allgemeine Statistik der Werbekampagne',
      push_text: {
        t1: 'Icon',
        t2: 'Bild',
        t3: 'Name',
        t4: 'Text',
        t5: 'Link zum Icon',
        t6: 'Erlaubte Dateitypen: png, jpeg, jpg, gif. Max Größe: 200 Mb.',
        t7: 'Min Auflösung: 80x80 px.',
        t8: 'Max Auflösung: 4096x4096 px.',
        t9: 'Link zum Bild',
        t10: 'Min Auflösung 360х240 px. Max Auflösung — 4096×4096 px.',
        t11: 'Resize, 2 Auflösungen 360×240 px, 492×328 px.',
        t12: 'Entfernen',
      },
      yandex_html_text: {
        t1: 'ZIP–Archiv',
        t2: 'Blindbanner',
        t3: 'Format: ZIP-Archiv. Max Größe — 150 Kb.',
        t4: 'Link zum ZIP–Archiv',
        t5: 'Anforderungen an Werbematerialien',
        t6: '150 Kb',
        t7: 'Alternativer Text',
        t8: 'Yandex Brand',
        t9: 'Yandex Kategorien',
        t10: 'Link zum Blindbanner',
        t11: 'Formate: jpeg, jpg, png, gif, svg. Max Größe — 150 Kb.',
        t12: 'Min Auflösung 80 × 80 px. Max Auflösung 4096 × 4096 px.',
        t13: '',
        t14: '',
        t15: '',
      },
      widget_text: {
        html_code: 'HTML-Kode des Banners',
        size: 'Größe des Kreatives',
        link: 'Navigationslink',
        adomain: 'Domain',
        iab: 'IAB-Kategorie',
        language: 'Sprache',
        advertiser_name: 'Werbekunde',
        zip_yandex_html: 'Link zum ZIP–Archiv',
        img_yandex_html: 'Link zum Blindbanner',
        alt_text: 'Alternativer Text',
        yandex_brand: 'Yandex Brand',
        yandex_category: 'Yandex Kategorien',
        video_url: 'Link zum Video',
        duration: 'Dauer, sec',
        skip_duration: 'Durchlaufdauer, sec',
        video_bitrate: 'Bitrate, kB/s',
        vast_url: 'Link zum Video (XML-File)',
        title: 'Name',
        text: 'Text',
        icon: 'Icon',
        image: 'Image',
        iconMask: 'Icon',
        imgMask: 'Image',
        image_1: 'Bild (600 х 600)',
        image_2: 'Bild (1080 х 607)',
        ID_ERID: 'ID ERIR',
        check_ERID: 'ERID Prüfung',
      },
      video: {
        video_input: {
          label: 'Video (URL)',
          custom_link: 'Link zum Video',
          hint1:
            'Erlaubte Formate: MP4 (MPEG-4): H.264-Video-Codecs, AAC-Audio-Codecs.',
          hint2: 'Empfohlene Größe - bis zu 10 Mb.',
        },
        tabs: {
          tab1: 'Haupteinstellungen ',
          tab2: 'Optionale Einstellungen',
        },
        fields: {
          video_bitrate: 'Bitrate, kB/s',
          video_duration: 'Dauer, sec',
          video_duration_pass: 'Durchlaufdauer, sec ',
          video_description: 'Beschreibung',
          video_show: 'Show (URL des Show-Trackers) ',
          video_show2: 'Show (Zusätzlich URL des Show-Trackers) ',
          video_click: 'Klick (URL des Klick-Trackers) ',
          video_view_0: 'Start (URL des Impression-Trackers)',
          video_view_25: 'FirstQuartile (URL des Impression-Trackers 25 %)',
          video_view_50: 'Midpoint (URL des Impression-Trackers 50%)',
          video_view_75: 'ThirdQuartile (URL des Impression-Trackers 75%)',
          video_view_100: 'Complete (URL des Impression-Trackers 100%)',
          video_skip: 'Skip (URL des Skip-Klick-Trackers) ',
          video_mute: 'Mute (URL des Mute-Trackers oder Lautstärke auf 0)',
          video_unmute: 'Unmute (URL des Unmute-Trackers)',
          video_pause: 'Pause (URL des Pause-Trackers)',
          video_rewind:
            'Rewind (URL des Rewind-Trackers oder des Fortschrittsbalkens)',
          video_resume: 'Resume (URL des Resume-Trackers)',
          video_expand: 'Expand (URL des Player-Erweitern-Trackers) ',
          video_collapse: 'Collapse (URL des Player-Kollapse-Trackers)',
          video_close: 'Close (URL des Player-Schließtrackers)',
          video_fullscreen: 'Fullscreen (URL des Fullscreen-Trackers) ',
          video_exitfs:
            'ExitFullscreen (URL des Trackers vom Ausgang aus dem Fullscreen)',
          video_sprogresshow:
            'Progress (URL des Trackers von Schau-Fortschrittsanzeigers)',
        },
        switch: {
          all: 'Alle Felder',
          quartiles: 'Nur Quartile',
          show: 'Nur Demonstration ',
        },
      },
      vast: {
        create_hint: {
          title: 'VAST Video',
          text: 'VAST (Video Ad Serving Template) ist eine vom Interactive Advertising Bureau (IAB) entwickelte Spezifikation, die einen Standard für die Kommunikationsanforderungen zwischen Werbeservern und Videoplayern zur Darstellung von Videoanzeigen festlegt. \nDas ist eine Datenstruktur mit XML',
        },
        tabs: {
          tab0: 'Link zum Video (XML-File)',
          tab1: 'Grundeinstellungen',
          tab2: 'Optionale Einstellungen',
        },
        fields: {
          video_url: 'Video (URL)',
          video_duration: 'Dauer, Sec',
          video_duration_pass: 'Durchlaufdauer, Sec',
        },
      },
      html: {
        no_tag1: 'Der Tag',
        no_tag2: 'fehlt im HTML-Code',
        error: 'Beim Anzeigen des Banners das Token wird nicht angezeigt. Bitte wenden Sie sich an den Support',
        copy_code_preview: 'Vorschau-Kode kopieren ',
      },
      clickunder: {
        create_hint: {
          title: 'Сlickunder',
          text: 'Clickunder ist eine Form der Pop-Werbung. Wenn Sie irgendwo auf der Seite klicken, wird die Website des Werbekunden in einem neuen Browserfenster geöffnet.',

        },
      },
      crea_statuses: {
        s1: 'Bestätigt',
        s2: 'In Moderation',
        s3: 'Überall außer Russland erlaubt',
        s4: 'Unknown',
        s5: 'In Moderation',
        s6: 'Abgelehnt',
        s7: 'Zur Moderation bereit',
        s8: 'Zur Moderation nicht bere',
        s9: 'Überall außer Russland erlaubt',
        s10: 'Zur Moderation senden',
        s11: 'iframe Code kopieren',
        s12: 'Nach der Einstellungsänderung speichern Sie bitte den Block, um Vorschau der aktuellen Kreativ-Version anzusehen.',
        s13: 'Nach der Einstellungsänderung speichern Sie bitte den Block, um Vorschau der aktuellen Kreativ-Version anzusehen.',
        s14: 'Im Vorschaumodus wird ein statisches Platzhalterbild angezeigt',
        s15: 'Vorschau ist nicht verfügbar. Füllen Sie bitte die Pflichtfelder in "Typ · HTML"',
        s16: 'Vorschau ist nicht verfügbar. Füllen Sie bitte die Pflichtfelder in "Typ · PUSH"',
        s17: 'Vorschau ist nicht verfügbar. Füllen Sie bitte die Pflichtfelder in "Typ · Yandex HTML"',
        s18: 'Vorschau ist nicht verfügbar. Füllen Sie bitte die Pflichtfelder in "Typ · VIDEO" block',
        s19: 'Bereit zum Versand',
        s20: 'Предпросмотр не доступен. Не заполнены обязательные поля в блоке "Тип · COMPANION"',
        s21: 'Vorschau ist nicht verfügbar. Füllen Sie bitte die Pflichtfelder in "Typ · VIDEO VAST" block',
        NOT_SENT: 'Zur Moderation nicht geschickt',
        MULTI_FORMAT:
          'Nach der Einstellungsänderung speichern Sie bitte den Block, um Vorschau der aktuellen Kreativ-Version anzusehen.',
      },
      mass_action: {
        modal_test_part1: 'Möchten Sie wirklich die',
        modal_test_part2: '',
        modal_test_part3: 'Kreative der Kampagne',
        modal_test_part4: 'Targets der Kreative der Kampagne entfernen',
        successfully_text_part1: 'Kreative wurden erfolgreich',
        successfully_text_part2: '',
        successfully_text_dublicate: 'Kreative wurden erfolgreich dubliert',
        successfully_text_clear: 'Kreative wurden erfolgreich gelöscht',
      },
      js_code_audit: 'JS Code Audit',
      js_code: 'JS Code',
      click_audit_pixel: 'Auditpixel beim Anklicken des Banners',
      erir_hint_1: 'Bitte vergessen Sie nicht,',
      erir_hint_2: 'ID',
      erir_hint_3: 'zu berücksichtigen',
      crea_size: 'Größe · ',
      cleartarg: 'Alle Targets entfernen',
      clear_all_target:
        'Möchten Sie wirklich alle Targets von der Kampagne entfernen ',
      stop_all_creatives:
        'Möchten Sie wirklich alle Kreativen von der Kampagne stoppen ',
      start_all_creatives:
        'Möchten Sie wirklich alle Kreativen von der Kampagne starten ',
      clear_done_targ:
        'Möchten Sie wirklich alle Targets von der Kreativ entfernen',
      fullname: 'Voller Name von',
      shortname: 'Gekürzter Name von ',
      fullwidth: 'Volle Breite',
      shortwidth: 'Kompakte Ansicht',
      tables_type: 'Typ',
      tables_name: 'Name',
      valid_vendor:
        '{LINK_UNESK} Macro ist nicht möglich in dem Navigationslink',
      errs: {
        e1: 'Datenvalidierungsfehler. Die Datei entspricht den Ladenanforderungen nicht ',
      },
      copy_creative: {
        copy_set: {
          t1: 'Einstellungen kopieren ',
          t2: 'Wählen Sie die Kreativen aus, in die Sie die Targeteinstellungen kopieren möchten.',
          t3: 'Einstellungen kopieren',
          t4: 'Das kann einige Sekunden dauern….',
          t5: 'Einstellungen von Targets der Kreativen ',
          t5_2: 'Die Target- und Pretarget-Einstellungen des Kreatives ',
          t6: ' wurden in Kreative kopiert',
          t7: 'Mit Pretarget-Einstellungen kopieren',
        },
        tit: 'Einstellungen kopieren ',
        text_creative:
          'Bitte wählen Sie die Kampagnen aus, in die die Creative kopiert werden sollen',
        text: 'Wählen Sie die Kreativen aus, in die Sie die Targeteinstellungen kopieren möchten.',
        errors: {
          tit: 'Kopierfehler',
          text: 'Nicht alle Einstellungen werden kopiert, einige Parameter sind nicht verfügbar für die Kreative unten.',
          err1: 'Unbekannter Kopierfehler',
        },
        succes: {
          t1: 'Kreative  ',
          t2: ' wurde erfolgreich kopiert',
          t3: '  in der Kampagne ',
          t4: 'Kreative ',
          t5: ' wurde erfolgreich kopiert',
        },
        copying_tit: 'Kopieren des Kreatives',
        copying_text: 'Das kann einige Sekunden dauern….',
      },
      dubl_creative: {
        t1: 'Kopieren des Kreatives ',
        add_eng_prefix_one: '',
        add_eng_prefix_many: '',
      },
      del_crea: {
        t1: 'Kreativ ',
        t2: ' wurde entfernt',
      },
      cop: 'Einstellungen kopieren ',
      clea: 'Alle Targets entfernen',
      none: {
        no1: 'Sie haben keine Kreative.',
        no2: 'Erstellen',
        none_text: 'Sie können einen Kreativ mithilfe von',
        none_text_of: 'Video, Bild, html-Kode erstellen',
      },
      type: {
        type: 'Typ · ',
        teaser: 'Teaser',
        video: 'Video',
        video_vast: 'VAST Video',
        clickunder: 'Сlickunder',
      },
      header_menu: {
        back: 'Zurück',
        settings: 'Einstellungen',
        targetings: 'Targeting',
        basic_settings: 'Grundeinstellungen',
      },
      use_VPAID: 'VPAID benutzen',
      create_btn: 'Kreativ hinzufügen',
      creative_was_created: 'Creative erstellt',
      creative_was_edited: 'Zuletzt bearbeitet',
      creative_requare: 'Pflichtfeld',
      creative_text: 'Kreativ',
      search: 'Suchen...',
      Name: 'Name',
      Impressions: 'Impressionen',
      cpm: 'CPM',
      Clicks: 'Klicks',
      cpc: 'CPC',
      ctr: 'CTR',
      Amount_spent: 'Kosten ',
      Moderation: 'Moderation',
      DocumentationGoogle: 'Google Docs',
      Status: 'Status',
      stat_act: 'Aktiviert',
      stat_pause: 'Pausiert',
      creative_prompt: {
        creative_text: {
          link: 'https://terratraf.readme.io/docs/td-creatives#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2',
        },
        type: {
          HTML: {
            link: 'https://terratraf.readme.io/docs/td-creatives-htmlbanner#%D0%B1%D0%BB%D0%BE%D0%BA-%D1%82%D0%B8%D0%BF-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%B4%D0%BB%D1%8F-html-%D0%B1%D0%B0%D0%BD%D0%BD%D0%B5%D1%80%D0%B0',
          },
          PUSH: {
            link: 'https://terratraf.readme.io/docs/td-creatives-push',
          },
          TEASER: {
            link: 'https://terratraf.readme.io/docs/td-creatives-teaser#%D0%B1%D0%BB%D0%BE%D0%BA-%D1%82%D0%B8%D0%BF-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D0%B4%D0%BB%D1%8F-%D1%82%D0%B8%D0%B7%D0%B5%D1%80%D0%B0',
          },
          VIDEO_INLINE: {
            link: 'https://terratraf.readme.io/docs/td-creatives-video#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D1%82%D0%B8%D0%BF%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE',
          },
          VIDEO_VAST: {
            link: 'https://terratraf.readme.io/docs/td-creatives-video-vast#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA-%D0%BA%D1%80%D0%B5%D0%B0%D1%82%D0%B8%D0%B2%D0%B0-%D1%82%D0%B8%D0%BF%D0%B0-%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE-vast',
          },
          MULTI_FORMAT: {
            link: 'https://terratraf.readme.io/docs/td-creatives-multiformat',
          },
          COMPANION: {
            link: '',
          },
        },
        additional_settings: {
          link: 'https://terratraf.readme.io/docs/td-creatives#%D0%B1%D0%BB%D0%BE%D0%BA-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B8',
        },
      },
      create_creative: {
        newTitle: 'Neues Kreativ',
        success: {
          t1: 'Kreativ ',
          t2: ' erfolgreich erstellt',
        },
        tizmacr: {
          m1: {
            t1: 'Makro · Kampagnen-ID',
            t2: 'Makro wird durch Kampagnen-ID ersetzt, z.B.',
          },
          m2: {
            t1: 'Makro ·  Kreativen-ID',
            t2: 'Makro wird durch Creative ID ersetzt, z.B.',
          },
          m3: {
            t1: 'Makro ·  Domain-ID',
            t2: 'Makro wird durch Domain-ID ersetzt, z.B.',
          },
          m4: {
            t1: 'Makro ·  Domain-ID',
            t2: 'Makro wird durch Domain-ID ersetzt, z.B.',
          },
        },
        create_tit: 'Kreativ erstellen',
        Advertiser_name: 'Werbekunde',
        Please_fill:
          'Füllen Sie bitte das Feld aus, um Kreativ zur Google Moderation zu schicken',
        Name: 'Name *',
        Creative_name: 'Kreativ',
        select_type: 'Art auswählen ...',
        symbols: ' Symbole',
        type_tit: 'Typ',
        type_tit_html: 'HTML',
        File: 'Datei',
        Supported_format: 'Unterstützes Format: zip-Archiv. Max Größe: 6 Mb',
        Max_size: '',
        Add_archive: 'Archiv hinzufügen',
        HTML_Code: 'HTML Code',
        Actions: 'Aktionen',
        Convert_code: 'Code konvertieren',
        Add_macros: 'Makros hinzufügen',
        Size_px: 'Größe, px',
        Please_select: 'Wählen Sie aus',
        Set_sizes: 'Ihre Größe einstellen',
        Width: 'Breite',
        Height: 'Höhe',
        select_from_list: 'Aus der Liste auswählen',
        Additional_settings: 'Zusätzliche Einstellungen',
        URL_settings: 'URL Einstellungen',
        Navigation_link: 'Navigationslink',
        Extension_link: 'Zusätzlicher Navigationslink und Lokalisierung',
        Link: 'Link',
        Domain: 'Zieldomäne',
        Domain_address: 'Domain-Adresse',
        Optional_URL: 'Zusätzliche URL Einstellungen',
        Pixel_audit: 'Pixel Audit',
        Optional_pixel: 'Zusätzliches Pixel Audit',
        Categories: 'Kategorien ',
        CategoriesAndLanguage: 'Kategorien und Sprache',
        Language: 'Sprache',
        optimization: {
          title: 'Optimierungs algorithmus',
          algorithm: 'Algorithmus',
          default: 'Nicht ausgewählt',
          max_ctr: 'Optimierung nach CTR',
          min: 'Minimalwert',
          max: 'Maximalwert',
        },
        Creative_preview: 'Kreativ-Vorschau',
        Preview: 'Vorschau',
        To_preview:
          'Um die Vorschau anzusehen, erstellen Sie bitte Kreativ, danach wird die Schaltfläche aktiv',
        not_available: 'Vorschau ist nicht verfügbar.',
        went_wrong: 'Es ist ein Fehler aufgetreten.',
        try_to_refresh: 'Aktualisieren Sie die Seite.',
        This_format:
          'Dieses Format unterstützt 3 Variante fürs Erstellen des Kreatives:',
        Upload_zip: 'Zip-Archiv herunterladen',
        Import_html: 'HTML-Code importieren',
        animation: 'Animation',
        type_jest: 'Brutalität',
        type_erotick: 'Erotik',
        ani_yes: 'Ja',
        ani_no: 'Nein',
        ertype: {
          t1: 'Nein',
          t2: 'Leicht',
          t3: 'Mäßig',
          t4: 'Hart',
          t5: 'Ohne Sex',
          t6: 'Körperteile',
          t7: 'Softcore',
          t8: 'Erotik',
          t9: 'Pornografie',
        },
      },
      delete_creative_modal: {
        delete_tit: 'Kreative entfernen',
        delete_quest: 'Sie möchten entfernen',
      },
      no_result_for_filter: 'Keine Ergebnisse für diesen Filter gefunden',
      tizer_word: 'Teaser',
      targeting_settings:
        'Verwendet benutzerdefinierte Einstellungen für das Creative-Targeting',
      pretargeting_settings: 'Eigene Pretargeting-Einstellungen sind aktiviert',
      targetAndPretarget_settings:
        'Eigene Pretargeting- und Targeting-Einstellungen sind aktiviert',
      no_selected_pretarget: 'Keine ausgewählten Pretarget-Einstellungen gefunden',
      creasmall_set: {
        linktoicon_word: 'Link zum Icon',
        icon_word: 'Icon',
        teaser_formats1:
          'Erlaubte Dateitypen: png, jpeg, jpg, gif Max Größe: 6 Mb',
        teaser_formats2: 'Min Auflösung: 80x80. Max Auflösung: 4096x4096',
        linktoimage_word: 'Link zum Bild',
        image_word: 'Bild',
        teaser_formats3:
          'Erlaubte Dateitypen: png, jpeg, jpg, gif Max Größe: 6 Mb',
        teaser_formats4: 'Min Auflösung: 100x100. Max Auflösung: 4096x4096.',
        html_format1:
          'Erlaubte Dateitypen: png, jpeg, jpg, gif. Max Größe: 6 Mb. ',
        html_format2: 'Min Auflösung: 100×100. Max Auflösung: 4096x4096',
        title_word: 'Name',
        text_word: 'Text',
        upload_comp: 'Vom Computer hochladen',
        upload_word: 'Hochladen',
        push_formats1:
          'Erlaubte Dateitypen: png, jpeg, jpg, gif Max Größe: 6 Mb',
        push_formats2: 'Erlaubte Auflösung 192 x 192 Pixel.',
        push_formats3: 'Erlaubte Auflösung 360 x 240 Pixel.',
        multuformat_formats1:
          'Erlaubte Dateitypen: png, jpeg, jpg Max Größe: 2 Mb',
        multuformat_formats2: 'Die erlaubte Auflösung beträgt 256 x 256 Pixel.',
        multuformat_formats3: 'Die erlaubte Auflösung beträgt 600 x 600 Pixel.',
        multuformat_formats4:
          'Die zulässige Auflösung beträgt 1080 x 607 Pixel.',
        type_hint: {
          HTML: 'Zur Ansicht sollen Sie die Sektion "Typ · HTML" ausfüllen und ein Kreativ erstellen, danach wird die Schaltfläche aktiv.',
          PUSH: 'Zum Anzeigen sollen Sie die Sektion "Typ · Push" ausfüllen und ein Kreativ erstellen, danach wird die Schaltfläche aktiv.',
          MULTI_FORMAT:
            'Um es anzuzeigen, müssen Sie den Block "Typ · Multiformat" ausfüllen und ein Kreativ erstellen. Danach wird die Schaltfläche aktiv.',
          YANDEX:
            'Zur Ansicht sollen Sie die Sektion " Blindbanner" ausfüllen und ein Kreativ erstellen, danach wird die Schaltfläche aktiv.',
          VIDEO_INLINE:
            'Um anzusehen, soll man ein Video hochladen und ein Kreativ erstellen, dann wird die Schaltfläche aktiv.',
        },
        multiformat: {
          type_of_btn: 'Schaltflächentyp',
          legal_info_title: 'Rechtliche Informationen',
          icon: 'Icon (256 x 256)',
          image_1: 'Bild (600 х 600)',
          image_2: 'Bild (1080 х 607)',
          title_hint:
            'Geben Sie einen Titel für Ihre Anzeige ein. Sie können einen Satz nicht mit einem kleinen Buchstaben oder Symbol beginnen. Verwenden Sie einen Großbuchstaben am Satzanfang.',
          text_hint:
            'Geben Sie Ihren Anzeigentext ein. Bitte beachten Sie, dass in einer Anzeige nur ein Ausrufezeichen stehen darf: entweder im Titel oder im Text. Sie können einen Satz nicht mit einem kleinen Buchstaben oder Symbol beginnen. Verwenden Sie einen Großbuchstaben am Satzanfang.',
          legal_info:
            'Geben Sie im Feld Rechtliche Informationen Informationen zum Verkäufer (OGRN, TIN usw.) ein oder lassen Sie dieses Feld leer.',
          type_btn_hint:
            'Call-to-Action-Schaltfläche. Verwenden Sie den passenden Button-Text, um die Attraktivität Ihrer Anzeige zu erhöhen.',
        },
        type_html_hint:
          'Zur Ansicht sollen Sie die Sektion "Typ · HTML" ausfüllen und ein Kreativ erstellen, danach wird die Schaltfläche aktiv.',
        type_push_hint:
          'Zum Anzeigen sollen Sie die Sektion "Typ · Push" ausfüllen und ein Kreativ erstellen, danach wird die Schaltfläche aktiv.',
        type_multiformat_hint:
          'Um es anzuzeigen, müssen Sie den Block "Typ · Multiformat" ausfüllen und ein Creative erstellen. Danach wird die Schaltfläche aktiv.',
        type_yandex_hint:
          'Zur Ansicht sollen Sie die Sektion " Blindbanner" ausfüllen und ein Kreativ erstellen, danach wird die Schaltfläche aktiv.',
        type_video_hint:
          'Um anzusehen, soll man ein Video hochladen und ein Kreativ erstellen, dann wird die Schaltfläche aktiv.',
        type_video_vast_hint:
          'Um anzusehen, soll man ein Video VAST hochladen und ein Kreativ erstellen, dann wird die Schaltfläche aktiv.',
        type_companion_hint: 'Um ansehen zu können, laden Sie das Video mit dem html-Code hoch und erstellen Sie ein Kreativ, dann wird die Schaltfläche aktiv.',
        notarg_text: 'Um zu targetieren, erstellen Sie bitte Kreativ',
        zip_link: 'Link zum ZIP–Archiv',
        zip_arr: 'ZIP–Archiv',
        zip_arrrull: 'Anforderungen an Werbematerialien',
        yand_format: 'Format: ZIP-Archiv. Max Größe — 150 Kb.',
        yand_link_img: 'Link zum Blindbanner',
        yand_stub: 'Blindbanner',
        yand_formats1: 'Formate: jpeg, jpg, png, gif, svg. Max Größe — 150 Kb.',
        yand_formats2:
          'Min Auflösung 80 × 80 px. Max Auflösung 4096 × 4096 px.',
        yand_alt_text: 'Alternativer Text',
        yand_brand: 'Yandex Brand',
        yand_cat: 'Yandex Kategorien',
      },
      settings: {
        set_tit: 'Einstellungen',
        ext_sour: 'Externe Quellen',
        ids: 'Externe ID',
        id_from_ss: 'Geben Sie ID aus CC ein',
        enter_date: 'Daten eingeben',
        help_text:
          'Für die Kunden mit Adminrechten sind mehrere Einstellungen verfügbar: Festlegung von täglichen Preisparameter (für CPA-Preistyp) festlegen, Hinzufügen von externen Quellen, Verwaltung der verfügbaren Berichte usw.',
      },
      mass_editing: 'Massenbearbeitung',
      set_url_incl_links: 'URL-Einstellungen werden nur auf die Kreative angewendet, die diese Links enthalten.',
      liks_stays: 'Links, die bei der Massenbearbeitung nicht hinzugefügt wurden, bleiben unverändert',
      will_be_changed: 'Nur die Felder, wo die Werte hinzugefügt wurden, werden geändert.',
      error: 'Speichern der Massenbearbeitung ist fehlgeschlagen',
      add_url: 'URL für Bearbeitung hinzufügen',
      enter_link: 'Geben Sie den Link ein',
      link_will_clean: 'Der Feld wird gelöscht ',
      discard_changes: 'Änderungen verwerfen',
      all_changes_saved: 'Alle Änderungen wurden erfolgreich in den ausgewählten {{value}} Kreativen gespeichert',
      used_in_creatives: 'Wird nur in Kreativen: {{creos}} verwendet',
      the_field_be_deleted: 'Der Inhalt des Feldes wird in den ausgewählten Kreativen entfernt',
      eridWarning_confirm: 'ERID ist nicht bei DSP registriert! Bestätigen Sie seine Verwendung in der Werbeanzeigung?',
      eridWarning_what_show: 'Sie verwenden eine bei DSP nicht registrierte ERID! INN und Name der Vertragspartei werden im Werbeblock nicht angezeigt.',
    },
    containers_page: {
      tit: 'Containers',
      name: 'Name',
      size_aut: 'Gesamtanzahl der Zielgruppe',
      size_aut_day: 'Anzahl der Zielgruppe pro Tag',
      back: 'Zurück',
      date: 'Date',
      stat_tit: 'Statistik',
      download: 'Herunterladen',
      more_info: 'Weitere Informationen finden Sie unter dem Link unten:',
      containers_none: 'Sie haben noch keine Containers',
      not_linked: 'Container sind nicht gebunden',
    },
    search_by_id_popup: {
      filter: {
        placeholder: 'Filter',
      },
      search: {
        placeholder: 'Kampagne / Creative ID',
        clear: 'Abbrechen',
      },
      empty_list: 'Für diese Anfrage wurden keine Ergebnisse gefunden',
    },
    type_parther: {
      ORGANIZATION: 'Organisation',
      AGENCY: 'Agentur',
      CLIENT: 'Kunde',
    },
    report: {
      download: 'Bericht herunterladen',
    },
    translations: {
      title: 'Status',
      title_hint_camp: 'Status der Kampagnenübertragung.',
      title_hint_creo: 'Status der Werbungsanzeige.',
      title_hint_link: 'Mehr erfahren.',
      status_paused: 'Der Status der Organisation ist pausiert',
      agency_no_start: 'Die Agentur wurde nicht gestartet',
      client_not_start: 'Der Kunde wurde nicht gestartet',
      campaign_stoppen: 'Die Kampagne wurde gestoppt',
      outdated_period: 'Irrelevante Periode der Werbungsanzeige',
      zero_target: 'Null-Optimierungsziel',
      balance_exhausted: 'Die Bilanz wurde ausgeschöpft',
      campaign_ready: 'Die Kampagne ist für den Start der Kreativen bereit',
      adv_uploaded: 'Die Werbung wird für die Anzeige hochgeladen',
      adv_not_uploaded:
        'Die Werbung wurde nicht hochgeladen. Es werden keine Impressionen angezeigt',
      ad_stopped: 'Die Werbung wurde gestoppt',
      ad_not_pass: 'Die Werbung hat die Moderation nicht bestanden',
      broadcast: 'Ausgestrahlt',
      no_broadcast: 'Nicht ausgestrahlt',
      title_widget: 'Status der Werbungsanzeige',
      no_results_status: 'Keine Ergebnisse für diesen Werbungsanzeigenstatus gefunden',
      contact_adnim_toggle:
        'Bitte wenden Sie sich an den Administrator, um den Status zu ändern.',
      check_toggle:
        'Zum Starten klicken Sie bitte auf die Toggle-Umschaltfläche.',
      check_toggle_need_help:
        'Wenn Sie Hilfe benötigen, wenden Sie sich an den Administrator.',
      check_toggle_check_settings:
        'Bitte überprüfen Sie die Einstellungen des Kreatives und schicken Sie es erneut zur Moderation.',
      check_date:
        'Bitte überprüfen Sie das Start- und Enddatum in den Kampagneneinstellungen. Für weitere Details folgen Sie bitte dem ',
      check_date_link: 'Link.',
      check_price:
        'Bitte überprüfen Sie den Preis für den angegebenen Optimierungstyp in den Kampagneneinstellungen. Für weitere Details folgen Sie bitte dem ',
      check_price_link: 'Link.',
      no_funds:
        'Es gibt kein Geld auf dem Kundenkonto. Bitte wenden Sie sich an den Administrator.',
      prepare_creatives:
        'Sie sollen die Kreative vorbereiten: die Kreative erstellen, zur Moderation schicken und die erforderlichen Targets ausfüllen.',
      campaign_no_ready:
        'Die Kampagne ist für den Start der Kreativen nicht bereit.',
      dsp_disable: 'Deaktiviert in DSP',
      no_impressions: 'Deaktiviert in DSP, keine Impressionen werden angezeigt',
      camp_stop: 'Die Kampagne wurde in DSP gestoppt',
      call_admin: 'Bitte kontaktieren Sie den Administrator',
      client_stop: 'Der Kunde wurde in DSP gestoppt',
      ad_stop: 'Die Werbung wurde in DSP gestoppt',
      dsp_problem: 'Einige technische Schwierigkeiten sind in DSP aufgetreten',
      fixed_soon: 'Alles wird bald wieder funktionieren',
      ad_is_run: 'Bitte beachten Sie: die Werbung läuft in DSP',
      camp_is_run: 'Bitte beachten Sie: die Kampagne läuft in DSP',

      error_creo: 'Ein Fehler ist in den Daten des Kreatives aufgetreten',
      contact_adnim: 'Bitte wenden Sie sich an den Administrator.',
      geo_target_empty: 'GEO-Target wurde nicht ausgefüllt',
      set_local:
        'Bitte überprüfen Sie die Targets des Kreatives und geben Sie es an. Für weitere Details folgen Sie bitte dem',
      set_local_link: 'Link.',
      source_target_empty: 'Quellen-Target wurde nicht ausgefüllt.',
      select_ssp:
        ' Bitte überprüfen Sie die Targets des Kreatives und wählen Sie SSP. Für weitere Details folgen Sie bitte dem ',
      select_ssp_link: 'Link.',

      profile_settings: 'Den Status der Werbung anzeigen',
      profile_set_info:
        'Wenn Kampagnen für Ad Impressionen erstellt wurden, aktivieren Sie entsprechende Einstellung. Sie sehen den Status der Kampagne und der Anzeigen auf den TD-Seiten.',

      see_statistics:
        'Wenn die Kampagne/ die Kreative die Prüfungen nicht bestanden haben, werden Statistiken mit der Anzahl der Impressionen und/oder Klicks angezeigt. Wenn es im Laufe von mehr als 1 Stunde keine Impressionen gibt, wenden Sie sich an den Administrator',
      prepare_creo:
        'Sie sollen die Kreative vorbereiten: die Kreative erstellen, zur Moderation schicken und die erforderlichen Targets ausfüllen.',
    },
    ord: {
      title: 'Werbedatenbetreiber',
      registration_in_ord: 'anmeldung bei Werbedatenbetreiber',
      сounterparties_title: 'Vertragsparteien',
      сounterparties_search: 'Suche nach INN / Name der juristischen Person',
      counterparte_create: 'Vertragspartei erstellen',
      сounterparties_none: 'Sie haben keine Vertragsparteien. Klicken Sie, um die zu erstellen.',
      contracts_title: 'Ursprungsverträge',
      counterparte_creating: 'Erstellen einer Vertragspartei',
      parameters: 'Parameter',
      type: 'TYP *',
      name_of_legal: 'NAME DER JURISTISCHEN PERSON *',
      synonyms_name: 'SYNONYME VOM NAMEN',
      inn: 'INN / ALTERNATIVE',
      synonyms_name_1: 'SYNONYME VOM NAMEN (GETRENNT DURCH KOMMAS)',
      name_for_export: 'NAME FÜR EXPORTIERTE ANGABEN',
      synonyms_enter: 'Geben Sie die Synonyme ein',
      contract_create: 'Vertrag erstellen',
      contract_search: 'UUID / Vertragsnummer / Externe ID / INN des Vertragsnehmers / INN des Auftragsgeber',
      customer_select: 'Wählen Sie den Auftragsgeber aus',
      contractor_select: 'Wählen Sie den Auftragsnehmer aus',
      contract_delete: 'Vertrag entfernen',
      want_to_del: 'Möchten Sie wirklich den Vertrag <bold>{{title}}</bold> entfernen??',
      contract_deleted: 'Vertrag <bold>{{title}}</bold> wurde entfernt',
      contacts_none: 'Sie haben keine Verträge. Klicken Sie, um einen neuen zu erstellen.',
      creating_contact: 'Erstellen eines Vertrages',
      contact_info: 'Vertragsinformation',
      contragents_info: 'Informationen über den Vertragspartner',
      registered: 'Im Werbedatenoperator registriert',
      original_contract: 'Ursprungsvertrag',
      contract_data: 'Vertragsdaten',
      additional_contract_data: 'Zusätzliche Vertragsdaten',
      inn_name: 'INN / Name',
      contractor: 'Verstragsnehmer',
      contract_number: 'Verstragsnummer*',
      enter_number: 'Geben Sie die Nummer ein',
      contract_date: 'Vertragsdatum*',
      enter_date: 'Geben Sie das Datum ein',
      contract_type: 'Vertragstyp*',
      select_type: 'Wählen Sie den Typ aus',
      information_on_contract: 'Information über Vertragsgegenstand*',
      contract_amount: 'Vertragssumme, ₽ *',
      transferring_money: 'Geldtransfer',
      select_transfer: 'Wählen Sie wohin Geldmittel überwiesen werden sollen',
      actions: 'Die vom Vermittler getroffene Maßnahmen*',
      report_contract: 'Berichtsvertrag',
      obligation_to_register: 'Registrierungs- und Meldepflicht für Kreative vom Vertragsnehmer',
      inc: 'inkl. MwSt.',
      contact_created: 'Vertrag <bold>{{title}}</bold> wurde erfolgreich erstellt',
      exported: 'Exportiert in ERIR',
      revenue_contract: 'Gewinnvertrag',
      parental_contract: 'Elternvertrag',
      financial_closure: 'Der Finanzabschluss wird im Rahmen dieses Vertrags erfolgen.',
      impossible_export: 'Es kann in ERIR nicht exportiert werden',
      send: 'In ERIR schicken',
      export_planned: 'Export ist geplannt',
      original_agreement: 'Wenn der Ursprungsvertrag des Kunden vom Typ "Zusatzvereinbarung" ist, müssen auch die Angaben zu dem/den entsprechenden ursprünglichen Vertrag/Verträgen übertragen werden. Nur für Zusatzvereinbarungen obligatorisch.',
      impossible_send: 'Es kann nicht geschickt werden',
      pending: 'In Erwartung',
      legal_entity: 'Juristische Person',
      individual_entity: 'Natürliche Person',
      sole_trader: 'Einzelunternehmer',
      foreign_individual_entity: 'Ausländische natürliche Person',
      foreign_legal_entity: 'Ausländische juristische Person',
      registration_number: 'Registrierungsnummer*',
      identification_number: 'Steuernummer oder ihr Äquivalent*',
      counterparty_created: 'Vertragspartei <bold>{{title}}</bold> wurde erfolgreich erstellt',
      inn_or: 'INN',
      status: 'Status',
      customer: 'AUFTRAGSGEBER',
      waiting_export: 'Wartet auf Export',
      successfully: 'Erfolgreich',
      error: 'Fehler',
      date: 'DATUM',
      td_clients: 'TD-KUNDEN*',
      td_agencis: 'TD-AGENTUREN',
      intermediary_contract: 'Vermittlungsvertrag',
      other: 'Anderes',
      agent_acts: 'Der Vertreter handelt im Interesse von Werbungsverbreiter',
      yes: 'Ja',
      no: 'Nein',
      contract_info: 'Vertrag',
      clients: 'Kunden*',
      no_del_contracts: 'Es gibt keine entfernten',
      fio: 'Voller Name',
      actions_for_dist: 'Maßnahmen zu Werbungsverbreitung',
      contracts_concl: 'Vertragsabschluss',
      commercial_rep: 'Kommerzielle Vertretung',
      agent_acts_behalf: 'Der Vertreter handelt im Interesse von Werbekunden ',
      service_contract: 'Dienstleistungsvertrag',
      additional_agreement: 'Zusatzvereinbarung',
      self_advertisement: 'Eigenwerbung',
      representation: 'Vertretung',
      distribution_org: 'Verbreitungsorganisation',
      intermediation: 'Vermittlung',
      distribution: 'Verbreitung',
      registration_country: 'Registrierungsland',
      mobile_phone: 'MOBILTELEFONNUMMER*',
      unknow_ord: 'Unbekannter Werbedatenbetreiber',
      active: 'Aktiv',
      moderation: 'In Moderation',
      deleted: 'Entfernt ',
      contracts: {
        title: 'OKVED',
        code: 'OKVED-Kodes für Werbeobjekte',
        hint1: 'Das Feld ist obligatorisch, wenn der Kunde im ursprünglichen Vertrag eine natürliche Person oder eine ausländische Gegenpartei ist',
        hint2: 'OKVED wird automatisch für den Kreativen angewendet, in denen dieser ursprüngliche Vertrag verwendet ist',
        placeholder_search: 'Suche nach der Vertragsnummer / INN',
        one: 'Ursprünglicher Vertrag',
        open: 'Den Vertrag öffnen ',
      },
      errors: {
        required_field: 'Pflichtfeld',
        already_exists: 'Vertragspartei mit dieser INN bereits existiert ',
        inn_invalid: 'INN ist ungültig',
        should_not_match: 'Auftragsgeber und Auftragsnehmer sollen übereinstimmen',
        min_6_characters: 'Minimal 6 Zeichen',
        max_40_characters: 'Maximal 30 Zeichen',
        min_1_character: 'Minimal 1 Zeichen',
        max_255_characters: 'Maximal 255 Zeichen',
        max_1000_characters: 'Maximal 1000 Zeichen',
        min_value_0: 'Mindestwert ist 0',
        max_value_exceeded: 'Maximalwert ist überschritten',
        incorrect_value: 'Falscher Wert',
        field_only_digits: 'Dieses Feld darf nur Ziffern enthalten',
        fill_field_or_epay: 'Füllen Sie dieses Feld oder EPAY aus',
        fill_field_or_phone: 'Füllen Sie dieses Feld oder "Mobiltelefonnummer" aus',
        field_only_latin_letters: 'Dieses Feld darf nur Ziffern und lateinische Buchstaben enthalten',
        fill_filed_or_equivalent: 'Füllen Sie dieses Feld oder "Steuernummer oder ihr Äquivalent" aus ',
        fill_field_or_number: 'Füllen Sie dieses Feld oder "Registrierungsnummer" aus',
        taxpayer_equivalent: 'Steuernummer oder ihr Äquivalent',
        customer_contract_type: 'Auftragsgeber und Auftragsnehmer sollen für Vertragstyp "Eigenwerbung" übereinstimmen',
        unsupported_contract: 'Nicht unterstützter Vertragstyp für Werbedatenbetreiber',
        cus_refer: 'Auftragsgeber und Auftragsnehmer sollen sich auf denselben Werbedatenbetreiber beziehen wie der Vertrag',
        should_written: 'Voller Name muss in kyrillischen Buchstaben geschrieben sein, mit einem Leerzeichen oder Bindestrich zwischen den Wörtern',
        okved_field: 'OKVED-Feld ist obligatorisch',
      }
    },
    found: 'Gefunden',
    save_btn: 'OK',
    cancel_btn: 'Abbrechen',
    btn: 'Schaltfläche',
    create_btn: 'Erstellen',
    select_btn: 'Wählen',
    ok_btn: 'ОК',
    continue_btn: 'Weiter',
    copy_btn: 'Kopieren ',
    edit_btn: 'Bearbeiten',
    add_btn: 'Einfügen',
    no_result: 'Nicht gefunden',
    apply_btn: 'Anwenden',
    stop: 'Stoppen',
    stopped: 'gestoppt',
    launched: 'gestartet',
    deleted: 'gelöscht',
    start: 'Starten',
    search_btn: 'Suchen',
    clean_btn: 'Löschen',
    more_btn: 'Mehr Details',
    yes: 'Ja',
    no: 'Nein',
    all: 'Alle',
    email: 'Email',
    selected: 'Ausgewählt',
    delete_btn: 'Entfernen',
    update_btn: 'Aktualisieren',
    change_btn: 'Veränderung',
    in_archive_btn: 'Zum Archiv',
    from_archive_btn: 'Extrahieren',
    retrieved: 'wurden aus dem Archiv extrahiert',
    archived: 'zum Archiv hinzugefügt',
    archive_title_to: 'Zum Archiv hinzufügen',
    archive_title_from: 'Aus dem Archiv entpacken',
    expand_btn: 'Erweitern',
    collapse_btn: 'Minimieren',
    duplicate_btn: 'Dublieren',
    statistics_btn: 'Statistik',
    download_btn: 'Herunterladen',
    show_btn: 'Anzeigen',
    copy_to_another_campaign_btn: 'In eine andere Kampagne kopieren',
    wider_then: 'Volle Breite',
    wider_center: 'Kompakte Ansicht',
    disable_edit: 'Sie haben eine Einschränkung beim Bearbeiten dieses Blocks',
  },
};
